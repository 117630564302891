import Lottie from "lottie-react";
import animationData from "assets/animations/spin_wheel_animation.json";
import "./animations.scss";

const SpinWheelLoaderAnimation = () => {
  return (
    <Lottie
      className="spin-wheel-animation"
      animationData={animationData}
      loop={true}
    />
  );
};

export default SpinWheelLoaderAnimation;
