import { i18n } from "i18n/localisation";
import { useEffect, useRef, useState } from "react";
import { propertyChatRequest } from "../../../api/ai";
import closeIcon from "../../../assets/core/close.svg";
import sendIcon from "../../../assets/core/send.svg";
import aiChatIcon from "../../../assets/property/ai_chat_dark.svg";

const PropertyChat = (props) => {
  const { messages } = props;
  const [sendingMessage, setSendingMessage] = useState(false);
  const [input, setInput] = useState("");
  const chatlogRef = useRef(null);

  useEffect(() => {
    chatlogRef.current.scrollTop = chatlogRef.current.scrollHeight;
  });

  const adjustChatlogScroll = () => {
    setTimeout(() => {
      if (chatlogRef && chatlogRef.current) {
        chatlogRef.current.scrollTop = chatlogRef.current.scrollHeight;
      }
    }, 100);
  };

  const sendMessage = async () => {
    if (input.trim() !== "") {
      setInput("");
      const userMessage = { content: input, role: "user" };
      const newMessages = [...messages, userMessage];
      props.setMessages(newMessages);
      adjustChatlogScroll();

      setSendingMessage(true);
      let propertyObj = props.property;

      // embed statistics in property object
      if (props.statistics) {
        propertyObj.statistics = props.statistics;
      }

      const data = await propertyChatRequest(newMessages, propertyObj);
      const botMessage = { content: data.response, role: "assistant" };
      props.setMessages([...messages, userMessage, botMessage]);
      setSendingMessage(false);
      adjustChatlogScroll();
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const closeChat = () => {
    document.querySelector(".property-chat").classList.add("slide-out");
    setTimeout(() => {
      props.onCloseChat();
    }, 200);
  };

  return (
    <div className="property-chat">
      <div className="property-chat_header">
        <div className="flex flex-1 gap-2">
          <img src={aiChatIcon} />
          <h3>{i18n("AI Property Chat")}</h3>
        </div>
        <img onClick={closeChat} src={closeIcon} />
      </div>
      <div className="divider"></div>
      <div ref={chatlogRef} className="property-chat_chatlog">
        <div className="property-chat_chatlog_message assistant">
          {i18n(
            "Ask me anything you want about the property and we'll answer it as best as possible",
          )}
          <br />
          <br />
          {i18n("e.g what's the distance to the closest airport?")}
        </div>
        {messages.map((message, index) => (
          <div
            className={
              "property-chat_chatlog_message " +
              (message.role === "user" ? "user" : "assistant")
            }
            key={index}
          >
            {message.content}
          </div>
        ))}
        {sendingMessage && (
          <div className="property-chat_chatlog_message assistant">
            {i18n("Thinking...")}
          </div>
        )}
      </div>
      <div className="divider"></div>
      <div className="property-chat_footer">
        <div className="property-chat_footer_wrapper">
          <input
            placeholder={i18n("Type a message...")}
            onKeyDown={(e) => onKeyDown(e)}
            value={input}
            onChange={handleInputChange}
            id="property-chat-input"
          />
          <div className="send-btn" onClick={() => sendMessage()}>
            <img src={sendIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyChat;
