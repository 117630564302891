import fireApiRequest from "./core";

var TRANSLATE_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/translate";

if (process.env.REACT_APP_NODE_ENV === "production") {
  TRANSLATE_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/translate";
}

/**
 * Translates texts
 */
export async function translateRequest(text, targetLang) {
  let result = "";

  try {
    let data = await fireApiRequest(TRANSLATE_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        text,
        target_lang: targetLang,
      }),
    });

    result = data.result;
  } catch (e) {
    console.log(e);
  }

  return result;
}
