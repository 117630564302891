import { Auth } from "aws-amplify";

export async function getHeaders() {
  let user = await Auth.currentAuthenticatedUser();
  let token = user.getSignInUserSession().getIdToken().getJwtToken();

  return {
    Authorization: `Bearer ${token}`,
  };
}
