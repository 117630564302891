import { useContext, useState } from "react";
import { CollectionsContext } from "../../../context/CollectionsContext";
import Button from "../../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { i18n } from "i18n/localisation";
import { getSearchTitle } from "utils/helpers";
import { getPropertyTitle } from "utils/properties";

const ObjectDeletionModal = ({ onSubmit, isOpen, object, closeModal }) => {
  const getModalTitle = () => {
    if (!object) {
      return "";
    }

    if (object.objectType == "search") {
      return i18n("Delete search");
    }

    return i18n("Delete property");
  };

  const getObjectTitle = () => {
    if (!object) {
      return "";
    }

    if (object.objectType == "search") {
      return getSearchTitle(object);
    }

    return getPropertyTitle(object);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        closeModal();
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{getModalTitle()}</DialogTitle>
        </DialogHeader>
        <div className="p-4">
          <p className="mb-2 text-sm font-medium text-gray-700">
            {i18n("Are you sure you want to delete this item:")}
          </p>
          <p>'{getObjectTitle()}'?</p>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="text" onClick={() => closeModal()}>
              {i18n("Cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={() => onSubmit()}
              className="ml-auto"
            >
              {i18n("Yes, delete")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ObjectDeletionModal;
