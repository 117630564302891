import React from "react";
import fullscreenIcon from "../../assets/core/fullscreen.svg";

const FullscreenButton = (props) => {
  return (
    <div className="fullscreen-button" onClick={props.onOpenFullscreen}>
      <img src={fullscreenIcon} alt="fullscreen" />
    </div>
  );
};

export default FullscreenButton;
