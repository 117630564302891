import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "components/ui/Button/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { sendContactForm } from "api/contactForm";
import { toast } from "sonner";
import { i18n } from "i18n/localisation";
import CheckMarkIcon from "../../../assets/property/checkmark.svg";

function ContactFormWidget({ property }) {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [comments, setComments] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(property.enquiryComplete);
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let formErrors = {};
    if (!fullName) formErrors.fullName = "Full name is required";
    if (!email) formErrors.email = "Email is required";
    // check if email is valid
    if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      formErrors.email = "Email is invalid";
    if (!phoneNumber) formErrors.phoneNumber = "Phone number is required";
    return formErrors;
  };

  const submitContactForm = async (
    propertyId,
    fullName,
    email,
    phoneNumber,
    comments,
  ) => {
    await sendContactForm({
      propertyId,
      fullName,
      email,
      phoneNumber,
      comments,
    });
  };

  const handleSubmit = async (e) => {
    // set loading state
    setLoading(true);

    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      console.log("Form submitted");
      await submitContactForm(
        property.id,
        fullName,
        email,
        phoneNumber,
        comments,
      );
      setFullName("");
      setEmail("");
      setPhoneNumber("");
      setComments("");
      setErrors({});
      setIsSubmitted(true);
      toast.success(i18n("Enquiry submitted"));
    }
    // set loading state
    setLoading(false);
  };

  return (
    <div className="contact-form-widget">
      {isSubmitted ? (
        <div className="contact-form-success">
          <div className="success-icon">
            <img src={CheckMarkIcon} alt="Success" />
          </div>
          <p className="contact-form-widget_title">
            {i18n("Thank you for your enquiry")}
          </p>
          <p className="success-tagline text-center">
            {i18n("We aim to respond to all enquiries within 24 hours")}
          </p>
        </div>
      ) : (
        <>
          <p className="contact-form-widget_title">
            {i18n("Enquire about this property")}
          </p>
          <form onSubmit={handleSubmit}>
            <div className="contact-form-widget_field">
              <label htmlFor="fullName">{i18n("Full Name")} *</label>
              <input
                id="fullName"
                type="text"
                value={fullName}
                placeholder={i18n("Enter Full Name")}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
              {errors.fullName && (
                <span className="error">{errors.fullName}</span>
              )}
            </div>
            <div className="contact-form-widget_field">
              <label htmlFor="email">{i18n("Email Address")} *</label>
              <input
                id="email"
                type="email"
                value={email}
                placeholder={i18n("Enter Email Address")}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="contact-form-widget_field">
              <label htmlFor="phoneNumber">{i18n("Phone Number")} *</label>
              <PhoneInput
                id="phoneNumber"
                country={"es"}
                value={phoneNumber}
                onChange={setPhoneNumber}
                placeholder={i18n("Enter Phone Number")}
                required
                containerClass="phone-input-container"
                inputClass="phone-input"
                buttonClass="phone-input-button"
              />
              {errors.phoneNumber && (
                <span className="error">{errors.phoneNumber}</span>
              )}
            </div>
            <div className="contact-form-widget_field">
              <label htmlFor="comments">{i18n("Comments")}</label>
              <textarea
                id="comments"
                value={comments}
                placeholder={i18n("Enter Comments")}
                onChange={(e) => setComments(e.target.value)}
              />
            </div>
            <div className="contact-form-widget_cta">
              <p className="cta-tagline">
                {i18n("Enquiries will be responded to within 24 hours")}
              </p>
              <Button
                type="submit"
                className="cta-button"
                variant="primary"
                onClick={handleSubmit}
                loading={loading}
              >
                {i18n("Contact")}
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default connect((state) => ({}))(ContactFormWidget);
