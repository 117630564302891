import moment from "moment";
import store from "store";

export function isTeamOwner() {
  let state = store.getState();
  return state.user.userData.teamRole === "owner";
}

export function isTeamMember() {
  let state = store.getState();
  return state.user.userData.teamRole === "member";
}

export function getSubscriptionData() {
  let state = store.getState();
  return state.auth.subscription.data;
}

export function getSubscriptionPlan() {
  let subscription = getSubscriptionData();

  if (!subscription) {
    return null;
  }

  return subscription.plan;
}

export function getTotalSeats() {
  let data = getSubscriptionData();

  if (!data) {
    return 1;
  }

  return parseInt(data.seats);
}

export function getBillingCycle() {
  let subscription = getSubscriptionData();

  if (!subscription) {
    return null;
  }

  return subscription.stripe_subscription.plan.interval;
}

export function getAvailableSeats() {
  let totalSeats = getTotalSeats();
  let usedSeats = getUsedSeats();
  return totalSeats - usedSeats;
}

export function getUsedSeats() {
  let state = store.getState();
  return state.team.users.length;
}

export function canAddUser() {
  return getAvailableSeats() > 0;
}

// for team members, an invitation status of "pending"
// means that the user has been invited to the team
// but has not yet accepted the invitation
export function isInvitationPending() {
  let state = store.getState();
  return state.user.userData.invitationStatus == "pending";
}

// get user roles
export function getUserRoles() {
  let state = store.getState();
  return state.user.userData.roles;
}

// check if the user's free trial has ended
export function trialEnded() {
  let state = store.getState();
  return parseFloat(state.user.userData.trialEndDate) < moment().unix();
}

// roles is an array of strings, so we check
// against the user object's roles array
export function userHasRole(role) {
  const roles = getUserRoles();
  return roles.includes(role);
}
