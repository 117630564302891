import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import createOverlay from "./OverlayBase";

export default function MapOverlay(props) {
  const { position, pane = "floatPane", map, zIndex, children } = props;

  const container = useMemo(() => {
    const div = document.createElement("div");
    div.style.position = "absolute";
    return div;
  }, []);

  const overlay = useMemo(() => {
    return new createOverlay(container, pane, position);
  }, [container, pane, position]);

  useEffect(() => {
    overlay.setMap(map);
    return () => overlay.setMap(null);
  }, [map, overlay]);

  // to move the container to the foreground and background
  useEffect(() => {
    container.style.zIndex = `${zIndex}`;
  }, [zIndex, container]);

  return createPortal(children, container);
}
