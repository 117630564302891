import React, { useState } from "react";
import Button from "components/ui/Button/Button";
import { connect } from "react-redux";
import moment from "moment";
import {
  getUpdatePaymentUrl,
  resumeSubscriptionRequest,
} from "api/subscriptions";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import { fetchSubscription } from "actions/userActions";
import { toast } from "sonner";
import { i18n } from "i18n/localisation";
import SubscriptionPlanContent from "components/settings/SubscriptionPlanContent";
import { getTotalSeats } from "utils/userHelpers";
import { setChangePlanModal, setPricingModal } from "actions/miscActions";

const SettingsBillingContent = ({ subscription, user, dispatch, ...props }) => {
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] =
    useState(false);

  const openUpdatePaymentLink = () => {
    window.open(getUpdatePaymentUrl(user.sub), "_blank");
  };

  const openChangePlanModal = () => {
    dispatch(setChangePlanModal(true));
    dispatch(setPricingModal(true));
    // open on page 3 of th
  };

  const resumeSubscription = () => {
    let promise = async () => {
      await resumeSubscriptionRequest();
      dispatch(fetchSubscription());
    };

    toast.promise(promise, {
      loading: i18n("Resuming subscription..."),
      success: i18n("Subscription resumed successfully"),
      error: i18n("Failed to resume subscription"),
      duration: 1000,
    });
  };

  const renderModifySubscriptionSection = () => {
    if (!subscription || !subscription.stripe_subscription) {
      return null;
    }

    if (subscription.stripe_subscription.cancel_at_period_end) {
      return (
        <div className="subscription-status_modify">
          <Button
            onClick={() => resumeSubscription()}
            className="resume-subscription mb-4 mt-4 flex w-48 justify-center"
            variant="primary"
          >
            {i18n("Resume subscription")}
          </Button>
          <span className="setting-heading">
            {i18n(
              "Subscription will be cancelled at the end of the current billing period on ",
            ) +
              moment
                .unix(subscription.stripe_subscription.current_period_end)
                .format("DD MMM YYYY")}
          </span>
        </div>
      );
    }

    return (
      <div className="subscription-status_modify">
        <div className="subscription-status_modify">
          <Button
            onClick={() => setOpenCancelSubscriptionModal(true)}
            className="cancel-subscription"
            variant="text"
          >
            {i18n("Cancel subscription")}
          </Button>
          <span className="setting-heading">
            {i18n("Renews on ") +
              moment
                .unix(subscription.stripe_subscription.current_period_end)
                .format("DD MMM YYYY")}
          </span>
        </div>
      </div>
    );
  };

  const renderSubscriptionStatus = () => {
    if (
      subscription &&
      (subscription.status == "active" ||
        subscription.status == "trialing" ||
        subscription.status == "cancel_at_period_end")
    ) {
      let subscriptionPlan = i18n("iRealty Plan");

      if (subscription.plan == "pro") {
        subscriptionPlan = i18n("Pro Plan");
      }

      if (subscription.plan == "ultra") {
        subscriptionPlan = i18n("Ultra Plan");
      }

      return (
        <>
          <span className="subscription-status_active">{i18n("Active")}</span>
          <span className="subscription-status_plan">{subscriptionPlan}</span>
        </>
      );
    }

    return (
      <span className="subscription-status_inactive">{i18n("Inactive")}</span>
    );
  };

  // render payment plan ui for free trials
  if (props.userData && props.userData.trialEndDate) {
    if (
      !subscription &&
      moment().unix() <= parseFloat(props.userData.trialEndDate)
    ) {
      let trialEndDate = moment.unix(parseFloat(props.userData.trialEndDate));
      let diffDays = trialEndDate.endOf("day").diff(moment(), "days");
      let daysRemainingText = i18n("days remaining of your free trial");

      if (diffDays == 1) {
        daysRemainingText = i18n("day remaining of your free trial");
      }

      return (
        <div className="settings_tabs_content">
          <div className="settings_tabs_content_body pt-3">
            <SubscriptionPlanContent
              headerTitle={i18n(
                "Subscribe to get unlimited access to our features:",
              )}
              subscribeBtn
            />
          </div>
          <p className="px-6 font-semibold">
            {diffDays} {daysRemainingText}
          </p>
        </div>
      );
    }
  }

  return (
    <div className="settings_tabs_content">
      <div className="settings_tabs_content_header">
        <div className="settings_tabs_content_header_row">
          <div className="settings_tabs_content_header_col">
            <h2>{i18n("Billing details")}</h2>
            <span>{i18n("Payment and subscription details")}</span>
          </div>
        </div>
        <div className="divider"></div>
      </div>
      <div className="settings_tabs_content_body">
        <div className="settings_tabs_content_body_row">
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">
              {i18n("Subscription status")}
            </span>
            <div className="subscription-status">
              {renderSubscriptionStatus()}
              {renderModifySubscriptionSection()}
            </div>
          </div>
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("Payment")}</span>
            <div className="subscription-status">
              <Button
                onClick={() => openUpdatePaymentLink()}
                variant="secondary"
              >
                {i18n("Update payment method")}
              </Button>
            </div>
            <div className="subscription-status">
              <Button onClick={() => openChangePlanModal()} variant="secondary">
                {i18n("Change plan")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <CancelSubscriptionModal
        open={openCancelSubscriptionModal}
        closeModal={() => setOpenCancelSubscriptionModal(false)}
      />
    </div>
  );
};

export default connect((state) => ({
  subscription: state.auth.subscription.data,
  user: state.auth.user,
  lang: state.user.userData.preferences.lang,
  userData: state.user.userData,
}))(SettingsBillingContent);
