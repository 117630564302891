import idealistaIcon from "../../../assets/property/idealista.png";
import airbnbIcon from "../../../assets/property/airbnb.png";
import fotocasaIcon from "../../../assets/property/fotocasa.png";
import pisosIcon from "../../../assets/property/pisos.png";
import engelVolkersIcon from "../../../assets/property/engel_volkers.png";
import aPropertiesIcon from "../../../assets/property/aProperties.png";
import callIcon from "../../../assets/core/call.svg";
import mailIcon from "../../../assets/core/mail.svg";
import { connect } from "react-redux";
import { i18n } from "i18n/localisation";
import { toast } from "sonner";
import Button from "components/ui/Button/Button";
import { markPropertyAsDupe } from "api/admin";
import {
  extractPropertyHistory,
  getAdvertisersName,
  getLocalePropertyPrice,
} from "utils/properties";
import { useState } from "react";
import arrowDown from "assets/property/red_arrow.png";
import arrowUp from "assets/property/green_arrow.png";
import moment from "moment";

function ListingsWidget({ property, duplicates, clientMode, permissions }) {
  const [tab, setTab] = useState("listings");

  if (clientMode) {
    return null;
  }

  const listings = [].concat([property], duplicates || []);

  const markAsParent = (listing) => {
    markPropertyAsDupe(listing.id, property.id).then(() => {
      toast.message("Dupe marked successfully");
      window.location.href = `/property/${listing.id}`;
    });
  };

  const getDataSourceTitle = (listing) => {
    if (listing.dataSource === "fotocasa") {
      return "fotocasa";
    } else if (listing.dataSource === "pisos") {
      return "pisos.com";
    } else if (listing.dataSource === "engel_volkers") {
      return "Engel & Völkers";
    } else if (listing.dataSource === "aproperties") {
      return "aProperties";
    } else if (listing.dataSource === "airbnb") {
      return "Airbnb";
    }

    return "idealista";
  };

  const getDataSourceIcon = (listing) => {
    if (listing.dataSource === "fotocasa") {
      return fotocasaIcon;
    } else if (listing.dataSource === "pisos") {
      return pisosIcon;
    } else if (listing.dataSource === "engel_volkers") {
      return engelVolkersIcon;
    } else if (listing.dataSource === "aproperties") {
      return aPropertiesIcon;
    } else if (listing.dataSource === "airbnb") {
      return airbnbIcon;
    }

    return idealistaIcon;
  };

  const renderListings = () => {
    return (
      <>
        <p className="listings-widget_title">{i18n("Property Listings")}</p>
        {listings.map((listing, index) => (
          <div key={index} className="listings-widget_listing">
            <a
              href={listing.url}
              target={`${listing.dataSource}-${listing.id}`}
              className="listings-widget_listing_header"
            >
              <img src={getDataSourceIcon(listing)} />
              <div className="listings-widget_listing_header_title">
                <span>{getDataSourceTitle(listing)}</span>
                <span className="listing-agent">
                  {getAdvertisersName(listing)}
                </span>
              </div>
            </a>
            <div className="listings-widget_listing_body">
              <div className="listings-widget_listing_body_reference">
                <span>{i18n("Ad reference")}: </span>
                {listing.externalReference ? (
                  <span className="ref-num">{listing.externalReference}</span>
                ) : (
                  <span className="ref-num">
                    {i18n("Lister has not supplied a reference")}
                  </span>
                )}
              </div>
              {permissions.mark_dupe && listing.id !== property.id && (
                <Button
                  style={{ width: 140 }}
                  variant="secondary"
                  className="mt-2 flex p-1"
                  onClick={() => markAsParent(listing)}
                >
                  <span className="text-center">Mark as parent</span>
                </Button>
              )}
              {listing.contactInfo.phone && (
                <div className="listings-widget_listing_body_contact">
                  <a
                    href={"tel:" + listing.contactInfo.phone}
                    className="primary-btn"
                  >
                    <img src={callIcon} />
                    <span>{listing.contactInfo.phone}</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderPropertyHistory = () => {
    const propertyHistory = extractPropertyHistory(property, duplicates);

    return (
      <>
        <p className="listings-widget_title">{i18n("Property History")}</p>
        {propertyHistory.map((event, index) => (
          <div key={index} className="listings-widget_listing listing_history">
            <div className="listings-widget_listing_header">
              <img src={event.dataSource.icon} />
              <div className="listings-widget_listing_header_title">
                <span>{getLocalePropertyPrice(event, event.price)}</span>
                {event.priceChange && (
                  <>
                    <img
                      src={
                        event.priceChange === "increase" ? arrowUp : arrowDown
                      }
                      alt="arrow"
                    />
                    <span
                      className={
                        event.priceChange === "increase"
                          ? "listing_history_price-change-up"
                          : "listing_history_price-change-down"
                      }
                    >
                      {event.priceChangePercentage}%
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="listings-widget_listing_body">
              <span>{event.dataSource.title}</span>
              <i>{event.label}</i>
              <span className="listing-agent">{event.advertiser}</span>
              <span>{moment.unix(event.time).format("DD/MM/yyyy")}</span>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="listings-widget">
      <div className="listings-widget_header">
        <div className="listings-widget_tabs">
          <div
            className={`listings-widget_tabs_tab ${tab === "listings" ? "active" : ""}`}
            onClick={() => setTab("listings")}
          >
            {i18n("Listings")}
          </div>
          <div
            className={`listings-widget_tabs_tab ${tab === "history" ? "active" : ""}`}
            onClick={() => setTab("history")}
          >
            {i18n("History")}
          </div>
        </div>
      </div>
      {tab === "listings" ? renderListings() : renderPropertyHistory()}
    </div>
  );
}

export default connect((state) => ({
  clientMode: state.misc.clientMode,
  permissions: state.user.userData.permissions,
}))(ListingsWidget);
