var awsExports = {
  REGION: "eu-west-2",
  USER_POOL_ID: "eu-west-2_FIUchQnFz",
  USER_POOL_APP_CLIENT_ID: "6ugp8o3cjv6395uiob3pn0cdat",
  IDENTITY_POOL_ID: "eu-west-2:b8ebd043-4a39-4890-8bfa-f7d642d0b573",
  MEDIA_BUCKET: "irealty-media-dev",
};

if (process.env.REACT_APP_NODE_ENV === "production") {
  awsExports = {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_rvCwlscmb",
    USER_POOL_APP_CLIENT_ID: "670ofnuahlld0lmbmbqlqivgt7",
    IDENTITY_POOL_ID: "eu-west-2:0ebc9817-907f-4bde-bc72-ae7819e8c6da",
    MEDIA_BUCKET: "irealty-media-prod",
  };
}

export default awsExports;
