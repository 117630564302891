import { fetchCollections, saveCollection } from "actions/collectionActions";
import checkIcon from "assets/core/check.svg";
import deleteIcon from "assets/core/delete.svg";
import duplicateIcon from "assets/core/duplicate.svg";
import editIcon from "assets/core/edit.svg";
import clsx from "clsx";
import HamburgerMenu from "components/core/HamburgerMenu";
import Button from "components/ui/Button/Button";
import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSearchSubtitle, getSearchTitle } from "utils/helpers";
import notificationIcon from "../../assets/navbar/notification.svg";
import { toggleNotificationsForSearchRequest } from "api/collections";
import { toast } from "sonner";
import { setGlobalLoading, setGlobalLoadingMessage } from "actions/miscActions";

const SearchTile = ({
  search,
  collection,
  onClick,
  className,
  onEditTitle,
  onDeleteSearch,
  onDuplicateSearch,
  ...props
}) => {
  const dispatch = useDispatch();

  // notifications enavbled are either 1 or 0 on the backend for dynamodb indexing reasons
  const [notificationsEnabled, setNotificationsEnabled] = useState(
    search.notifications_enabled &&
      parseFloat(search.notifications_enabled) === 1,
  );
  const handleClick = () => {
    onClick();
  };

  const menuItems = [
    {
      text: i18n("Edit title"),
      icon: editIcon,
      onClick: () => onEditTitle(),
    },
    {
      text: i18n("Duplicate"),
      icon: duplicateIcon,
      onClick: () => onDuplicateSearch(),
    },
    {
      text: i18n("Delete"),
      icon: deleteIcon,
      onClick: () => onDeleteSearch(),
    },
  ];

  const onNotificationsEnabledChange = async () => {
    setNotificationsEnabled(!notificationsEnabled);
    dispatch(setGlobalLoading(true));
    dispatch(setGlobalLoadingMessage(i18n("Updating notification settings")));
    await toggleNotificationsForSearchRequest(
      search.id,
      collection.id,
      !notificationsEnabled,
    );
    dispatch(fetchCollections());
    dispatch(setGlobalLoading(false));
    toast.success(i18n("Notification settings updated"));
  };

  return (
    <div className={clsx(className, "tile flex flex-col")} {...props}>
      <div
        className={clsx(
          "relative h-[268px] w-[268px] cursor-pointer rounded-2xl border-4 border-white bg-gray-200 transition-all duration-200 ease-in-out hover:opacity-75",
        )}
        onClick={handleClick}
      >
        <img
          src={search.thumbnail}
          className="absolute h-full w-full rounded-xl object-cover"
        />
      </div>
      <div className="title-content flex w-[268px] flex-col py-3">
        <p className="text-lg font-medium">{getSearchTitle(search)}</p>
        <p className="mb-3 text-sm text-gray-500">
          {getSearchSubtitle(search)}
        </p>
      </div>
      <Button
        onClick={onNotificationsEnabledChange}
        className="notify-btn"
        variant="secondary"
      >
        <img
          src={notificationsEnabled ? checkIcon : notificationIcon}
          alt="Save search icon"
        />
        <span>
          {notificationsEnabled ? i18n("Notification set") : i18n("Notify me")}
        </span>
      </Button>
      <HamburgerMenu items={menuItems} />
    </div>
  );
};

export default SearchTile;
