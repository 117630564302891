import { createAction } from "@reduxjs/toolkit";

const setGlobalLoading = createAction("misc/setGlobalLoading");
const setGlobalLoadingMessage = createAction("misc/setGlobalLoadingMessage");
const setClientMode = createAction("misc/setClientMode");
const setInitialDataLoaded = createAction("misc/setInitialDataLoaded");
const setAppWideLogoLoader = createAction("misc/setAppWideLogoLoader");
const setPricingModal = createAction("misc/setPricingModal");
const setHighlightProIcon = createAction("misc/setHighlightProIcon");
const setChangePlanModal = createAction("misc/setChangePlanModal");

export {
  setGlobalLoading,
  setGlobalLoadingMessage,
  setClientMode,
  setInitialDataLoaded,
  setAppWideLogoLoader,
  setPricingModal,
  setHighlightProIcon,
  setChangePlanModal,
};
