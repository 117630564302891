import { i18n } from "i18n/localisation";

export default function AdvertisersCommentsCard({ property }) {
  return (
    <div className="advertisers-comments details-card">
      <div className="details-card_contents">
        <p className="details-card_title">{i18n("Advertisers Comments")}</p>
        <div className="horizontal-divider"></div>
        <p
          className="details-card_description"
          dangerouslySetInnerHTML={{
            __html: property.description
              .replaceAll(/\n{2,}/g, "<br /><br />")
              .replaceAll("\n", "<br />"),
          }}
        />
      </div>
    </div>
  );
}
