import { createAction } from "@reduxjs/toolkit";

const setCatastroProperty = createAction("myProperties/setCatastroProperty");
const setListingType = createAction("myProperties/setListingType");
const setPropertyType = createAction("myProperties/setPropertyType");
const setListingAgent = createAction("myProperties/setListingAgent");
const setLocationPreference = createAction(
  "myProperties/setLocationPreference",
);
const clearUploadProperty = createAction("myProperties/clearUploadProperty");

export {
  clearUploadProperty,
  setCatastroProperty,
  setListingAgent,
  setListingType,
  setLocationPreference,
  setPropertyType,
};
