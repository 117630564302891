import { useEffect } from "react";
import closeIcon from "../../assets/core/close_white.svg";

const FullscreenContainer = (props) => {
  useEffect(() => {
    setTimeout(() => {
      document
        .querySelector(".fullscreen-container")
        .classList.add("fullscreen-container-fade-in");
    }, 1);
  }, []);

  return (
    <div className="fullscreen-container">
      <div
        className="fullscreen-container_bg"
        onClick={props.onCloseFullscreen}
      />
      <div
        onClick={props.onCloseFullscreen}
        className="fullscreen-container_close"
      >
        <img src={closeIcon} alt="close" />
      </div>
      <div className="fullscreen-container_content">{props.children}</div>
    </div>
  );
};

export default FullscreenContainer;
