import fireApiRequest from "./core";

var FETCH_MAP_SEARCH_INDEXES_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_map_search_indexes";
var FETCH_GEOBOUNDARY_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_geoboundary";
var FETCH_MUNICIPALITIES_BY_BOUNDS_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_municipalities_by_bounds";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_MAP_SEARCH_INDEXES_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_map_search_indexes";
  FETCH_GEOBOUNDARY_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_geoboundary";
  FETCH_MUNICIPALITIES_BY_BOUNDS_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_municipalities_by_bounds";
}

/**
 * fetches map search indexes
 */
export async function fetchMapSearchIndexesRequest() {
  let response = await fireApiRequest(FETCH_MAP_SEARCH_INDEXES_ENDPOINT, {
    method: "POST",
  });
  return response.indexes;
}

/**
 * fetches single geoboundary entity
 */
export async function fetchGeoboundaryRequest(id) {
  return await fireApiRequest(FETCH_GEOBOUNDARY_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({ id }),
  });
}

/**
 * fetches plots within a certain bounds from the server
 */
export async function fetchMunicipalitiesByBounds(bounds) {
  let municipalities = [];

  try {
    let data = await fireApiRequest(FETCH_MUNICIPALITIES_BY_BOUNDS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({ bounds }),
    });

    municipalities = data.municipalities;
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Fetch aborted");
    } else {
      // Handle other errors
    }
  }

  return municipalities;
}
