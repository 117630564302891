import fireApiRequest from "./core";

var FETCH_USER_NOTIFICATIONS =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_user_notifications";
var MARK_NOTIFICATIONS_AS_READ =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/mark_notifications_read";
var DELETE_NOTIFICATIONS =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/delete_notifications";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_USER_NOTIFICATIONS =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_user_notifications";
  MARK_NOTIFICATIONS_AS_READ =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/mark_notifications_read";
  DELETE_NOTIFICATIONS =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/delete_notifications";
}

/**
 * Fetch user notifications request
 */
export async function fetchUserNotificationsRequest() {
  try {
    return await fireApiRequest(FETCH_USER_NOTIFICATIONS, { method: "POST" });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Fetch user notifications request
 */
export async function markNotificationsAsReadRequest(notificationIds) {
  try {
    return await fireApiRequest(MARK_NOTIFICATIONS_AS_READ, {
      method: "POST",
      body: JSON.stringify({
        notification_ids: notificationIds,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Delete user notifications request
 */
export async function deleteNotificationsRequest(notificationIds) {
  try {
    return await fireApiRequest(DELETE_NOTIFICATIONS, {
      method: "DELETE",
      body: JSON.stringify({
        notification_ids: notificationIds,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}
