import { createReducer } from "@reduxjs/toolkit";
import { setMapIndexes } from "../actions/geoActions";

const initialState = {
  mapIndexes: [],
};

const geoReducer = createReducer(initialState, (builder) => {
  builder.addCase(setMapIndexes, (state, action) => {
    state.mapIndexes = action.payload;
  });
});

export default geoReducer;
