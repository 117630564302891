import React from "react";
import PropTypes from "prop-types";
import { i18n } from "i18n/localisation";

const UpgradeView = ({ variant }) => {
  const getImageSrc = () => {
    if (variant && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(variant)) {
      return require(
        `../../assets/property/blurred_bank_property_images/${variant}.png`,
      );
    } else {
      // Random image from 1 to 3
      const randomImage = Math.floor(Math.random() * 10) + 1;
      return require(
        `../../assets/property/blurred_bank_property_images/${randomImage}.png`,
      );
    }
  };

  return (
    <div
      className="upgrade-view"
      style={{ backgroundImage: `url(${getImageSrc()})` }}
    >
      <div className="upgrade-text">{i18n("Upgrade to view")}</div>
    </div>
  );
};

UpgradeView.propTypes = {
  variant: PropTypes.number,
};

export default UpgradeView;
