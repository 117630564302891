import checkIcon from "assets/core/check.svg";
import { CollectionsContext } from "context/CollectionsContext";
import { i18n } from "i18n/localisation";
import { useContext, useState } from "react";
import notificationIcon from "../../assets/navbar/notification.svg";
import Button from "../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import "./search.scss";

const SearchSaveModal = ({ isOpen, closeModal, searchObject }) => {
  const [searchName, setSearchName] = useState("");
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const { openAddToCollectionModal } = useContext(CollectionsContext);

  const onKeyDown = (e) => {
    if (e.key === "Enter" && searchName.length > 0) {
      handleOnSubmit();
      window.removeEventListener("keydown", onKeyDown);
      e.preventDefault();
      return;
    }

    if (e.key === "Escape") {
      closeModal();
      window.removeEventListener("keydown", onKeyDown);
      return;
    }
  };

  const handleOnSubmit = async () => {
    closeModal();

    openAddToCollectionModal({
      ...searchObject,
      title: searchName,
      notifications_enabled: notificationsEnabled ? 1 : 0,
    });

    setSearchName("");
    setNotificationsEnabled(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <DialogContent className="save-search-modal sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Save Search")}</DialogTitle>
        </DialogHeader>
        <div className="px-4">
          <p className="mb-2 text-sm font-medium text-gray-700">
            {i18n("Name")}
          </p>
          <input
            type="text"
            className="w-full rounded-lg border border-gray-300 p-2"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            maxLength={50}
            onKeyDown={(e) => onKeyDown(e)}
          />
          <p className="mt-2 text-right text-sm text-gray-500">
            {searchName.length}/50 {i18n("characters")}
          </p>
        </div>
        <div className="save-search-modal_notify mt-4 flex justify-between gap-8">
          <span className="save-search-modal_notify_info self-center text-sm">
            <strong>{i18n("Get notified of new properties")}</strong>{" "}
            {i18n("that appear in your saved search via email.")}
          </span>
          <Button
            onClick={() =>
              notificationsEnabled
                ? setNotificationsEnabled(false)
                : setNotificationsEnabled(true)
            }
            className="save-search-modal_notify_btn"
            variant="secondary"
          >
            <img
              src={notificationsEnabled ? checkIcon : notificationIcon}
              alt="Save search icon"
            />
            <span>
              {notificationsEnabled
                ? i18n("Notification set")
                : i18n("Notify me")}
            </span>
          </Button>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button
              variant="text"
              disabled={searchName.length === 0}
              onClick={() => setSearchName("")}
            >
              {i18n("Clear")}
            </Button>
            <Button
              variant={searchName.length > 0 ? "primary" : "secondary"}
              disabled={searchName.length === 0}
              onClick={() => handleOnSubmit()}
              className="ml-auto"
            >
              {i18n("Save")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SearchSaveModal;
