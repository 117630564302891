import { createReducer } from "@reduxjs/toolkit";
import {
  setSaveSearchModalOpen,
  setSearchContext,
  setSearchObject,
} from "actions/saveSearchActions";

const initialState = {
  searchContext: null,
  saveSearchModalOpen: false,
  searchObject: null,
};

const saveSearchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSearchContext, (state, action) => {
      state.searchContext = action.payload;
    })
    .addCase(setSaveSearchModalOpen, (state, action) => {
      state.saveSearchModalOpen = action.payload;
    })
    .addCase(setSearchObject, (state, action) => {
      state.searchObject = action.payload;
    });
});

export default saveSearchReducer;
