import { createReducer } from "@reduxjs/toolkit";
import { setCollections } from "../actions/collectionActions";

// initial collection object
const COLLECTION_INITIAL = {
  properties: [], // array of property objects
  searches: [], // array of search objects [{ "id": "uuid", "filters": [], sort: {}, polygons: [] }]
  title: "",
  metadata: {}, // metadata objects { "notes": "" }
};

// get a copy of an initial collection object
export function getCollectionInitial() {
  return { ...COLLECTION_INITIAL };
}

const initialState = {
  collections: [],
  collectionsFetched: false,
};

const collectionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setCollections, (state, action) => {
    state.collections = action.payload;
    state.collectionsFetched = true;
  });
});

export default collectionsReducer;
