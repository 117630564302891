import { createReducer } from "@reduxjs/toolkit";
import {
  setTeamFetched,
  setUsersForTeam,
  setFetchingTeam,
} from "actions/teamActions";

const initialState = {
  users: [],
  teamFetched: false,
  fetchingTeam: false,
};

const teamReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUsersForTeam, (state, action) => {
      state.users = action.payload;
    })
    .addCase(setTeamFetched, (state, action) => {
      state.teamFetched = action.payload;
    })
    .addCase(setFetchingTeam, (state, action) => {
      state.fetchingTeam = action.payload;
    });
});

export default teamReducer;
