import { createReducer } from "@reduxjs/toolkit";
import {
  setHoveredArea,
  setCanDrawGeographicPolygons,
  setCanShowBoundsToolbarItem,
  setHideBounds,
  setAreaSelectionMode,
  setHideMapTools,
  setAvailableAreas,
  toggleSelectedArea,
  setSelectedAreas,
} from "actions/mapActions";
import { MAX_ALLOWED_AREAS } from "config/constants";
import { i18n } from "i18n/localisation";
import { toast } from "sonner";
import {
  clearAllActiveGeographicPolygons,
  clearAllDrawnGeographicPolygons,
  clearAllDrawnMunicipalities,
  hideGeographicBounds,
  hideMunicipalityBounds,
  setSelectedDrawnDistricts,
  showGeographicBounds,
  showMunicipalityBounds,
} from "utils/map";

const initialState = {
  hoveredArea: null,
  canDrawGeographicPolygons: true,
  canShowBoundsToolbarItem: true,
  hideBounds: false,
  selectedAreas: [],
  areaSelectionMode: null,
  availableAreas: [],
  hideMapTools: false,
};

const mapReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setHoveredArea, (state, action) => {
      state.hoveredArea = action.payload;
    })
    .addCase(setCanDrawGeographicPolygons, (state, action) => {
      state.canDrawGeographicPolygons = action.payload;

      // hide or show bounds
      if (!action.payload) {
        state.hoveredArea = null;
        hideGeographicBounds();
      } else {
        if (!state.hideBounds) {
          showGeographicBounds();
        }
      }
    })
    .addCase(setCanShowBoundsToolbarItem, (state, action) => {
      state.canShowBoundsToolbarItem = action.payload;
    })
    .addCase(setHideBounds, (state, action) => {
      state.hideBounds = action.payload;

      if (action.payload) {
        hideGeographicBounds();
      } else {
        showGeographicBounds();
      }
    })
    .addCase(toggleSelectedArea, (state, action) => {
      const { selectedAreas } = state;
      const area = action.payload;

      if (
        !selectedAreas.find((a) => a.id == area.id) &&
        selectedAreas.length < MAX_ALLOWED_AREAS
      ) {
        const selected = [...selectedAreas, area];
        state.selectedAreas = selected;
        setSelectedDrawnDistricts(selected);
      } else {
        const filtered = selectedAreas.filter((a) => a.id !== area.id);
        state.selectedAreas = filtered;
        setSelectedDrawnDistricts(filtered);
      }
    })
    .addCase(setSelectedAreas, (state, action) => {
      state.selectedAreas = action.payload;
    })
    .addCase(setAreaSelectionMode, (state, action) => {
      state.areaSelectionMode = action.payload;

      // clear active state for the municipality
      if (!action.payload) {
        clearAllActiveGeographicPolygons();
      }
    })
    .addCase(setAvailableAreas, (state, action) => {
      state.availableAreas = action.payload;
    })
    .addCase(setHideMapTools, (state, action) => {
      state.hideMapTools = action.payload;
    });
});

export default mapReducer;
