import { clearUploadProperty } from "actions/myPropertiesActions";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import "../collections/collections.scss";
import EmptyTileContainer from "../core/EmptyTileContainer";
import Dashboard from "../Dashboard";
import MyPropertyTile from "./MyPropertyTile";
import UploadPropertyModal from "./UploadPropertyModal";

// TODO: remove this mock data and fetch the real data
// when upload property flow is implemented
const property = {
  property: {
    listingHistory: [
      {
        operation: "relisted",
        price: "400000",
        time: "1725540637.7536566257476806640625",
      },
    ],
    privateBank_propertyStatus: "notAvailable",
    building_use: [],
    privateBank_assetType: null,
    status: "good",
    building_garageSpaces: null,
    garage_multipleSpaces: false,
    saleType: "sale",
    commercial_shopWindows: null,
    refcat: "None",
    description_en:
      "Graciano Inmobiliaria sells apartment in Marbella 200m from the beach.\n\nThe property is distributed in living room with balcony of 20m2 overlooking the common areas and pool. Fitted kitchen with gallery, 2 large bedrooms with closets and two bathrooms, one of them in the master bedroom.\n\nIt also has parking space, common areas, swimming pool and children's area.\n\nVery close to the beach at only 200m. Cozy place in Lunamar urbanization.\n\nDo not miss this opportunity and come and see it!\n\nTlf:.",
    municipality: "Marbella",
    orientations: [],
    partition: "Malaga",
    country: "es",
    privateBank_idufir: null,
    garage_spaceSize: null,
    privateBank_sourceDepartment: null,
    land_buildableFloors: null,
    multimedia: {
      images: [
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/1f/64/f6/1221364578.jpg",
          tag: "Foto",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/7e/ce/8b/1221364621.jpg",
          tag: "Piscina",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/2b/3e/48/1221364643.jpg",
          tag: "Sal\u00f3n",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/08/6f/3f/1221364627.jpg",
          tag: "Sal\u00f3n",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/fe/51/5b/1221364625.jpg",
          tag: "Cocina",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/e0/9b/ed/1221364626.jpg",
          tag: "Cocina",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/65/a1/70/1221364637.jpg",
          tag: "Cocina",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/ed/5d/18/1221364653.jpg",
          tag: "Dormitorio",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/aa/93/66/1221364622.jpg",
          tag: "Dormitorio",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/f8/33/a7/1221364646.jpg",
          tag: "Dormitorio",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/2d/1f/01/1221364630.jpg",
          tag: "Dormitorio",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/69/8f/8c/1221364634.jpg",
          tag: "Dormitorio",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/5a/59/18/1221364647.jpg",
          tag: "Dormitorio",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/85/4b/65/1221364645.jpg",
          tag: "Ba\u00f1o",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/f3/a9/37/1221364620.jpg",
          tag: "Ba\u00f1o",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/90/a0/14/1221364579.jpg",
          tag: "Ba\u00f1o",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/62/83/36/1221364631.jpg",
          tag: "Ba\u00f1o",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/c9/3b/6c/1221364628.jpg",
          tag: "Foto",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/a9/4d/94/1221364629.jpg",
          tag: "Foto",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/30/f1/97/1221364619.jpg",
          tag: "Terraza",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/d2/33/d8/1221364644.jpg",
          tag: "Terraza",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/56/0c/fb/1221364635.jpg",
          tag: "Piscina",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/68/fd/dd/1221364636.jpg",
          tag: "Sal\u00f3n",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/7d/f7/3e/1221364632.jpg",
          tag: "Detalles",
        },
        {
          url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/6e/b0/73/1221364638.jpg",
          tag: "Foto",
        },
      ],
      videos: [],
      plans: [],
      virtualTour: null,
    },
    privateBank_postalCode: null,
    bathrooms: "2",
    numFloors: null,
    propertyCode: "104459976",
    dataSource: "idealista",
    thumbnail:
      "https://img4.idealista.com/blur/WEB_DETAIL_TOP-L-L/0/id.pro.es.image.master/1f/64/f6/1221364578.jpg",
    latitude: "36.496196",
    energyCertificate: {
      inProgress: true,
      consumptionGrade: null,
      emissionGrade: null,
    },
    office_features: {
      hasDoubleGlazing: false,
      hasCentralHeating: false,
      isWarehouse: false,
      hasKitchen: false,
      hasToilets: false,
      hasHotWater: false,
      hasDisabledAccess: false,
      hasRaisedFloor: false,
      hasAirConditioning: false,
      hasFalseCeiling: false,
    },
    building_numUnits: null,
    privateBank_executed: null,
    garage_vehicle: null,
    address: "Barrio Costabella",
    floor: "1",
    garage_features: {
      hasAutomaticDoor: false,
      hasLift: false,
      hasSecurity: false,
      hasAlarm: false,
      hasCCTV: false,
      isCovered: false,
    },
    longitude: "-4.7975272",
    neighborhood: "Costabella",
    rooms: "2",
    commercial_features: {
      hasHeating: false,
      hasStoreroom: false,
      hasSmokeExtractor: false,
      hasAlarmSystem: false,
      hasOffice: false,
      isOnCorner: false,
      hasAuxiliaryEntrance: false,
      hasExtractorFan: false,
      hasFullyEquippedKitchen: false,
      hasSafetyGate: false,
      hasAccessibleBathrooms: false,
      hasAirConditioning: false,
      hasCCTV: false,
    },
    description:
      "Graciano Inmobiliaria vende apartamento en Marbella a 200m de la playa. \n\nLa vivienda se encuentra distribuida en sal\u00f3n con balc\u00f3n de 20m2 con vistas a las zonas comunes y piscina. Cocina amueblada con galer\u00eda, 2 amplias habitaciones con armarios y dos cuartos de ba\u00f1o completos uno de ellos en habitaci\u00f3n principal. \n\nAdem\u00e1s dispone de plaza de garaje, zonas comunes, piscina y zona infantil.\n\nMuy cerca de la playa a tan solo 200m. Lugar acogedor en urbanizaci\u00f3n Lunamar. \n\nNo pierdas esta oportunidad y ven a verlo!\n\nTlf:.",
    updatedTime: "1725540637.489566802978515625",
    land_roadAccess: false,
    title: "Piso en venta en Costabella",
    land_buildableSize: null,
    size: "113",
    building_numLifts: null,
    isPrivateBankProperty: false,
    office_security: {
      hasSprinklers: false,
      hasEmergencyExit: false,
      hasControlledAccess: false,
      hasFireproofDoor: false,
      hasSafetyGate: false,
      hasAlarm: false,
      hasFireExtinguisher: false,
      hasFireDetectors: false,
      hasEmergencyLighting: false,
    },
    building_features: {
      hasTenants: null,
    },
    isBankProperty: false,
    commercial_grossAnnualProfitability: null,
    land_features: {
      hasSewage: false,
      hasNaturalGas: false,
      hasElectricity: false,
      hasStreetLighting: false,
      hasWater: false,
      hasSidewalk: false,
    },
    url: "https://www.idealista.com/inmueble/104459976/",
    features: {
      hasLift: true,
      hasCentralHeating: false,
      petsAllowed: false,
      accessibility: false,
      hasBalcony: true,
      hasGarden: false,
      hasTerrace: true,
      hasSeaView: false,
      hasGreenery: true,
      hasSwimmingPool: true,
      hasStorage: false,
      isLuxury: false,
      hasFittedWardrobes: true,
      childrenAllowed: false,
      hasAirConditioning: false,
      hasGarage: true,
    },
    parentDuplicateId: "none",
    land_type: "urban",
    contactInfo: {
      userType: "professional",
      agencyLogo: "https://st3.idealista.com/38/cd/7b/graciano-lopez.gif",
      phone: null,
      contactName: "Graciano Lopez Blazquez",
      commercialName: "Graciano Lopez Blazquez",
    },
    commercial_type: "commercial",
    office_exclusiveUse: false,
    province: "M\u00e1laga",
    privateBank_source: "",
    id: "104459976-i",
    detailedType: {
      subTypology: "",
      isVilla: false,
      typology: "flat",
    },
    yearConstructed: null,
    externalReference: "G-0454",
    plotSize: null,
    office_distribution: null,
    land_minSaleSize: null,
    privateBank_exclusiveSale: false,
    privateBank_modificationDate: null,
    commercial_facade: null,
    initialImportTime: "1725540637.489548206329345703125",
    priceDropValue: null,
    land_classifications: [],
    privateBank_workInProgress: false,
    commercial_businessActivities: "other",
    district: "Las Chapas-El Rosario",
    office_location: null,
    usableSize: null,
    priceHistory: [],
    buildingType: "property",
    commercial_communityCost: null,
    price: "400000",
    privateBank_fincaRegistral: null,
    storage_features: {
      hasLoadingBay: false,
      is24hAccess: false,
    },
    rental_maxTenants: null,
  },
  duplicates: [
    {
      listingHistory: [],
      privateBank_propertyStatus: "notAvailable",
      building_use: [],
      privateBank_assetType: null,
      status: "good",
      building_garageSpaces: null,
      saleType: "sale",
      garage_multipleSpaces: false,
      commercial_shopWindows: null,
      refcat: "None",
      municipality: "Marbella",
      orientations: ["south", "west"],
      partition: "Malaga",
      country: "es",
      privateBank_idufir: null,
      privateBank_sourceDepartment: null,
      garage_spaceSize: null,
      land_buildableFloors: null,
      multimedia: {
        images: [
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/e4/60/22/1249913071.jpg",
            tag: "Piscina",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/23/c1/ca/1249913072.jpg",
            tag: "Foto",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/b1/d9/7a/1249913113.jpg",
            tag: "Sal\u00f3n",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/4d/7e/8b/1249913114.jpg",
            tag: "Sal\u00f3n",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/27/6e/d7/1249913115.jpg",
            tag: "Sal\u00f3n",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/13/1a/ec/1249913116.jpg",
            tag: "Terraza",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/55/93/8a/1249913117.jpg",
            tag: "Cocina",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/29/82/9d/1249913118.jpg",
            tag: "Cocina",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/b9/9b/8f/1249913119.jpg",
            tag: "Cocina",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/dd/c8/9f/1249913120.jpg",
            tag: "Dormitorio",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/0b/77/97/1249913121.jpg",
            tag: "Dormitorio",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/22/1d/73/1249913122.jpg",
            tag: "Dormitorio",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/27/de/67/1249913134.jpg",
            tag: "Ba\u00f1o",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/b6/05/d7/1249913106.jpg",
            tag: "Dormitorio",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/3a/8b/75/1249913101.jpg",
            tag: "Foto",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/12/36/a1/1249913102.jpg",
            tag: "Pasillo",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/d2/28/5b/1249913154.jpg",
            tag: "Foto",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/65/6e/95/1249913155.jpg",
            tag: "Dormitorio",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/a3/16/ec/1249913156.jpg",
            tag: "Jard\u00edn",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/0f/7b/d8/1249913137.jpg",
            tag: "Foto",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/78/6c/5f/1249913157.jpg",
            tag: "Vistas",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/a5/05/38/1249913158.jpg",
            tag: "Foto",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/91/1a/5d/1249913159.jpg",
            tag: "Terraza",
          },
          {
            url: "https://img4.idealista.com/blur/WEB_DETAIL/0/id.pro.es.image.master/7f/ee/3e/1249913160.jpg",
            tag: "Jard\u00edn",
          },
        ],
        videos: [],
        plans: [],
        virtualTour: null,
      },
      privateBank_postalCode: null,
      propertyCode: "105357329",
      bathrooms: "2",
      numFloors: null,
      dataSource: "idealista",
      thumbnail:
        "https://img4.idealista.com/blur/WEB_DETAIL_TOP-L-L/0/id.pro.es.image.master/e4/60/22/1249913071.jpg",
      latitude: "36.4979727",
      energyCertificate: {
        inProgress: false,
        consumptionGrade: null,
        emissionGrade: null,
      },
      office_features: {
        hasDoubleGlazing: false,
        hasCentralHeating: false,
        isWarehouse: false,
        hasKitchen: false,
        hasToilets: false,
        hasHotWater: false,
        hasDisabledAccess: false,
        hasRaisedFloor: false,
        hasAirConditioning: false,
        hasFalseCeiling: false,
      },
      privateBank_executed: null,
      building_numUnits: null,
      garage_vehicle: null,
      address: "Barrio Costabella",
      floor: "0",
      garage_features: {
        hasAutomaticDoor: false,
        hasLift: false,
        hasSecurity: false,
        hasAlarm: false,
        hasCCTV: false,
        isCovered: false,
      },
      longitude: "-4.7970693",
      neighborhood: "Costabella",
      rooms: "2",
      commercial_features: {
        hasHeating: false,
        hasStoreroom: false,
        hasSmokeExtractor: false,
        hasAlarmSystem: false,
        hasOffice: false,
        isOnCorner: false,
        hasAuxiliaryEntrance: false,
        hasExtractorFan: false,
        hasFullyEquippedKitchen: false,
        hasSafetyGate: false,
        hasAccessibleBathrooms: false,
        hasAirConditioning: false,
        hasCCTV: false,
      },
      updatedTime: "1725540743.0865252017974853515625",
      description: "Precio sin honorarios del 5%.",
      land_roadAccess: false,
      title: "Piso en venta en Costabella",
      size: "113",
      land_buildableSize: null,
      building_numLifts: null,
      isPrivateBankProperty: false,
      office_security: {
        hasSprinklers: false,
        hasEmergencyExit: false,
        hasControlledAccess: false,
        hasFireproofDoor: false,
        hasSafetyGate: false,
        hasAlarm: false,
        hasFireExtinguisher: false,
        hasFireDetectors: false,
        hasEmergencyLighting: false,
      },
      building_features: {
        hasTenants: null,
      },
      isBankProperty: false,
      commercial_grossAnnualProfitability: null,
      land_features: {
        hasSewage: false,
        hasNaturalGas: false,
        hasElectricity: false,
        hasStreetLighting: false,
        hasWater: false,
        hasSidewalk: false,
      },
      url: "https://www.idealista.com/inmueble/105357329/",
      parentDuplicateId: "104459976-i",
      features: {
        hasLift: true,
        hasCentralHeating: false,
        petsAllowed: false,
        accessibility: false,
        hasBalcony: true,
        hasGarden: false,
        hasTerrace: true,
        hasSeaView: false,
        hasGreenery: true,
        hasSwimmingPool: true,
        hasStorage: false,
        isLuxury: false,
        hasFittedWardrobes: false,
        childrenAllowed: false,
        hasAirConditioning: false,
        hasGarage: false,
      },
      land_type: "urban",
      contactInfo: {
        userType: "professional",
        agencyLogo: "https://st3.idealista.com/1d/62/e2/grupo-nodalia.gif",
        phone: null,
        contactName: "Grupo Nodalia",
        commercialName: "Grupo Nodalia",
      },
      commercial_type: "commercial",
      office_exclusiveUse: false,
      province: "M\u00e1laga",
      privateBank_source: "",
      id: "105357329-i",
      yearConstructed: "1991",
      detailedType: {
        subTypology: "",
        isVilla: false,
        typology: "flat",
      },
      externalReference: "LM001KV",
      plotSize: null,
      office_distribution: null,
      land_minSaleSize: null,
      privateBank_modificationDate: null,
      privateBank_exclusiveSale: false,
      initialImportTime: "1725540743.0865085124969482421875",
      commercial_facade: null,
      priceDropValue: null,
      land_classifications: [],
      privateBank_workInProgress: false,
      commercial_businessActivities: "other",
      district: "Las Chapas-El Rosario",
      usableSize: null,
      office_location: null,
      priceHistory: [],
      commercial_communityCost: null,
      buildingType: "property",
      price: "400000",
      privateBank_fincaRegistral: null,
      storage_features: {
        hasLoadingBay: false,
        is24hAccess: false,
      },
      rental_maxTenants: null,
    },
  ],
  is_deleted_property: false,
};

const MyPropertiesPage = (props) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [myProperties, setMyProperties] = useState([property]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTeamPortfolio = async () => {
      try {
        //const response = await fetchTeamPortfolioRequest();
        setMyProperties([property, property, property]);
      } catch (error) {
        console.error("Error fetching user properties", error);
      }
    };

    fetchTeamPortfolio();
  }, []);

  const onClickEmptyProperty = () => {
    dispatch(clearUploadProperty());
    setIsUploadModalOpen(true);
    sendAnalyticsEvent("My Properties", {
      type: "click upload property",
    });
  };

  const onDeleteProperty = (property) => {
    //openDeleteModal(collection);
    return;
  };

  const onDuplicateProperty = (property) => {
    toast(i18n("Property has been duplicated"), { duration: 2000 });
    return;
  };

  const onEditTitle = (property, newTitle) => {
    //setEditingObjectTitle(property);
    return;
  };

  return (
    <Dashboard>
      <Helmet>
        <title>{i18n("My Properties")}</title>
      </Helmet>

      <div className="pr-18 collections-page align-center flex min-h-screen w-full flex-col bg-gray-100 p-12 py-16 md:pl-36">
        <h1 className="mb-8 text-3xl font-bold">My Properties</h1>
        <div className="w-full max-w-[1140px] ">
          {myProperties.length === 0 && (
            <>
              <h2 className="mb-8 text-3xl font-bold">
                {i18n("Create your first property listing")}
              </h2>
              <div className="mb-8 max-w-prose text-base">
                <p className="mb-2">
                  {i18n(
                    "Upload your property details and add imagery to create a listing.",
                  )}
                </p>
                <p>
                  {i18n(
                    "You can choose to make your property listing public so others can find it through search, or keep it private and share it selectively.",
                  )}
                </p>
              </div>
            </>
          )}

          <div className="mb-4 flex max-w-screen-xl flex-wrap gap-[24px]">
            <EmptyTileContainer
              label={i18n("Upload property")}
              onClick={onClickEmptyProperty}
            />
            {myProperties.length > 0 &&
              myProperties.map((property) => (
                <MyPropertyTile
                  key={property.id}
                  showHamburger
                  onEditTitle={onEditTitle}
                  onDeleteProperty={onDeleteProperty}
                  onDuplicateProperty={onDuplicateProperty}
                  handleOnClick={() =>
                    navigate(`/property/${property.property.id}`)
                  }
                  property={property}
                />
              ))}
          </div>
        </div>
      </div>
      <UploadPropertyModal
        isOpen={isUploadModalOpen}
        closeModal={() => setIsUploadModalOpen(false)}
      />
    </Dashboard>
  );
};

export default connect((state) => ({
  team: state.team,
  user: state.user,
}))(MyPropertiesPage);
