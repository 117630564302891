import { Auth } from "aws-amplify";
import { CANNY_APP_ID } from "../config/constants";

export async function initialiseCanny(userData) {
  const currentUser = await Auth.currentAuthenticatedUser();

  // eslint-disable-next-line no-undef
  Canny("identify", {
    appID: CANNY_APP_ID,
    user: {
      email: currentUser.attributes.email,
      id: currentUser.attributes.sub,
      name: `${userData.personalDetails.firstName} ${userData.personalDetails.lastName}`,
    },
  });
}
