export const sortByCreatedDate = (a, b) => {
  if (a.created_date < b.created_date) return -1;
  if (a.created_date > b.created_date) return 1;
  return 0;
};

export const getCollectionById = (collections, id) => {
  return collections.find((collection) => {
    return collection.id === id;
  });
};

export const getInitialsFromString = (str) => {
  let words = str.split(" ");
  if (words.length < 2) {
    return str.substring(0, 1);
  } else {
    return words[0][0] + words[1][0];
  }
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
