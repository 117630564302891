import { getUsersInTeamRequest } from "api/team";

const { createAction } = require("@reduxjs/toolkit");

const setUsersForTeam = createAction("team/setUsersForTeam");
const setTeamFetched = createAction("team/setTeamFetched");
const setFetchingTeam = createAction("team/setFetchingTeam");

export function fetchUsersForTeam(teamId) {
  return async (dispatch) => {
    dispatch(setFetchingTeam(false));

    try {
      const data = await getUsersInTeamRequest(teamId);
      dispatch(setUsersForTeam(data.users));
      dispatch(setTeamFetched(true));
      dispatch(setFetchingTeam(false));
    } catch (e) {
      console.log(e);
    }
  };
}

export { setUsersForTeam, setTeamFetched, setFetchingTeam };
