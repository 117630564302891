import plusIcon from "../../assets/core/plus.svg";

export const EmptyTileContainer = ({ label, ...props }) => {
  return (
    <div
      className={
        "flex h-[282px] w-[282px] cursor-pointer flex-col items-center justify-center rounded-2xl border-4 border-dashed border-gray-400 transition-all duration-200 ease-in-out hover:border-gray-500" +
        props.className
      }
      {...props}
    >
      <img src={plusIcon} alt="plus" className="mb-2 w-8 opacity-60" />
      <span className="flex text-2xl font-medium text-gray-500">{label}</span>
    </div>
  );
};

export default EmptyTileContainer;
