import MapOverlay from "./MapOverlay";
import "./map.scss";
import propertyPinNibble from "../../assets/map/property_pin_nibble.svg";
import favouriteIcon from "../../assets/map/favourite_icon.svg";
import favouriteIconSelected from "../../assets/map/favourite_icon_selected.svg";
import favouriteIconHovered from "../../assets/map/favourite_icon_hovered.svg";
import { selectPropertyCard } from "../../utils/ui";
import { connect } from "react-redux";
import { useState } from "react";
import { formatNumber } from "utils/helpers";
import { i18n } from "i18n/localisation";

function PropertyMarker(props) {
  const { position, property, map, cluster, disableActions, page } = props;

  const [isHovering, setIsHovering] = useState(false);

  // scroll to the property on the side panel
  const onPropertyPinClick = (e) => {
    if (disableActions) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    if (page !== "cma") {
      props.selectMarker();
      window.propertyPanelScrollToProperty(property.id);
    } else {
      const sidePanel = document.querySelector(".cma-form");
      const selectedCard = document.querySelector(`#property-${property.id}`);
      sidePanel.scrollTop = selectedCard.offsetTop - 210;
    }
  };

  // fires a zoom to cluster event on the parent property map component
  const onClusterClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onClusterClick(cluster);
  };

  // if its a cluster then render the cluster instead of a property pin
  if (cluster && cluster.properties.cluster) {
    let count = cluster.properties.point_count;
    return (
      <MapOverlay
        position={{
          lat: position.lat,
          lng: position.lng,
        }}
        map={map}
      >
        <div
          className={
            "map-cluster" +
            (count < 10 ? " padded-cluster" : "") +
            (count > 99 ? " lesser-padded-cluster" : "")
          }
          onClick={onClusterClick}
          data-properties={props.clusterProperties.map((p) => p.id).join(",")}
        >
          {count > 99 ? "99+" : count}
        </div>
      </MapOverlay>
    );
  }

  let markerText = "";
  let isSelected = props.isSelected || property.isCmaProperty;
  let isInCollection = props.collections.find((c) =>
    c.properties.find((p) => p.id === property.id),
  );

  // catastro refs are displayed on map differently
  if (property.isCatastro) {
    markerText = property.ref;
  } else {
    if (property.saleType == "rent") {
      if (property.rental_isShortTerm) {
        markerText = "€" + formatNumber(property.price) + "/d";
      } else {
        markerText = "€" + formatNumber(property.price) + "/m";
      }
    } else {
      let price = Math.ceil(parseFloat(property.price) / 1000);
      let priceSuffix = "K";

      // format price in the millions
      if (price > 999) {
        price = Math.ceil(price / 100);
        price = parseFloat((price / 10).toFixed(1));
        priceSuffix = "M";
      }

      markerText = `€${price}${priceSuffix}`;

      // if is privateBankProperty and price is 0 then show price on request
      if (property.isPrivateBankProperty && price === 0) {
        markerText = i18n("P.O.R");
      }
    }
  }

  const onHover = (inbound) => {
    if (property.isCatastro) {
      return;
    }

    setIsHovering(inbound);
  };

  return (
    <>
      {map && (
        <MapOverlay
          position={{
            lat: position.lat,
            lng: position.lng,
          }}
          map={map}
        >
          <div
            onMouseOver={() => onHover(true)}
            onMouseOut={() => onHover(false)}
            id={"property-marker-" + property.id}
            onClick={onPropertyPinClick}
            className={
              "property-marker" +
              (isInCollection ? " property-marker-favourite" : "") +
              (isSelected ? " selected" : "") +
              (isHovering ? " hovered" : "")
            }
          >
            <img className="property-marker_nibble" src={propertyPinNibble} />
            {isInCollection &&
              (isSelected ? (
                <img
                  className="property-marker_favourite"
                  src={favouriteIconSelected}
                />
              ) : isHovering ? (
                <img
                  className="property-marker_favourite"
                  src={favouriteIconHovered}
                />
              ) : (
                <img
                  className="property-marker_favourite"
                  src={favouriteIcon}
                />
              ))}
            <span>{markerText}</span>
          </div>
        </MapOverlay>
      )}
    </>
  );
}

export default connect((state) => ({
  collections: state.collections.collections,
}))(PropertyMarker);
