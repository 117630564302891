import "@aws-amplify/ui-react/styles.css";
import { Provider } from "react-redux";
import "./App.css";
import "./components/core/core.scss";
import store from "./store";
import AuthedApp from "AuthedApp";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <Provider store={store}>
        <AuthedApp />
      </Provider>
    </Router>
  );
}

export default App;
