import React from "react";

export const Checkbox = ({
  label,
  checked,
  fillOpacityUnchecked,
  ...props
}) => {
  const uncheckedIcon = (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.41667"
        y="4.4165"
        width="35.1667"
        height="35.1667"
        rx="7.25"
        fill="white"
        fillOpacity={fillOpacityUnchecked || "0.4"}
        stroke="#717171"
        strokeWidth="1.5"
      />
    </svg>
  );

  const checkedIcon = (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.41667"
        y="4.41675"
        width="35.1667"
        height="35.1667"
        rx="7.25"
        fill="#222222"
        stroke="#222222"
        strokeWidth="1.5"
      />
      <path
        d="M15 22L20 27L30 17"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div className="flex items-center space-x-2" {...props}>
      {checked ? checkedIcon : uncheckedIcon}
      <label className="cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap text-sm">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
