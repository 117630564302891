import { getAllMapObjectsByType } from "db/mapStore";
import React, { useEffect, useRef, useState } from "react";
import { capitalizeWord } from "utils/helpers";
import "./admin.scss";
import Button from "components/ui/Button/Button";
import { connect } from "react-redux";
import Navbar from "components/navbar/Navbar";
import { setGlobalLoading, setGlobalLoadingMessage } from "actions/miscActions";
import { uploadGeoboundary } from "api/admin";
import { toast } from "sonner";

const UploadPolygonForm = (props) => {
  const [provinces, setProvinces] = useState([]);
  const [province, setProvince] = useState("");
  const [municipalities, setMunicipalities] = useState([]);
  const [municipality, setMunicipality] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [districtType, setDistrictType] = useState("");
  const [geometry, setGeometry] = useState("");
  const districtTypes = ["district", "zone", "locality"];

  // redirect to home page if user does not have permission
  if (
    props.user.userDataFetched &&
    !props.user.userData.permissions.upload_polygon
  ) {
    window.location.href = "/";
  }

  useEffect(() => {
    // fetch provinces
    getAllMapObjectsByType("es", "province").then((fetchedProvinces) => {
      setProvinces(
        fetchedProvinces
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((p) => ({
            id: p.id,
            name: p.name,
          })),
      );
    });
  }, [props.user.userDataFetched]);

  const onProvinceChange = (provinceName) => {
    setProvince(provinceName);
    const province = provinces.find((p) => p.name === provinceName);

    getAllMapObjectsByType("es", "municipality").then(
      (fetchedMunicipalities) => {
        setMunicipalities(
          fetchedMunicipalities
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((m) => ({
              id: m.id,
              mun_code: m.mun_code,
              name: m.name,
              province: m.province,
            }))
            .filter((m) => m.province === province.name),
        );
      },
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let municipalityObject = municipalities.find(
      (m) => m.name === municipality,
    );

    if (
      districtName.length === 0 ||
      districtType.length === 0 ||
      geometry.length === 0 ||
      municipality.length === 0
    ) {
      toast.error("Please fill in all fields");
      return;
    }

    let geojson = null;

    try {
      geojson = JSON.parse(geometry);
    } catch {
      toast.error("Invalid GeoJSON");
      return;
    }

    if (geojson.type !== "FeatureCollection" && geojson.type !== "Feature") {
      toast.error("Invalid GeoJSON");
      return;
    }

    if (geojson.type === "FeatureCollection") {
      if (geojson.features.length !== 1) {
        toast.error("Must have exactly one polygon in GeoJSON");
        return;
      }
    }

    try {
      props.dispatch(setGlobalLoading(true));
      props.dispatch(setGlobalLoadingMessage("Uploading polygon..."));
      await uploadGeoboundary(
        districtName,
        districtType,
        "es",
        municipalityObject,
        geojson,
      );
      props.dispatch(setGlobalLoading(false));
      toast.success("Polygon uploaded successfully", {
        duration: 2000,
      });
    } catch {
      props.dispatch(setGlobalLoading(false));
      toast.error("Failed to upload polygon");
    }
  };

  return (
    <div className="upload-polygon-page">
      <Navbar />
      <h1 className="upload-polygon-title">Upload Polygon Form</h1>
      <form className="upload-polygon-form" onSubmit={handleSubmit}>
        <select
          value={province.name}
          onChange={(e) => onProvinceChange(e.target.value)}
        >
          <option value="">Select Province</option>
          {provinces.map((p) => (
            <option key={p.id} value={p.name}>
              {p.name}
            </option>
          ))}
        </select>
        <select
          value={municipality.name}
          onChange={(e) => setMunicipality(e.target.value)}
        >
          <option value="">Select Municipality</option>
          {municipalities.map((m) => (
            <option key={m.id} value={m.name}>
              {m.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="District Name"
          value={districtName}
          onChange={(e) => setDistrictName(e.target.value)}
        />
        <select
          value={districtType}
          onChange={(e) => setDistrictType(e.target.value)}
        >
          <option value="">Select District Type</option>
          {districtTypes.map((t) => (
            <option key={t} value={t}>
              {capitalizeWord(t)}
            </option>
          ))}
        </select>
        <textarea
          placeholder="GeoJSON"
          value={geometry}
          onChange={(e) => setGeometry(e.target.value)}
        />
        <Button type="primary" variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default connect((state) => ({
  user: state.user,
}))(UploadPolygonForm);
