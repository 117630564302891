import Dropdown from "components/core/Dropdown";
import PropertyCard from "components/property/PropertyCard";
import Checkbox from "components/ui/Checkbox/Checkbox";
import { i18n } from "i18n/localisation";
import { SORT_LABEL_MAPPINGS, SORT_OPTIONS } from "lib/filter/sort";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import leftIconLucid from "assets/core/lucide_arrow-left.svg";
import rightIconLucid from "assets/core/lucid-arrow_right.svg";

const CmaPropertyPanel = ({
  properties,
  selectedProperties,
  onSelectProperty,
  selectTopMatches,
  topMatchesSelected,
  ...props
}) => {
  let listRef = useRef(null);

  useEffect(() => {
    window.propertyPanelScrollToProperty = scrollToProperty;
  }, [window.propertyPanelScrollToProperty]);

  const [fixedHeader, setFixedHeader] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const onScroll = (e) => {
    if (e.target.scrollTop > 200) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  };

  const scrollToTop = () => {
    const cmaForm = document.querySelector(".cma-form");
    cmaForm.scrollTop = 0;
  };

  useEffect(() => {
    let cmaForm = document.querySelector(".cma-form");
    cmaForm.addEventListener("scroll", onScroll);

    return () => {
      cmaForm.removeEventListener("scroll", onScroll);
    };
  }, []);

  const renderHeader = () => {
    return (
      <div
        className={
          "cma-property-panel_header" + (fixedHeader ? " fixed_header" : "")
        }
      >
        <div
          onClick={selectTopMatches}
          className="cma-property-panel_header_select-all"
        >
          <span>{i18n("Select best matches")}</span>
          <Checkbox checked={topMatchesSelected} />
        </div>
        {fixedHeader && <div className="divider"></div>}
      </div>
    );
  };

  const scrollToProperty = (id) => {
    if (listRef.current) {
      const index = properties.findIndex((p) => p.id === id);
      listRef.current.scrollToItem(index, "start");
    }
  };

  const onSelectPropertyInternal = useCallback((property) => {
    onSelectProperty(property);
  });

  // expanded view
  if (expanded) {
    return (
      <div className="cma-property-panel expanded">
        <div className="cma-property-panel_header flex">
          <div className="flex-1"></div>
          <div className="flex flex-1 justify-end">
            <div style={{ width: 300 }}>
              <div
                onClick={selectTopMatches}
                className="cma-property-panel_header_select-all"
              >
                <span>{i18n("Select best matches")}</span>
                <Checkbox checked={topMatchesSelected} />
              </div>
            </div>
            <div style={{ width: 100 }}>
              <div
                onClick={() => setExpanded(false)}
                className="property-panel_collapse"
              >
                <div>
                  <img src={rightIconLucid} />
                  <span>{i18n("Collapse")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="divider"></div>
        </div>
        <div className="cma-property-panel_content">
          <div>
            <div className="cma-property-panel_property-grid">
              {properties.map((property, index) => (
                <PropertyCard
                  key={index}
                  isSelectable
                  property={property}
                  selected={selectedProperties.includes(property.id)}
                  onSelect={onSelectPropertyInternal}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="cma-property-panel">
      {renderHeader()}
      <div className="cma-property-panel_content">
        <div className="cma-property-panel_properties">
          {properties.map((property, index) => (
            <PropertyCard
              key={index}
              isSelectable
              property={property}
              selected={selectedProperties.includes(property.id)}
              onSelect={onSelectPropertyInternal}
            />
          ))}
        </div>
      </div>
      <div
        onClick={() => setExpanded(true)}
        className="property-panel_expand"
        style={{ bottom: 80 }}
      >
        <div>
          <img src={leftIconLucid} />
          <span>{i18n("Expand")}</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CmaPropertyPanel);
