import { i18n } from "i18n/localisation";
import Button from "../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";

const SignOutModal = ({ signOut, closeModal, open }) => {
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        closeModal();
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Log out?")}</DialogTitle>
        </DialogHeader>
        <div className="p-4">
          <p className="mb-2 text-sm font-medium text-gray-700">
            {i18n("Are you sure you want to log out?")}
          </p>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="text" onClick={() => closeModal()}>
              {i18n("Cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={() => signOut()}
              className="ml-auto"
            >
              {i18n("Yes, log out")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SignOutModal;
