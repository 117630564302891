import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { i18n } from "i18n/localisation";
import Button from "../Button/Button";

function ConfirmModal({
  open,
  onCancel,
  onConfirm,
  onClose,
  title,
  confirmationText,
  confirmText,
  cancelText,
}) {
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        onClose();
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="p-4">
          <p className="mb-2 text-sm font-medium text-gray-700">
            {confirmationText}
          </p>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="text" onClick={() => onCancel()}>
              {i18n(cancelText ? cancelText : "Cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={() => onConfirm()}
              className="ml-auto"
            >
              {i18n(confirmText ? confirmText : "Yes")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmModal;
