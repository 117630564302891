import { setSearchContext } from "actions/saveSearchActions";
import EditObjectTitleModal from "components/collections/modals/EditObjectTitleModal";
import { i18n } from "i18n/localisation";
import { createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import {
  addPropertyToCollection,
  addSearchToCollection,
  createCollection,
  deleteCollection,
  removePropertyFromCollection,
  removeSearchFromCollection,
  saveCollection,
  saveMetaDataToCollection,
} from "../actions/collectionActions";
import AddToCollectionModal from "../components/collections/modals/AddToCollectionModal";
import CollectionCreateModal from "../components/collections/modals/CollectionCreateModal";
import CollectionDeleteModal from "../components/collections/modals/CollectionDeleteModal";
import CollectionMetaModal from "../components/collections/modals/CollectionMetaModal";

export const CollectionsContext = createContext();

export const CollectionsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const collections = useSelector((state) => state.collections);

  const [isCreateModalOpen, setCreateIsModalOpen] = useState(false);

  // use collection as state
  const [isMetaModalOpen, setMetaIsModalOpen] = useState(null);

  // use property as state
  const [activeAddToCollectionObject, setActiveAddToCollectionObject] =
    useState(null);

  // use collection as state
  const [isDeleteModalOpen, setDeleteIsModalOpen] = useState(null);

  // editing of collection or search titles
  const [editingObjectTitle, setEditingObjectTitle] = useState(null);

  // object to which will be added to selected collections
  const [collectionAdditionObject, setCollectionAdditionObject] =
    useState(null);

  // CREATE MODAL
  const openCreateModal = () => {
    setCreateIsModalOpen(true);
  };

  const closeCreateModal = (continueProcess = false) => {
    setCreateIsModalOpen(false);

    if (collectionAdditionObject && continueProcess) {
      openAddToCollectionModal(collectionAdditionObject, true);
      setCollectionAdditionObject(null);
    }
  };

  const handleCreateCollection = (collectionName) => {
    dispatch(createCollection({ title: collectionName }));
    closeCreateModal(true);
  };

  // DELETE MODAL
  const openDeleteModal = (collection) => {
    setDeleteIsModalOpen(collection);
  };

  const closeDeleteModal = () => {
    setDeleteIsModalOpen(null);
  };

  const handleDeleteCollection = (collection) => {
    dispatch(deleteCollection(collection));
    closeDeleteModal();
    navigate("/collections");
  };

  // META MODAL
  const openMetaModal = (collection) => {
    setMetaIsModalOpen(collection);
  };

  const closeMetaModal = () => {
    setMetaIsModalOpen(null);
  };

  const handleMetaCollection = (collection, metadata) => {
    dispatch(saveMetaDataToCollection(collection, metadata));
    closeMetaModal();
  };

  // ADD TO COLLECTION MODAL
  const openAddToCollectionModal = (object, fromCollectionCreation = false) => {
    if (collections.collections.length === 0 && !fromCollectionCreation) {
      onCreateCollectionFlow(object);
    } else {
      setActiveAddToCollectionObject(object);
    }
  };

  const closeAddToCollectionModal = () => {
    setActiveAddToCollectionObject(null);
  };

  const handleObjectToCollection = (
    object,
    addToCollections,
    removeFromCollections,
  ) => {
    removeFromCollections.forEach((collection) => {
      if (object.objectType == "search") {
        dispatch(removeSearchFromCollection(collection, object));
      } else {
        dispatch(removePropertyFromCollection(collection, object));
      }
    });

    addToCollections.forEach((collection) => {
      if (object.objectType == "search") {
        dispatch(addSearchToCollection(collection, object));
      } else {
        dispatch(addPropertyToCollection(collection, object));
      }
    });

    toast(i18n("Collection has been updated."), { duration: 2000 });

    // if search is added to collection, update search context
    if (object.objectType == "search" && addToCollections.length > 0) {
      dispatch(
        setSearchContext({
          search: object,
          collection: addToCollections[0],
        }),
      );
    }

    closeAddToCollectionModal();
  };

  // save new title for collection or searchb object
  const handleEditTitle = (object, newTitle) => {
    // editing search title
    if (object.objectType == "search") {
      let collection = collections.collections.find(
        (c) => c.id == object.parentId,
      );

      if (collection) {
        let newSearchObject = { ...object };
        newSearchObject.title = newTitle;
        dispatch(addSearchToCollection(collection, newSearchObject));
        toast(i18n("Search has been updated"), { duration: 2000 });
      }

      return;
    }

    // editing collection title
    let collectionToUpdate = { ...object };
    collectionToUpdate.title = newTitle;
    dispatch(saveCollection(collectionToUpdate));
    toast(i18n("Collection has been updated"), { duration: 2000 });
  };

  const onCreateCollectionFlow = (object) => {
    closeAddToCollectionModal();
    openCreateModal();
    setCollectionAdditionObject(object);
  };

  const closeEditObjectTitleModal = () => {
    setEditingObjectTitle(null);
  };

  return (
    <CollectionsContext.Provider
      value={{
        isCreateModalOpen,
        openCreateModal,
        closeCreateModal,
        isDeleteModalOpen,
        openDeleteModal,
        closeDeleteModal,
        isMetaModalOpen,
        openMetaModal,
        closeMetaModal,
        activeAddToCollectionObject,
        openAddToCollectionModal,
        closeAddToCollectionModal,
        editingObjectTitle,
        setEditingObjectTitle,
        closeEditObjectTitleModal,
      }}
    >
      {children}
      <CollectionCreateModal
        collections={collections.collections}
        onSubmit={handleCreateCollection}
      />
      <CollectionDeleteModal onSubmit={handleDeleteCollection} />
      <CollectionMetaModal onSubmit={handleMetaCollection} />
      <AddToCollectionModal
        collections={collections.collections}
        onSubmit={handleObjectToCollection}
        onCreateCollectionFlow={onCreateCollectionFlow}
      />
      <EditObjectTitleModal
        onSubmit={handleEditTitle}
        object={editingObjectTitle}
        objectTitle={editingObjectTitle ? editingObjectTitle.title ?? "" : ""}
      />
    </CollectionsContext.Provider>
  );
};
