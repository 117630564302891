import React from "react";
import { i18n } from "i18n/localisation";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from "chart.js";
import { statisticsPlugin } from "utils/stats";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ...registerables,
  statisticsPlugin,
);

const ChartCard = ({
  property,
  chartType = "line",
  data,
  options,
  title,
  statistics,
}) => {
  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            family: "'Inter', sans-serif",
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        titleColor: "#000",
        bodyColor: "#666",
        borderColor: "#ddd",
        borderWidth: 1,
        padding: 12,
        bodyFont: {
          family: "'Inter', sans-serif",
        },
        titleFont: {
          family: "'Inter', sans-serif",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "#f5f5f5",
        },
        ticks: {
          font: {
            family: "'Inter', sans-serif",
            size: 11,
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "'Inter', sans-serif",
            size: 11,
          },
        },
      },
    },
  };

  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };

  const renderChart = () => {
    if (chartType === "line") {
      return <Line data={data} options={mergedOptions} height={300} />;
    }
    return <Bar data={data} options={mergedOptions} height={300} />;
  };

  return (
    <div className="chart-card details-card">
      <div className="details-card_contents">
        <p className="details-card_title">{title || i18n("Chart")}</p>
        <div className="horizontal-divider"></div>
        <div className="details-card_chart">{renderChart()}</div>
        {statistics && (
          <div className="details-card_statistics">
            {statistics.map((stat, index) => (
              <div key={index} className="details-card_statistics_item">
                <p className="details-card_statistics_item_label">
                  {stat.label}
                </p>
                <p className="details-card_statistics_item_value">
                  {stat.value}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChartCard;
