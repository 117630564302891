import { Authenticator } from "@aws-amplify/ui-react";
import components from "components/core/authComponents";
import formFields from "config/cognito-form-fields";
import React, { useEffect } from "react";
import "../../components/core/core.scss";
import "./embeddables.scss";

const EmbeddableAuthenticator = ({ initialState = "signUp" }) => {
  // disable scrolling on the body
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const postRedirectToDashboardMessage = () => {
    window.parent.postMessage("redirect_to_dashboard", "*");
    return <></>;
  };

  return (
    <div className="login-wrapper embedded-signup">
      <div className="login-form flex flex-1 items-center justify-center">
        <Authenticator initialState={initialState} formFields={formFields}>
          {({ signOut, user }) => postRedirectToDashboardMessage()}
        </Authenticator>
      </div>
    </div>
  );
};

export default EmbeddableAuthenticator;
