import hamburgerIcon from "assets/core/hamburger.svg";
import { useEffect, useRef, useState } from "react";
import "./core.scss";

const HamburgerMenu = ({ items, id = null, ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hamburgerEl = useRef();

  const onClickAnywhere = (e) => {
    if (!hamburgerEl.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleCloseMenus = (event) => {
      if (event.detail.id !== id) {
        // If this menu isn't the one that triggered the event, close it
        setIsMenuOpen(false);
      }
    };

    // Add the event listener
    window.addEventListener("closeMenus", handleCloseMenus);

    document.addEventListener("click", onClickAnywhere);

    return () => {
      window.removeEventListener("closeMenus", handleCloseMenus);
      document.removeEventListener("click", onClickAnywhere);
    };
  }, [items]);

  const onItemClick = (item) => {
    setIsMenuOpen(false);
    item.onClick();
  };

  const onMenuToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);

    // Broadcast a custom event that notifies other menus to close
    const closeMenusEvent = new CustomEvent("closeMenus", {
      detail: { id },
    });
    window.dispatchEvent(closeMenusEvent);

    // position the dropdown menu
    // inversely if its in the bottom quarter of screen
    const windowHeight = window.innerHeight;
    const hamburgerMenuRect = hamburgerEl.current.getBoundingClientRect();
    const hamburgerMenuBottom = hamburgerMenuRect.bottom;

    // wait until the dropdown element is rendered
    if (!isMenuOpen) {
      let interval = setInterval(() => {
        let dropdownEl = hamburgerEl.current.querySelector(
          ".hamburger-menu_dropdown",
        );

        if (dropdownEl) {
          if (hamburgerMenuBottom > windowHeight * 0.75) {
            dropdownEl.style.top = `-${items.length * 40}px`;
          } else {
            dropdownEl.style.top = 52;
          }

          clearInterval(interval);
        }
      });
    }
  };

  return (
    <div {...props} ref={hamburgerEl} className="hamburger-menu">
      <div onClick={(e) => onMenuToggle(e)} className="hamburger-menu_toggler">
        <img src={hamburgerIcon} />
      </div>
      {isMenuOpen && (
        <div className="hamburger-menu_dropdown">
          {items.map((item, index) => (
            <div
              key={index}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onItemClick(item);
              }}
              className="hamburger-menu_dropdown_item"
            >
              <img src={item.icon} />
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
