import fireApiRequest from "./core";

var GET_USERS_IN_TEAM_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_users_in_team";
var CREATE_USER_FOR_TEAM_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/create_user_for_team";
var DELETE_USER_FROM_TEAM_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/delete_user_from_team";

if (process.env.REACT_APP_NODE_ENV === "production") {
  GET_USERS_IN_TEAM_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_users_in_team";
  CREATE_USER_FOR_TEAM_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/create_user_for_team";
  DELETE_USER_FROM_TEAM_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/delete_user_from_team";
}

/**
 * Fetch users in team request
 */
export async function getUsersInTeamRequest(teamId) {
  try {
    return await fireApiRequest(GET_USERS_IN_TEAM_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        team_id: teamId,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Request that creates a user for a team
 */
export async function createUserForTeam(teamId, email, firstName, lastName) {
  try {
    return await fireApiRequest(CREATE_USER_FOR_TEAM_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        teamId,
        email,
        firstname: firstName,
        lastname: lastName,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Deletes user from a team request
 */
export async function deleteUserFromTeam(userId) {
  try {
    return await fireApiRequest(DELETE_USER_FROM_TEAM_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}
