import { setCatastroProperty } from "actions/myPropertiesActions";
import { getCatastralByRefDirect } from "api/cma";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { catastroToCmaProperty, searchMapForReferences } from "utils/map";
import searchIcon from "../../assets/search/search_icon.svg";
import { i18n } from "../../i18n/localisation";
import "./myProperties.scss";

const UploadPropertySearchBar = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { selectedPlot, catastroProperty } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (catastroProperty) {
      setSearchValue(catastroProperty.ref);
      searchMapForReferences(catastroProperty.ref, "myProperties");
    }
  }, []);

  useEffect(() => {
    if (selectedPlot && searchValue) {
      const findCatastralByRef = async (ref) => {
        const promise = getCatastralByRefDirect(ref);
        promise.then((data) => {
          const property = catastroToCmaProperty(
            data,
            selectedPlot.refcat,
            selectedPlot.center_y,
            selectedPlot.center_x,
            selectedPlot,
          );

          dispatch(setCatastroProperty(property));
        });
      };

      findCatastralByRef(searchValue);
    }
  }, [selectedPlot]);

  return (
    <div className="upload-property-search-bar_container">
      <div className="upload-property-search-bar_input-content">
        <input
          id="search-input"
          value={searchValue}
          type="text"
          placeholder={i18n("Search Catastral Reference")}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className="upload-property-search-bar_divider"></div>
      <div
        className="upload-property-search-bar_container_search-icon-content"
        onClick={() => {
          searchMapForReferences(searchValue, "myProperties");
        }}
      >
        <img src={searchIcon} alt="search icon" />
      </div>
    </div>
  );
};

export default connect((state) => ({
  user: state.user,
  selectedPlot: state.plots.selectedPlot,
  catastroProperty: state.myProperties.catastroProperty,
}))(UploadPropertySearchBar);
