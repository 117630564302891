import fireApiRequest from "./core";

var FETCH_USER_DATA =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_user_data";
var SAVE_USER_DATA =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/save_user_data";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_USER_DATA =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_user_data";
  SAVE_USER_DATA =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/save_user_data";
}

/**
 * Fetch user data request
 */
export async function fetchUserDataRequest() {
  try {
    return await fireApiRequest(FETCH_USER_DATA, { method: "POST" });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Save user data request
 */
export async function saveUserDataRequest(data) {
  try {
    await fireApiRequest(SAVE_USER_DATA, {
      method: "POST",
      body: JSON.stringify(data),
    });
  } catch (e) {
    console.log(e);
  }
}
