import { DrawingManager, GoogleMap } from "@react-google-maps/api";
import { setCanShowBoundsToolbarItem } from "actions/mapActions";
import withRouter from "components/core/withRouter";
import {
  ZOOM_LEVEL_TO_HIDE_MUNICIPALITIES,
  ZOOM_LEVEL_TO_SHOW_PLOTS,
} from "config/constants";
import {
  mapConfig,
  mapStyles,
  polygonColours,
  roadMapStyles,
} from "config/map";
import { useState } from "react";
import { connect } from "react-redux";
import Supercluster from "supercluster";
import {
  clearAllButSelectedPlot,
  fetchPlotsByMapBounds,
  formatPropertiesToGeoJsonPoints,
  getGlobalMapInstance,
  shouldDrawGeographicBounds,
} from "utils/map";
import PropertyMarker from "./PropertyMarker";

/**
 * Wrapper to wrap the google maps library
 * so we prevent rerenders of dependant components within
 * the property map
 */
const GoogleMapWrapper = ({
  setMap,
  map,
  handlePolygonComplete,
  setDrawingManager,
  setSelectedProperty,
  selectedProperty,
  onMapClick,
  filteredProperties,
  page,
  drawingMode,
  plotMode,
  handleMunicipalityClick,
  ...props
}) => {
  const [bounds, setBounds] = useState([0, 0, 0, 0]);
  const [zoom, setZoom] = useState(0);
  const device = window.innerWidth < 1100 ? "mobile" : "desktop";

  // create clusters for dense property segments
  const sc = new Supercluster({ radius: 190, maxZoom: 20 });
  sc.load(formatPropertiesToGeoJsonPoints(filteredProperties));
  const clusters = sc.getClusters(bounds, zoom);

  // when the viewport on the map changes this function is fired
  const onMapBoundsChange = () => {
    if (!map) {
      return;
    }

    const _bounds = map.getBounds().toJSON();
    setBounds([_bounds.west, _bounds.south, _bounds.east, _bounds.north]);
  };

  // if plot mode is enabled then we need to draw
  // the plots of buildings if we are at a certain zoom level
  const onShouldDrawPlots = () => {
    let map = getGlobalMapInstance();

    if (map.zoom > ZOOM_LEVEL_TO_SHOW_PLOTS && plotMode) {
      fetchPlotsByMapBounds();
    } else if (plotMode) {
      clearAllButSelectedPlot();
    }
  };

  const onDragEnd = () => {
    onShouldDrawPlots();
    shouldDrawGeographicBounds(handleMunicipalityClick);
  };

  // when the map zoom levek changes this is called
  const onZoomLevelChange = () => {
    if (!map) {
      return;
    }

    setZoom(map.zoom);
    onShouldDrawPlots();
    shouldDrawGeographicBounds(handleMunicipalityClick);

    if (map.zoom <= ZOOM_LEVEL_TO_HIDE_MUNICIPALITIES) {
      props.dispatch(setCanShowBoundsToolbarItem(true));
    } else {
      props.dispatch(setCanShowBoundsToolbarItem(false));
    }
  };

  // when a map cluster is clicked this is fired
  const onClusterClick = (cluster) => {
    const expansionZoom = Math.min(sc.getClusterExpansionZoom(cluster.id), 20);
    map.setZoom(expansionZoom);
    map.panTo({
      lat: cluster.geometry.coordinates[1],
      lng: cluster.geometry.coordinates[0],
    });
  };

  // renders property pins/clusters
  const renderMarker = (cluster, map, index) => {
    const { property } = cluster.properties;
    let latitude = 0;
    let longitude = 0;
    let clusterProperties = [];

    if (cluster.properties.cluster) {
      latitude = cluster.geometry.coordinates[1];
      longitude = cluster.geometry.coordinates[0];
    } else {
      latitude = parseFloat(property.latitude);
      longitude = parseFloat(property.longitude);
    }

    // collect all a cluster's properties for the cluster marker
    if (cluster.properties.cluster) {
      const clusterPoints = sc.getLeaves(
        cluster.properties.cluster_id,
        Infinity,
      );
      clusterPoints.forEach((point) => {
        clusterProperties.push(point.properties.property);
      });
    }

    // selected states for property markers
    let isSelected = false;
    if (property && selectedProperty === property.id) {
      isSelected = true;
    }

    return (
      <PropertyMarker
        page={page}
        onClusterClick={onClusterClick}
        cluster={cluster}
        key={index}
        map={map}
        position={{ lat: latitude, lng: longitude }}
        property={property}
        clusterProperties={clusterProperties}
        isSelected={isSelected}
        selectMarker={() => setSelectedProperty(property ? property.id : null)}
      />
    );
  };

  const renderHoverInfo = () => {
    const { hoveredArea, hideBounds } = props.mapReducer;

    if (!hoveredArea || hideBounds) {
      return null;
    }

    return (
      <div id="hoverInfo">
        <span>{hoveredArea.name}</span>
      </div>
    );
  };

  return (
    <GoogleMap
      mapContainerStyle={mapConfig[page].style[device]}
      center={mapConfig[page].center[device]}
      zoom={mapConfig[page].zoom[device]}
      onIdle={onDragEnd}
      onBoundsChanged={onMapBoundsChange}
      onZoomChanged={onZoomLevelChange}
      onLoad={(map) => {
        // force satellite view for cma mode
        if (
          props.user.userData.preferences.mapType !== "roadmap" ||
          page === "cma" ||
          page === "myProperties"
        ) {
          map.setMapTypeId("hybrid");
        }

        setMap(map);
        map.addListener("click", onMapClick);
        window.googleMapsInstance = map;
        shouldDrawGeographicBounds();

        // if url params contain a property id then dont center map
        // on user location
        // also dont center map on user location if loading a search
        const urlParams = new URLSearchParams(window.location.search);
        const propertyId = urlParams.get("id");

        // MARK: - Commented out to prevent annoying geolocation request
        // if (!propertyId && !props.urlParams.collectionId) {
        //   setDefaultCenter(map, cmaMode);
        // }
      }}
      tilt={page === "cma" || page === "myProperties" ? 0 : null}
      options={{
        styles:
          !props.user.userData.preferences.mapType ||
          props.user.userData.preferences.mapType === "hybrid" ||
          page === "cma" ||
          page === "myProperties"
            ? mapStyles
            : roadMapStyles,
        disableDefaultUI: true,
        zoomControl: false,
        gestureHandling: "greedy",
      }}
    >
      <DrawingManager
        drawingMode={drawingMode}
        onPolygonComplete={(polygon) =>
          handlePolygonComplete(polygon, true, true)
        }
        onLoad={(drawingManager) => {
          window.googleMapsDrawingManager = drawingManager;
          setDrawingManager(drawingManager);
        }}
        options={{
          map: map,
          drawingControl: false,
          polygonOptions: {
            draggable: false,
            editable: true,
            fillColor: polygonColours.primary,
            strokeColor: polygonColours.primary,
            fillOpacity: polygonColours.fillOpacity,
            strokeWeight: 4,
          },
        }}
      />
      {clusters.map((cluster, index) => renderMarker(cluster, map, index))}
      {renderHoverInfo()}
    </GoogleMap>
  );
};

export default connect((state) => ({
  user: state.user,
  mapReducer: state.map,
}))(withRouter(GoogleMapWrapper));
