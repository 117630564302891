import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { useContext, useEffect, useState } from "react";
import { CollectionsContext } from "../../../context/CollectionsContext";
import Button from "../../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";

const CollectionCreateModal = ({ collections, onSubmit, onCancel }) => {
  const { isCreateModalOpen, closeCreateModal } =
    useContext(CollectionsContext);
  const [collectionName, setCollectionName] = useState("");
  const [isNameError, setIsNameError] = useState(false);

  useEffect(() => {
    if (isNameError) {
      document.querySelector("#create-collection-input").focus();
      return;
    }
  }, [isNameError]);

  const onKeyDown = (e) => {
    if (e.key === "Enter" && collectionName.length > 0) {
      handleOnSubmit();
      window.removeEventListener("keydown", onKeyDown);
      e.preventDefault();
      return;
    }

    if (e.key === "Escape") {
      setCollectionName("");
      closeCreateModal();
      window.removeEventListener("keydown", onKeyDown);
      return;
    }
  };

  const handleOnSubmit = () => {
    if (
      collections.some(
        (collection) =>
          collection.title.toLowerCase().trim() ===
          collectionName.toLowerCase().trim(),
      )
    ) {
      setIsNameError(true);
      return;
    }
    onSubmit(collectionName);
    setCollectionName("");

    sendAnalyticsEvent("Create Collection", {
      type: "save new collection",
    });
  };

  return (
    <Dialog
      open={isCreateModalOpen}
      onOpenChange={(open) => {
        setCollectionName("");
        closeCreateModal();
        setIsNameError(false);
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Create collection")}</DialogTitle>
        </DialogHeader>
        <div className="px-4">
          <p className="mb-2 text-sm font-medium text-gray-700">
            {i18n("Name")}
          </p>
          <div
            className={`w-full rounded-[12px] p-[1px] ${isNameError && "border-2 border-red-500"}`}
          >
            <input
              id="create-collection-input"
              type="text"
              className="w-full rounded-lg border border-gray-300 p-2"
              value={collectionName}
              onChange={(e) => {
                setCollectionName(e.target.value);
                setIsNameError(false);
              }}
              maxLength={50}
              onKeyDown={(e) => onKeyDown(e)}
            />
          </div>
          <div className="mt-2 flex w-full justify-between">
            <p className="text-sm text-red-500">
              {isNameError &&
                i18n(
                  "A collection with this name already exists. Enter a new name.",
                )}
            </p>
            <p className="text-left text-sm text-gray-500">
              {collectionName.length}/50 {i18n("characters")}
            </p>
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="text" onClick={() => closeCreateModal()}>
              {i18n("Close")}
            </Button>
            <Button
              variant={collectionName.length > 0 ? "primary" : "secondary"}
              disabled={collectionName.length === 0 || isNameError}
              onClick={() => handleOnSubmit()}
              className="ml-auto"
            >
              {i18n("Create")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CollectionCreateModal;
