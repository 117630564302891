import React from "react";
import "./Checkbox.scss";
import "./Input.scss";

const uncheckedIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.41669"
      y="2.41663"
      width="15.1667"
      height="15.1667"
      rx="4.25"
      stroke="#222222"
      strokeWidth="1.5"
    />
  </svg>
);

const checkedIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.41669"
      y="2.41675"
      width="15.1667"
      height="15.1667"
      rx="4.25"
      fill="#222222"
      stroke="#222222"
      strokeWidth="1.5"
    />
    <path
      d="M5.83331 10.8334L8.33331 13.3334L14.5833 7.08337"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Checkbox = ({ label, checked }, props) => {
  return (
    <div className="checkbox" {...props}>
      {checked ? checkedIcon : uncheckedIcon}
      <label>{label}</label>
    </div>
  );
};

export default Checkbox;
