import { i18n } from "i18n/localisation";
import { useContext, useEffect, useState } from "react";
import { CollectionsContext } from "../../../context/CollectionsContext";
import Button from "../../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";

const EditObjectTitleModal = ({ onSubmit, object, objectTitle }) => {
  const { editingObjectTitle, closeEditObjectTitleModal } =
    useContext(CollectionsContext);

  const [newObjectTitle, setNewObjectTitle] = useState("");

  useEffect(() => {
    setNewObjectTitle(objectTitle);
  }, [objectTitle, object]);

  const onKeyDown = (e) => {
    if (e.key === "Enter" && newObjectTitle.length > 0) {
      handleOnSubmit();
      window.removeEventListener("keydown", onKeyDown);
      e.preventDefault();
      return;
    }

    if (e.key === "Escape") {
      closeEditObjectTitleModal();
      window.removeEventListener("keydown", onKeyDown);
      return;
    }
  };

  const handleOnSubmit = () => {
    onSubmit(object, newObjectTitle);
    closeEditObjectTitleModal();
  };

  return (
    <Dialog
      open={editingObjectTitle}
      onOpenChange={(open) => {
        closeEditObjectTitleModal();
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Edit title")}</DialogTitle>
        </DialogHeader>
        <div className="px-4">
          <p className="mb-2 text-sm font-medium text-gray-700">
            {i18n("Title")}
          </p>
          <input
            type="text"
            className="w-full rounded-lg border border-gray-300 p-2"
            value={newObjectTitle}
            onChange={(e) => setNewObjectTitle(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
            maxLength={50}
          />
          <p className="mt-2 text-right text-sm text-gray-500">
            {newObjectTitle.length}/50 {i18n("characters")}
          </p>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="text" onClick={() => closeEditObjectTitleModal()}>
              {i18n("Close")}
            </Button>
            <Button
              variant="primary"
              onClick={() => handleOnSubmit()}
              className="ml-auto"
              disabled={newObjectTitle.length === 0}
            >
              {i18n("Save")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditObjectTitleModal;
