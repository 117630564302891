import { createReducer } from "@reduxjs/toolkit";
import {
  setCanDrawPlots,
  setSelectedPlot,
  setShowPlots,
} from "actions/plotsActions";
import { hidePlots, showPlots } from "utils/map";

const initialState = {
  selectedPlot: null,
  canDrawPlots: true,
  showPlots: true,
};

const plotReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSelectedPlot, (state, action) => {
      state.selectedPlot = action.payload;
    })
    .addCase(setCanDrawPlots, (state, action) => {
      state.canDrawPlots = action.payload;
    })
    .addCase(setShowPlots, (state, action) => {
      state.showPlots = action.payload;

      if (action.payload) {
        showPlots();
      } else {
        hidePlots(state.selectedPlot);
      }
    });
});

export default plotReducer;
