import React from "react";
import { connect } from "react-redux";
import "./notifications.scss";
import closeIcon from "assets/core/close.svg";
import {
  markAllNotificationsAsRead,
  setNotificationsOpen,
} from "actions/notificationsActions";
import { i18n } from "i18n/localisation";
import blueCheckIcon from "assets/core/blue_check.svg";
import { getUnreadNotifications } from "utils/helpers";
import NotificationItem from "./NotificationItem";

const NotificationsPanel = (props) => {
  const [activeControl, setActiveControl] = React.useState("unread");
  let unreadNotifications = getUnreadNotifications();
  let notifications = [...props.notifications];

  if (activeControl == "unread") {
    notifications = unreadNotifications;
  }

  if (!props.notificationsOpen) {
    return null;
  }

  const closePanel = () => {
    props.dispatch(setNotificationsOpen(false));
  };

  const onActiveControlChange = (control) => {
    setActiveControl(control);
  };

  const markAllAsRead = () => {
    props.dispatch(markAllNotificationsAsRead());
  };

  return (
    <>
      <div onClick={() => closePanel()} className="notifications-overlay"></div>
      <div className="notifications-panel">
        <div className="notifications-panel_header">
          <h3>{i18n("Notifications")}</h3>
          {unreadNotifications.length > 0 && (
            <div
              className={
                "notifications-panel_header_badge" +
                (unreadNotifications.length > 99 ? " overflow" : "")
              }
            >
              <span>
                {unreadNotifications.length > 99
                  ? "99+"
                  : unreadNotifications.length}
              </span>
            </div>
          )}
          <div className="notifications-panel_spacer"></div>
          <img onClick={() => closePanel()} src={closeIcon} />
        </div>
        <div className="notifications-panel_controls">
          <div
            className={
              "notifications-panel_controls_item" +
              (activeControl == "unread" ? " active" : "")
            }
            onClick={() => onActiveControlChange("unread")}
          >
            <span>{i18n("New")}</span>
          </div>
          <div
            className={
              "notifications-panel_controls_item" +
              (activeControl == "all" ? " active" : "")
            }
            onClick={() => onActiveControlChange("all")}
          >
            <span>{i18n("Inbox")}</span>
          </div>
          <div className="notifications-panel_spacer"></div>
          <div
            onClick={() => markAllAsRead()}
            className="notifications-panel_controls_mark-all"
          >
            <span>{i18n("Mark all as seen")}</span>
            <img src={blueCheckIcon} />
          </div>
        </div>
        <div className="notifications-panel_body">
          {notifications.length == 0 ? (
            <div className="notifications-panel_body_empty">
              <span>{i18n("No notifications")}</span>
            </div>
          ) : (
            <div className="notifications-panel_body_scrollplane">
              {notifications.map((notification, index) => (
                <NotificationItem key={index} notification={notification} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  notifications: state.notifications.notifications,
  notificationsOpen: state.notifications.notificationsOpen,
}))(NotificationsPanel);
