import fireApiRequest from "./core";

var FETCH_COLLECTIONS_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_collections";
var SAVE_COLLECTION_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/save_collection";
var DELETE_COLLECTION_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/delete_collection";
var TOGGLE_NOTIFICATIONS_FOR_SEARCH_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/toggle_notifications_for_search";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_COLLECTIONS_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_collections";
  SAVE_COLLECTION_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/save_collection";
  DELETE_COLLECTION_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/delete_collection";
  TOGGLE_NOTIFICATIONS_FOR_SEARCH_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/toggle_notifications_for_search";
}

export async function fetchCollectionsRequest() {
  return await fireApiRequest(FETCH_COLLECTIONS_ENDPOINT, { method: "POST" });
}

export async function saveCollectionRequest(item) {
  return await fireApiRequest(SAVE_COLLECTION_ENDPOINT, {
    method: "POST",
    body: JSON.stringify(item),
  });
}

export async function deleteCollectionRequest(id) {
  return await fireApiRequest(DELETE_COLLECTION_ENDPOINT, {
    method: "DELETE",
    body: JSON.stringify({ id }),
  });
}

export async function toggleNotificationsForSearchRequest(
  searchId,
  collectionId,
  enabled,
) {
  return await fireApiRequest(TOGGLE_NOTIFICATIONS_FOR_SEARCH_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      search_id: searchId,
      collection_id: collectionId,
      notifications_enabled: enabled ? 1 : 0,
    }),
  });
}
