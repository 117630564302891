import Button from "../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { connect } from "react-redux";
import { i18n } from "i18n/localisation";
import { useState } from "react";
import PrimaryInput from "components/core/PrimaryInput";
import { Auth } from "aws-amplify";
import { toast } from "sonner";

const ChangePasswordModal = ({
  closeModal,
  open,
  onHandleChangePasswordComplete,
}) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const close = () => {
    closeModal();
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const handleChangePassword = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, currentPassword, newPassword);
      toast.success(i18n("Password changed successfully"));
      close();
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");

      if (onHandleChangePasswordComplete) {
        onHandleChangePasswordComplete();
      }
    } catch (error) {
      toast.error(i18n("Incorrect password."));
      console.error("Error changing password:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        close();
      }}
    >
      <DialogContent className="change-password-modal sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Change password")}</DialogTitle>
        </DialogHeader>
        <div className="change-password-modal_content p-4 text-sm">
          <p>{i18n("Current password")}</p>
          <PrimaryInput
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <p>{i18n("New password")}</p>
          <PrimaryInput
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <p>{i18n("Confirm new password")}</p>
          <PrimaryInput
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button
              className={
                "change-password-btn" +
                (newPassword !== confirmNewPassword ||
                newPassword === "" ||
                newPassword.length < 8
                  ? " disabled"
                  : "")
              }
              variant="primary"
              onClick={handleChangePassword}
            >
              {i18n("Update password")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default connect()(ChangePasswordModal);
