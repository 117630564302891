import largeTexts from "./largetexts.json";
import cmaEs from "./es/cma.json";
import coreEs from "./es/core.json";
import { SUPPORTED_LANGUAGES } from "config/constants";
import store from "store";

const spanishLoc = {
  ...cmaEs,
  ...coreEs,
};

const englishLargeTextLoc = {
  ...largeTexts["en"],
};

const spanishLargeTextLoc = {
  ...largeTexts["es"],
};

export function getLocalisation(key, locale) {
  if (locale === "es") {
    let loc = spanishLoc[key];

    if (loc) {
      return loc;
    }

    return key;
  }

  return key;
}

export function getLargeTextLocalisation(key, locale) {
  if (locale === "es") {
    return spanishLargeTextLoc[key];
  }

  return englishLargeTextLoc[key];
}

// gets either user set language or default browser language
export function getLang() {
  let preferences = store.getState().user.userData.preferences;

  if (preferences && preferences.lang) {
    return preferences.lang;
  }

  return getDefaultLocalisation();
}

// main entrypoint for default localisation
export function i18n(key) {
  return getLocalisation(key, getLang().code);
}

export function getDefaultLocalisation() {
  const browserLanguage = navigator.language;
  if (browserLanguage.includes("es")) {
    return SUPPORTED_LANGUAGES[0];
  }

  return SUPPORTED_LANGUAGES[1];
}
