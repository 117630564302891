import { i18n } from "i18n/localisation";
import Button from "../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import PrimaryInput from "components/core/PrimaryInput";
import { useState } from "react";
import { createUserForTeam } from "api/team";
import { toast } from "sonner";
import { connect } from "react-redux";
import { fetchUsersForTeam } from "actions/teamActions";

const InviteMemberModal = ({ closeModal, open, teamId, dispatch }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const inviteMember = async () => {
    closeModal();

    let promise = new Promise(async (resolve, reject) => {
      try {
        let data = await createUserForTeam(teamId, email, firstName, lastName);

        if (data.success) {
          toast.success(i18n("User added successfully"));
          dispatch(fetchUsersForTeam(teamId));
        } else {
          toast.error(i18n("Failed to add user"));
        }

        resolve();
      } catch (e) {
        toast.error(i18n("Failed to add user"));
        reject();
      }
    });

    toast.promise(promise, {
      loading: i18n("Adding user..."),
      duration: 1000,
    });

    setEmail("");
    setFirstName("");
    setLastName("");
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        closeModal();
      }}
    >
      <DialogContent className="invite-member-modal sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Invite team member")}</DialogTitle>
        </DialogHeader>
        <div className="invite-member-modal_content p-4">
          <p>{i18n("Email")}</p>
          <PrimaryInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p>{i18n("First name")}</p>
          <PrimaryInput
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <p>{i18n("Last name")}</p>
          <PrimaryInput
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="text" onClick={() => closeModal()}>
              {i18n("Cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={() => inviteMember()}
              className="ml-auto"
            >
              {i18n("Add member")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default connect()(InviteMemberModal);
