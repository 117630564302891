import { saveCollection } from "actions/collectionActions";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { CollectionsContext } from "../../context/CollectionsContext";
import EmptyTileContainer from "../core/EmptyTileContainer";
import Dashboard from "../Dashboard";
import "./collections.scss";
import { CollectionTile } from "./CollectionTile";
import { sortByCreatedDate } from "./utils";

const CollectionsPage = function (props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openCreateModal, openDeleteModal, setEditingObjectTitle } =
    useContext(CollectionsContext);

  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const sortedCollections = [...props.collections.collections]
      .sort(sortByCreatedDate)
      .reverse();
    setCollections([...sortedCollections].sort(sortByCreatedDate).reverse());
  }, [props.collections]);

  const onDeleteCollection = (collection) => {
    openDeleteModal(collection);
  };

  const onDuplicateCollection = (collection) => {
    let newCollection = { ...collection };
    delete newCollection.id;
    dispatch(saveCollection(newCollection));
    toast(i18n("Collection has been duplicated"), { duration: 2000 });
  };

  const onEditTitle = (collection, newTitle) => {
    setEditingObjectTitle(collection);
  };

  const onClickEmptyCollection = () => {
    openCreateModal();
    sendAnalyticsEvent("Create Collection", {
      type: "collections page",
    });
  };

  return (
    <Dashboard>
      <Helmet>
        <title>{i18n("Collections")}</title>
      </Helmet>
      <div className="pr-18 collections-page flex min-h-screen w-full justify-center bg-gray-100 p-12 py-16 md:pl-36">
        <div className="w-full max-w-[1140px] ">
          <h2 className="mb-8 text-3xl font-bold">{i18n("Collections")}</h2>

          {collections.length === 0 && (
            <h3 className="mb-6 text-2xl font-semibold">
              {i18n("Create your first collection")}
            </h3>
          )}

          <div className="mb-8 max-w-prose text-base">
            <p className="mb-2">
              {i18n(
                "As you search, you can click on the heart icon to save a property or ‘Save search’ to save your search query to a collection.",
              )}
            </p>
            <p>
              {i18n(
                "You can organise your collections by client or folder name.",
              )}
            </p>
          </div>

          <div className="mb-4 flex max-w-screen-xl flex-wrap">
            {collections.length > 0 &&
              collections.map((collection) => (
                <CollectionTile
                  key={collection.id}
                  showHamburger
                  collection={collection}
                  onEditTitle={onEditTitle}
                  onDeleteCollection={onDeleteCollection}
                  onDuplicateCollection={onDuplicateCollection}
                  handleOnClick={() =>
                    navigate(`/collections/${collection.id}`)
                  }
                  className="mb-2 mr-4"
                />
              ))}
            <EmptyTileContainer
              label={i18n("Create collection")}
              onClick={() => onClickEmptyCollection()}
            />
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default connect((state) => {
  return {
    collections: state.collections,
  };
})(CollectionsPage);
