import { fetchSubscriptionRequest } from "api/subscriptions";
import { Auth } from "aws-amplify";
import mixpanel from "mixpanel-browser";
import store from "../store";

window.currentUserId = null;

// initialise the user tracking with mixpanel
export async function initialiseUserTracking() {
  // set the current user data with mixpanel
  // for analytics
  const currentUser = await Auth.currentAuthenticatedUser();
  const userSub = currentUser.attributes.sub;
  const userEmail = currentUser.attributes.email;

  if (window.currentUserId !== userSub) {
    window.currentUserId = userSub;
    mixpanel.identify(window.currentUserId);
    let subscription = null;

    try {
      subscription = await fetchSubscriptionRequest();
    } catch (e) {}

    let avatar = null;
    let picture = store.getState().user.userData?.agencyDetails?.picture;
    if (picture) {
      avatar = picture.replace(/\s/g, "%20"); // replace white spaces with %20 because mixpanel doesn't recognise white spaces
    }

    mixpanel.people.set({
      $email: userEmail,
      $first_name: store.getState().user.userData.personalDetails.firstName,
      $last_name: store.getState().user.userData.personalDetails.lastName,
      plan: subscription ? subscription.plan : "free trial",
      $avatar: avatar,
    });
  }
}

// sends an event to mixpanel
export function sendAnalyticsEvent(eventName, eventProperties = {}) {
  Auth.currentAuthenticatedUser().then((user) => {
    mixpanel.track(eventName, {
      ...eventProperties,
      email: user.attributes.email,
    });
  });
}
