import { getHeaders } from "./headers";

var requestTableTimestamps = {};

export async function fireApiRequest(path, requestObject) {
  let time = new Date().getTime();

  // make sure we don't multiply requests
  // if (requestTableTimestamps[path] && (requestTableTimestamps[path] >= (time - 100))) {
  //     console.log('Too many requests at one time, skipping ', path);
  //     return;
  // }

  requestTableTimestamps[path] = time;
  let headers = await getHeaders();
  let response = await fetch(path, {
    ...requestObject,
    headers,
    redirect: "manual",
  });

  if (response.status === 403) {
    try {
      const data = await response.json();
      if (data.redirect) {
        // Handle the redirect within the current origin
        window.location.href = window.location.origin + data.redirect;
        return; // Stop further execution after redirect
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw new Error("Forbidden - Failed to process response");
    }
  }

  if (response.ok) {
    try {
      return await response.json();
    } catch {
      return response;
    }
  } else {
    // API ERROR
    throw new Error(response.statusText);
  }
}

export default fireApiRequest;
