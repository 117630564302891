import fireApiRequest from "./core";

var SEND_USER_REPORT_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/send_report";

if (process.env.REACT_APP_NODE_ENV === "production") {
  SEND_USER_REPORT_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/send_report";
}

export async function sendUserReport({
  objectId,
  reason,
  comments,
  duplicateURLs,
}) {
  return await fireApiRequest(SEND_USER_REPORT_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      type: "property",
      object_id: objectId,
      reason,
      comments,
      metadata: { duplicate_urls: duplicateURLs },
    }),
  });
}
