import { useState } from "react";
import Checkbox from "../input/Checkbox";
import { i18n } from "i18n/localisation";
import { connect } from "react-redux";
import { hidePlots, showPlots as _showPlots } from "utils/map";
import { setShowPlots } from "actions/plotsActions";

const ShowPlotsToggle = ({ plots, dispatch }) => {
  const onShowPlotsChange = () => {
    dispatch(setShowPlots(!plots.showPlots));
  };

  // only show plots if we are on the valuation page
  if (
    !plots.canDrawPlots ||
    !window.location.pathname.startsWith("/valuation")
  ) {
    return null;
  }

  return (
    <div className="map-toolbar show-plots-map-btn">
      <div onClick={onShowPlotsChange} className="map-toolbar_item">
        <Checkbox checked={plots.showPlots} />
        <span>{i18n("Show plots")}</span>
      </div>
    </div>
  );
};

export default connect((state) => ({
  plots: state.plots,
}))(ShowPlotsToggle);
