import React from "react";
import CmaForm from "./CmaForm";

const CmaPanel = (props) => {
  return (
    <div id="cma-panel" className="cma-panel">
      <CmaForm {...props} />
    </div>
  );
};

export default React.memo(CmaPanel);
