import { setCmaProperty } from "actions/cmaActions";
import {
  setFilters,
  setInvisibleFilters,
  setLastAppliedFilters,
  setSort,
} from "actions/filterActions";
import {
  setAreaSelectionMode,
  setAvailableAreas,
  setCanDrawGeographicPolygons,
  setCanShowBoundsToolbarItem,
  setHideBounds,
  setHideMapTools,
  setSelectedAreas,
} from "actions/mapActions";
import { setInitialDataLoaded } from "actions/miscActions";
import { setNotificationsOpen } from "actions/notificationsActions";
import { setCanDrawPlots, setSelectedPlot } from "actions/plotsActions";
import { setSearchContext, setSearchObject } from "actions/saveSearchActions";
import { fetchSubscriptionRequest } from "api/subscriptions";
import { fetchUserDataRequest } from "api/user";
import { downloadMapData } from "db/mapStore";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { shouldDrawGeographicBounds } from "utils/map";
import { fetchCollections } from "../../actions/collectionActions";
import { fetchMapSearchIndexes } from "../../actions/geoActions";
import {
  incrementNumberOfPolygonsFetched,
  setDrawnPolygons,
} from "../../actions/polygonActions";
import {
  setFilteredProperties,
  setProperties,
} from "../../actions/propertiesActions";
import { fetchSubscription, fetchUserData } from "../../actions/userActions";
import { SORT_OPTIONS } from "../../lib/filter/sort";
import { downloadProvinceData } from "../../utils/provinces";

var globalProviderInitialised = false;

const GlobalProvider = (props) => {
  const location = useLocation();

  // prevent propagating initial fetches
  useEffect(() => {
    if (globalProviderInitialised) return;
    globalProviderInitialised = true;

    // Dispatch actions to fetch necessary data
    props.dispatch(fetchUserData());
    props.dispatch(fetchSubscription());
    props.dispatch(fetchMapSearchIndexes());
    props.dispatch(fetchCollections());

    // Wait for user data and subscription data to be fetched
    Promise.all([fetchUserDataRequest(), fetchSubscriptionRequest()])
      .then(() => {
        props.dispatch(setInitialDataLoaded(true));
        return downloadMapData("es");
      })
      .then(() => {
        shouldDrawGeographicBounds();
      })
      .catch((error) => {
        console.error("Error initializing global provider:", error);
      });

    downloadProvinceData();
  }, [props]);

  useEffect(() => {
    // Clear properties and filters on route changes
    props.dispatch(setProperties([]));
    props.dispatch(setFilteredProperties([]));
    props.dispatch(setCmaProperty(null));
    props.dispatch(setDrawnPolygons([]));
    props.dispatch(setFilters([]));
    props.dispatch(setInvisibleFilters([]));
    props.dispatch(setLastAppliedFilters([]));
    props.dispatch(setSort(SORT_OPTIONS[0]));
    props.dispatch(setCanDrawPlots(true));
    props.dispatch(setSelectedPlot(null));
    props.dispatch(fetchUserData());
    props.dispatch(setSearchContext(null));
    props.dispatch(setSearchObject(null));
    props.dispatch(setNotificationsOpen(false));
    props.dispatch(incrementNumberOfPolygonsFetched(0));

    props.dispatch(
      setHideMapTools(window.location.pathname.includes("valuation")),
    );
    props.dispatch(setAreaSelectionMode(false));
    props.dispatch(setSelectedAreas([]));
    props.dispatch(setAvailableAreas([]));

    if (window.location.pathname === "/") {
      props.dispatch(setCanDrawGeographicPolygons(true));
      props.dispatch(setHideBounds(false));
      props.dispatch(setCanShowBoundsToolbarItem(true));
    }

    // Clear drawn polygons
    window.drawnPolygons.forEach((p) => {
      p.setMap(null);
    });
    window.drawnPolygons = [];
    window.drawnPlots = [];
    window.drawnGeographicPolygons = [];
    window.drawnHoverDistricts = [];
    window.drawnSelectedDistricts = [];
  }, [location]);

  return <div>{props.children}</div>;
};

export default connect()(GlobalProvider);
