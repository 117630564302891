import { useContext, useEffect, useState } from "react";
import { CollectionsContext } from "../../../context/CollectionsContext";
import Button from "../../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { i18n } from "i18n/localisation";

const initEmptyMetaData = {
  area: "",
  budget: "",
  bedrooms: "",
  bathrooms: "",
  notes: "",
};

const CollectionMetaModal = ({ onSubmit, onCancel }) => {
  const { isMetaModalOpen, closeMetaModal } = useContext(CollectionsContext);

  const [metaData, setMetaData] = useState(() => ({ ...initEmptyMetaData }));

  useEffect(() => {
    const collection = isMetaModalOpen;
    if (isMetaModalOpen) {
      if (Object.keys(collection.metadata).length === 0) {
        setMetaData({ ...initEmptyMetaData });
      } else {
        setMetaData({
          area: collection.metadata.area ?? "",
          budget: collection.metadata.budget ?? "",
          bedrooms: collection.metadata.bedrooms ?? "",
          bathrooms: collection.metadata.bathrooms ?? "",
          notes: collection.metadata.notes ?? "",
        });
      }
    }
  }, [isMetaModalOpen]);

  const handleOnSubmit = () => {
    onSubmit(isMetaModalOpen, metaData);
  };

  return (
    <Dialog
      open={isMetaModalOpen}
      onOpenChange={(open) => {
        closeMetaModal();
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Add notes")}</DialogTitle>
        </DialogHeader>
        <div className="px-4">
          <p className="text-md mb-6 text-gray-500">
            {i18n(
              "Add your client’s requirements below for you to refer back to later.",
            )}
          </p>
          <div className="-mx-2 flex">
            <div className="grow p-2">
              <p className="mb-2 text-sm font-medium text-gray-700">
                {i18n("Area")}
              </p>
              <input
                type="text"
                className="w-full rounded-lg border border-gray-300 p-2"
                value={metaData.area}
                onChange={(e) =>
                  setMetaData({ ...metaData, area: e.target.value })
                }
              />
            </div>
            <div className="grow p-2">
              <p className="mb-2 text-sm font-medium text-gray-700">
                {i18n("Budget")}
              </p>
              <input
                type="text"
                className="w-full rounded-lg border border-gray-300 p-2"
                value={metaData.budget}
                onChange={(e) =>
                  setMetaData({ ...metaData, budget: e.target.value })
                }
              />
            </div>
          </div>
          <div className="-mx-2 flex">
            <div className="grow p-2">
              <p className="mb-2 text-sm font-medium text-gray-700">
                {i18n("Bedrooms")}
              </p>
              <input
                type="text"
                className="w-full rounded-lg border border-gray-300 p-2"
                value={metaData.bedrooms}
                onChange={(e) =>
                  setMetaData({ ...metaData, bedrooms: e.target.value })
                }
              />
            </div>
            <div className="grow p-2">
              <p className="mb-2 text-sm font-medium text-gray-700">
                {i18n("Bathrooms")}
              </p>
              <input
                type="text"
                className="w-full rounded-lg border border-gray-300 p-2"
                value={metaData.bathrooms}
                onChange={(e) =>
                  setMetaData({ ...metaData, bathrooms: e.target.value })
                }
              />
            </div>
          </div>
          <div className="grow">
            <p className="mb-2 text-sm font-medium text-gray-700">
              {i18n("Notes")}
            </p>
            <textarea
              className="w-full rounded-lg border border-gray-300 p-2"
              value={metaData.notes}
              rows={2}
              onChange={(e) =>
                setMetaData({ ...metaData, notes: e.target.value })
              }
            />
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button onClick={() => handleOnSubmit()} className="ml-auto">
              {i18n("Done")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CollectionMetaModal;
