import { i18n } from "i18n/localisation";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Dashboard from "../Dashboard";
import SettingsTabs from "./SettingsTabs";

function SettingsPage(props) {
  return (
    <Dashboard>
      <Helmet>
        <title>{i18n("Account Settings")}</title>
      </Helmet>
      <div className="md:pr-18 settings-page flex min-h-screen w-full justify-center bg-gray-100 px-4 md:py-16 md:pl-36">
        <div className="mt-16 w-full max-w-[1140px] pt-8 md:mt-0 md:pt-8">
          <h2 className="mb-0 text-xl font-bold md:mb-8 md:text-3xl">
            {i18n("Account")}
          </h2>
          <div className="mb-8 max-w-prose text-base">
            <p className="mb-2">
              {i18n("Manage your account settings and contact details.")}
            </p>
          </div>
          <SettingsTabs />
        </div>
      </div>
    </Dashboard>
  );
}

export default connect((state) => ({
  lang: state.user.userData.preferences.lang,
}))(SettingsPage);
