import React, { useState } from "react";
import Button from "components/ui/Button/Button";
import PrimaryInput from "components/core/PrimaryInput";
import { saveUserData } from "actions/userActions";
import { connect } from "react-redux";
import { toast } from "sonner";
import { i18n } from "i18n/localisation";
import ChangePasswordModal from "./ChangePasswordModal";

const SettingsPersonalContent = ({ personalDetails, user, ...props }) => {
  const [editMode, setEditMode] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [firstName, setFirstName] = useState(
    personalDetails && personalDetails.firstName
      ? personalDetails.firstName
      : "",
  );
  const [lastName, setLastName] = useState(
    personalDetails && personalDetails.lastName ? personalDetails.lastName : "",
  );
  const [phone, setPhone] = useState(
    personalDetails && personalDetails.phone ? personalDetails.phone : "",
  );

  const onSave = async () => {
    props.dispatch(
      saveUserData({
        personalDetails: {
          firstName,
          lastName,
          phone,
        },
      }),
    );

    toast.message(i18n("Personal details saved successfully"));
    setEditMode(false);
  };

  const onCancel = () => {
    setEditMode(false);
    setFirstName(personalDetails.firstName);
    setLastName(personalDetails.lastName);
    setPhone(personalDetails.phone);
  };

  const onChangePasswordClick = () => {
    setChangePasswordModalOpen(true);
  };

  const renderBody = () => {
    if (editMode) {
      return (
        <div className="settings_tabs_content_body">
          <div className="settings_tabs_content_body_row">
            <div className="settings_tabs_content_body_col">
              <span className="setting-heading">{i18n("First name")}</span>
              <PrimaryInput
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="settings_tabs_content_body_col">
              <span className="setting-heading">{i18n("Last name")}</span>
              <PrimaryInput
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="settings_tabs_content_body_row">
            <div className="settings_tabs_content_body_col">
              <span className="setting-heading">{i18n("Email address")}</span>
              <PrimaryInput disabled value={user.email ? user.email : ""} />
            </div>
            <div className="settings_tabs_content_body_col">
              <span className="setting-heading">{i18n("Phone number")}</span>
              <PrimaryInput
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <Button
            className="settings-save"
            variant="primary"
            onClick={() => onSave()}
          >
            {i18n("Save")}
          </Button>
        </div>
      );
    }

    return (
      <div className="settings_tabs_content_body">
        <div className="settings_tabs_content_body_row">
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("First name")}</span>
            <span>
              {personalDetails && personalDetails.firstName
                ? personalDetails.firstName
                : i18n("First name empty")}
            </span>
          </div>
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("Last name")}</span>
            <span>
              {personalDetails && personalDetails.lastName
                ? personalDetails.lastName
                : i18n("Last name empty")}
            </span>
          </div>
        </div>
        <div className="settings_tabs_content_body_row">
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("Email address")}</span>
            <span>{user.email ? user.email : ""}</span>
          </div>
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("Phone number")}</span>
            <span>
              {personalDetails && personalDetails.phone
                ? personalDetails.phone
                : i18n("Phone empty")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="settings_tabs_content">
      <div className="settings_tabs_content_header">
        <div className="settings_tabs_content_header_row">
          <div className="settings_tabs_content_header_col">
            <h2>{i18n("Personal Information")}</h2>
            <span>
              {i18n(
                "Your contact details that will appear on client valuation documents",
              )}
            </span>
          </div>
          <div className="settings_tabs_content_header_col">
            {editMode ? (
              <Button
                className="settings-cancel"
                variant="secondary"
                onClick={() => onCancel()}
              >
                {i18n("Cancel")}
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => setEditMode(true)}>
                {i18n("Edit")}
              </Button>
            )}
          </div>
        </div>
        <div className="divider"></div>
      </div>
      {renderBody()}
      {!editMode && (
        <Button
          variant="secondary"
          className="mt-4"
          onClick={onChangePasswordClick}
        >
          <span>{i18n("Change password")}</span>
        </Button>
      )}
      <ChangePasswordModal
        open={changePasswordModalOpen}
        closeModal={() => setChangePasswordModalOpen(false)}
      />
    </div>
  );
};

export default connect((state) => ({
  personalDetails: state.user.userData.personalDetails,
  user: state.auth.user,
  lang: state.user.userData.preferences.lang,
}))(SettingsPersonalContent);
