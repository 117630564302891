export default function (props) {
  return (
    <input
      className="primary-input"
      type={props.type}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      disabled={props.disabled}
      min={0}
    />
  );
}
