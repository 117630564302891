import { GoogleMap } from "@react-google-maps/api";
import { geolocatePropertyRequest } from "api/ai";
import FullscreenContainer from "components/core/FullscreenContainer";
import PropertyMarker from "components/map/PropertyMarker";
import Button from "components/ui/Button/Button";
import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { animateMapPan, animateMapZoom } from "utils/map";
import globeIcon from "assets/core/globe.svg";
import googleEarthIcon from "assets/core/google_earth.svg";
import { mapStyles } from "config/map";
import { Tooltip } from "react-tooltip";

export default function ({ property, setShowMap }) {
  const [map, setMap] = useState(null);
  const [isGeolocating, setIsGeolocating] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    setLatitude(parseFloat(property.latitude));
    setLongitude(parseFloat(property.longitude));
  }, [property]);

  // opens the property in google earth
  const openGoogleEarth = () => {
    window.open(
      `https://earth.google.com/web/@${latitude},${longitude},0a,1348.49405414d,35y,-6.58300448h,65.08334865t,0r/data=OgMKATA`,
      "earth-" + property.id,
    );
  };

  // geolocates the property with ai backend
  const geolocateProperty = async () => {
    if (!isGeolocatorSupported()) {
      return;
    }

    setIsGeolocating(true);
    animateMapZoom(map, 10, 1000);

    try {
      let response = await geolocatePropertyRequest(property);

      if (response) {
        setLatitude(parseFloat(response.latitude));
        setLongitude(parseFloat(response.longitude));
        animateMapZoom(map, 18, 1000);
        animateMapPan(map, {
          lat: parseFloat(response.latitude),
          lng: parseFloat(response.longitude),
        });
        toast.success(i18n("Property geolocated"));
      }
    } catch (e) {
      console.log(e);
      toast.error(i18n("Failed to geolocate property"));
    }

    setIsGeolocating(false);
  };

  const isGeolocatorSupported = () => {
    return property.detailedType.typology == "house";
  };

  return (
    <FullscreenContainer onCloseFullscreen={() => setShowMap(false)}>
      <GoogleMap
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        center={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }}
        zoom={18}
        onLoad={(map) => {
          map.setMapTypeId("hybrid");
          setMap(map);
        }}
        options={{
          styles: mapStyles,
          disableDefaultUI: true,
          zoomControl: false,
          gestureHandling: "greedy",
        }}
      >
        <PropertyMarker
          disableActions
          map={map}
          position={{
            lat: latitude,
            lng: longitude,
          }}
          property={property}
          isSelected={true}
        />
      </GoogleMap>
      <div className="geo-buttons">
        {/* <Button
          className={"geolocate-ai-btn" + (isGeolocatorSupported() ? "" : " disabled")}
          variant="secondary"
          onClick={geolocateProperty}
          loading={isGeolocating}
          data-tooltip-id="disabled-geolocator-tooltip"
          data-tooltip-content={i18n("This property type is not supported")}
        >
          <img src={globeIcon} alt="Geolocate" />
          <span>{i18n("Geolocate with AI")}</span>
        </Button> */}
        <Button
          className="google-earth-btn"
          variant="secondary"
          onClick={openGoogleEarth}
        >
          <img src={googleEarthIcon} alt="Google Earth" />
          <span>{i18n("Open in Google Earth")}</span>
        </Button>
      </div>
      {!isGeolocatorSupported() && <Tooltip id="disabled-geolocator-tooltip" />}
    </FullscreenContainer>
  );
}
