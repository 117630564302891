import { createReducer } from "@reduxjs/toolkit";
import {
  incrementNumberOfPolygonsFetched,
  incrementPolygonColourCode,
  setDrawnPolygons,
  setSelectedPolygons,
} from "../actions/polygonActions";
import { polygonColourLadder } from "../config/map";

const initialState = {
  drawnPolygons: [],
  selectedPolygons: [],
  polygonColourIndex: 0, // current index of polygon colour ladder
  numberOfPolygonsFetched: 0,
};

const polygonReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setDrawnPolygons, (state, action) => {
      if (action.payload.length === 0) {
        state.polygonColourMap = {};
        state.polygonColourIndex = 0;
      }

      state.drawnPolygons = action.payload;
    })
    .addCase(setSelectedPolygons, (state, action) => {
      state.selectedPolygons = action.payload;
    })
    .addCase(incrementPolygonColourCode, (state, action) => {
      let polygonColourIndex = state.polygonColourIndex;

      if (polygonColourIndex >= polygonColourLadder.length - 1) {
        polygonColourIndex = 0;
      } else {
        polygonColourIndex++;
      }

      state.polygonColourIndex = polygonColourIndex;
    })
    .addCase(incrementNumberOfPolygonsFetched, (state, action) => {
      if (action.payload === 0) {
        state.numberOfPolygonsFetched = action.payload;
        return;
      }

      state.numberOfPolygonsFetched = state.numberOfPolygonsFetched + 1;
    });
});

export default polygonReducer;
