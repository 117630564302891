import { getLang, i18n } from "i18n/localisation";
import {
  fetchCachedProperties,
  getLocalePropertyPrice,
  getPropertyGeoboundary,
} from "./properties";

export const statisticsPlugin = {
  id: "statisticsPlugin",
  afterDraw: (chart, args, options) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right, width, height },
    } = chart;

    ctx.save();
    ctx.font = "12px Inter";
    ctx.textAlign = "left";
    ctx.fillStyle = "#666";

    const statistics = options.statistics;
    const property = options.property;
    const padding = 10;
    const lineHeight = 20;
    let y = top + padding;

    // Draw statistics
    if (statistics) {
      ctx.fillText(
        i18n("Average price per sqm:") +
          ` ${getLocalePropertyPrice(property, Math.round(statistics.mean))}`,
        left + padding,
        y,
      );

      if (statistics.currentPropertyPercentile !== null) {
        y += lineHeight;
        ctx.fillText(
          `Percentile: ${statistics.currentPropertyPercentile}%`,
          left + padding,
          y,
        );
      }
    }

    ctx.restore();
  },
};

// returns the price distribution data for the chart/statistics cards
export async function getPriceDistributionData(property) {
  const propertyDistrict = await getPropertyGeoboundary(property);

  if (!propertyDistrict) {
    return null;
  }

  let properties = await fetchCachedProperties(
    propertyDistrict.id,
    property.saleType ?? "sale",
  );

  if (property.saleType === "rent") {
    properties = properties.filter(
      (p) => p.rental_isShortTerm === property.rental_isShortTerm,
    );
  }

  // Calculate prices per sqm
  const pricesPerSqm = properties
    .filter(
      (p) =>
        p.price &&
        p.size &&
        !isNaN(p.price) &&
        p.price > 0 &&
        !isNaN(p.size) &&
        p.size > 0,
    )
    .map((p) => ({
      price: Math.round(parseFloat(p.price) / parseFloat(p.size)),
      originalPrice: parseFloat(p.price),
      size: parseFloat(p.size),
      isCurrentProperty: false,
    }));

  // Add current property
  if (property.price && property.size) {
    const currentPropertyPrice = Math.round(
      parseFloat(property.price) / parseFloat(property.size),
    );
    pricesPerSqm.push({
      price: currentPropertyPrice,
      originalPrice: parseFloat(property.price),
      size: parseFloat(property.size),
      isCurrentProperty: true,
    });
  }

  // Calculate statistical measures
  let prices = pricesPerSqm.map((p) => p.price);
  if (property.saleType === "rent") {
    prices = pricesPerSqm.map((p) => p.originalPrice);
  }

  const mean = prices.reduce((a, b) => a + b, 0) / prices.length;
  const averagePrice =
    pricesPerSqm.reduce((a, b) => a + b.originalPrice, 0) / pricesPerSqm.length;
  const stdDev = Math.sqrt(
    prices.reduce((sq, n) => sq + Math.pow(n - mean, 2), 0) / prices.length,
  );

  // Calculate the actual minimum price from the data
  const minPrice = Math.min(...prices);
  const lowestDeviation = Math.max(minPrice, mean - 2 * stdDev, 1); // Ensure lowest deviation is at least 1

  // Create more meaningful bin ranges based on standard deviation
  const binRanges =
    property.saleType === "rent"
      ? [
          {
            min: 0,
            max: lowestDeviation,
            label: `< ${getLocalePropertyPrice(property, Math.round(lowestDeviation))}`,
          },
          {
            min: lowestDeviation,
            max: Math.max(lowestDeviation, mean - stdDev),
            label: `${getLocalePropertyPrice(property, Math.round(lowestDeviation))} - ${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean - stdDev)))}`,
          },
          {
            min: Math.max(lowestDeviation, mean - stdDev),
            max: Math.max(lowestDeviation, mean),
            label: `${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean - stdDev)))} - ${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean)))}`,
          },
          {
            min: Math.max(lowestDeviation, mean),
            max: Math.max(lowestDeviation, mean + stdDev),
            label: `${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean)))} - ${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean + stdDev)))}`,
          },
          {
            min: Math.max(lowestDeviation, mean + stdDev),
            max: Math.max(lowestDeviation, mean + 2 * stdDev),
            label: `${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean + stdDev)))} - ${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean + 2 * stdDev)))}`,
          },
          {
            min: Math.max(lowestDeviation, mean + 2 * stdDev),
            max: Infinity,
            label: `> ${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean + 2 * stdDev)))}`,
          },
        ]
      : [
          {
            min: 0,
            max: lowestDeviation,
            label: `< ${getLocalePropertyPrice(property, Math.round(lowestDeviation))}/m²`,
          },
          {
            min: lowestDeviation,
            max: Math.max(lowestDeviation, mean - stdDev),
            label: `${getLocalePropertyPrice(property, Math.round(lowestDeviation))} - ${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean - stdDev)))}/m²`,
          },
          {
            min: Math.max(lowestDeviation, mean - stdDev),
            max: Math.max(lowestDeviation, mean),
            label: `${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean - stdDev)))} - ${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean)))}/m²`,
          },
          {
            min: Math.max(lowestDeviation, mean),
            max: Math.max(lowestDeviation, mean + stdDev),
            label: `${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean)))} - ${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean + stdDev)))}/m²`,
          },
          {
            min: Math.max(lowestDeviation, mean + stdDev),
            max: Math.max(lowestDeviation, mean + 2 * stdDev),
            label: `${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean + stdDev)))} - ${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean + 2 * stdDev)))}/m²`,
          },
          {
            min: Math.max(lowestDeviation, mean + 2 * stdDev),
            max: Infinity,
            label: `> ${getLocalePropertyPrice(property, Math.round(Math.max(lowestDeviation, mean + 2 * stdDev)))}/m²`,
          },
        ];

  // Assign properties to bins
  const bins = binRanges.map((range) => ({
    label: range.label,
    count: 0,
    properties: [],
    isCurrentPropertyBin: false,
  }));

  pricesPerSqm.forEach((item) => {
    const priceToCompare =
      property.saleType === "rent" ? item.originalPrice : item.price;
    const binIndex = binRanges.findIndex(
      (range) => priceToCompare >= range.min && priceToCompare < range.max,
    );
    if (binIndex !== -1) {
      bins[binIndex].count++;
      bins[binIndex].properties.push(item);
      if (item.isCurrentProperty) {
        bins[binIndex].isCurrentPropertyBin = true;
      }
    }
  });

  // Calculate percentiles for context
  const currentProperty = pricesPerSqm.find((p) => p.isCurrentProperty);

  let statistics = [
    {
      label: i18n("Average price/m²:"),
      value: getLocalePropertyPrice(property, Math.round(mean)),
    },
    {
      label: i18n("Property price/m²:"),
      value: getLocalePropertyPrice(
        property,
        Math.round(parseFloat(property.price) / parseFloat(property.size)),
      ),
    },
    {
      label: i18n("Price/m² difference:"),
      value:
        Math.round(parseFloat(property.price) / parseFloat(property.size)) >
        Math.round(mean)
          ? `+${getLocalePropertyPrice(property, Math.round(parseFloat(property.price) / parseFloat(property.size)) - Math.round(mean))}`
          : `${getLocalePropertyPrice(property, Math.round(parseFloat(property.price) / parseFloat(property.size)) - Math.round(mean))}`,
      positive:
        Math.round(parseFloat(property.price) / parseFloat(property.size)) >
        Math.round(mean),
      negative:
        Math.round(mean) >
        Math.round(parseFloat(property.price) / parseFloat(property.size)),
    },
    {
      label: i18n("Price/m² difference %:"),
      value:
        Math.round(parseFloat(property.price) / parseFloat(property.size)) >
        Math.round(mean)
          ? `+${Math.round(
              ((Math.round(parseFloat(property.price) / parseFloat(property.size)) - Math.round(mean)) / Math.round(mean)) * 100,
            )}%`
          : `-${Math.round(
              ((Math.round(mean) - Math.round(parseFloat(property.price) / parseFloat(property.size))) / Math.round(mean)) * 100,
            )}%`,
      positive:
        Math.round(mean) <
        Math.round(parseFloat(property.price) / parseFloat(property.size)),
      negative:
        Math.round(mean) >
        Math.round(parseFloat(property.price) / parseFloat(property.size)),
    },
    {
      label: i18n("Average price:"),
      value: getLocalePropertyPrice(property, Math.round(averagePrice)),
    },
    {
      label: i18n("Price difference:"),
      value:
        currentProperty.originalPrice > averagePrice
          ? `+${getLocalePropertyPrice(property, Math.round(currentProperty.originalPrice - averagePrice))}`
          : `-${getLocalePropertyPrice(property, Math.round(averagePrice - currentProperty.originalPrice))}`,
      positive: currentProperty.originalPrice > averagePrice,
      negative: currentProperty.originalPrice < averagePrice,
    },
  ];

  if (property.saleType === "rent") {
    statistics = [
      {
        label: i18n("Average price:"),
        value:
          getLocalePropertyPrice(property, Math.round(averagePrice)) +
          (property.rental_isShortTerm ? i18n(" / day") : i18n(" / month"))
      },
      {
        label: i18n("Property price:"),
        value:
          getLocalePropertyPrice(property, property.price) +
          (property.rental_isShortTerm ? i18n(" / day") : i18n(" / month")),
      },
      {
        label: i18n("Price difference:"),
        value:
          currentProperty.originalPrice > averagePrice
            ? `+${getLocalePropertyPrice(property, Math.round(currentProperty.originalPrice - averagePrice))}`
            : `${getLocalePropertyPrice(property, Math.round(currentProperty.originalPrice - averagePrice))}`,
        positive: currentProperty.originalPrice > averagePrice,
        negative: currentProperty.originalPrice < averagePrice,
      },
      {
        label: i18n("Price difference %:"),
        value:
          currentProperty.originalPrice > averagePrice
            ? `+${Math.round(((currentProperty.originalPrice - averagePrice) / averagePrice) * 100)}%`
            : `-${Math.round(((averagePrice - currentProperty.originalPrice) / averagePrice) * 100)}%`,
        positive: currentProperty.originalPrice > averagePrice,
        negative: currentProperty.originalPrice < averagePrice,
      },
    ];
  }

  return {
    chartData: {
      labels: bins.map((bin) => bin.label),
      datasets: [
        {
          label: i18n("Number of Properties"),
          data: bins.map((bin) => bin.count),
          backgroundColor: bins.map((bin) =>
            bin.isCurrentPropertyBin ? "#ef5533" : "#8884",
          ),
          borderColor: bins.map((bin) =>
            bin.isCurrentPropertyBin ? "#ef5533" : "#888",
          ),
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: true,
          position: "top",
          labels: {
            generateLabels: (chart) => [
              {
                text: i18n("Other Properties"),
                fillStyle: "#8884",
                strokeStyle: "#888",
                lineWidth: 1,
              },
              {
                text: i18n("Current Property"),
                fillStyle: "#ef5533",
                strokeStyle: "#ef5533",
                lineWidth: 1,
              },
            ],
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: i18n("Price Range"),
            font: {
              size: 14,
            },
          },
        },
        y: {
          title: {
            display: true,
            text: i18n("Number of Properties"),
            font: {
              size: 14,
            },
          },
        },
      },
    },
    statistics: statistics,
  };
}
