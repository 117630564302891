// utility function for selecting property cards outside of the property panel context
export function selectPropertyCard(selectedCard) {
  // when we have scrolled to the element, add some UI focus to it
  document.querySelectorAll(".property-panel-card").forEach((el) => {
    if (el.id != selectedCard.id) {
      el.classList.remove("selected");
    }
  });

  selectedCard.classList.add("selected");
}
