import React, { useState } from "react";
import Button from "components/ui/Button/Button";
import PrimaryInput from "components/core/PrimaryInput";
import { saveUserData, saveUserPreferences } from "actions/userActions";
import { connect } from "react-redux";
import { toast } from "sonner";
import { SUPPORTED_LANGUAGES } from "config/constants";
import Dropdown from "components/core/Dropdown";
import { i18n } from "i18n/localisation";
import { Link } from "react-router-dom";
import { clearMapData, downloadMapData } from "db/mapStore";
import { fetchMapSearchIndexes } from "actions/geoActions";
import { setGlobalLoading, setGlobalLoadingMessage } from "actions/miscActions";

const SettingsPreferencesContent = ({ preferences, permissions, ...props }) => {
  const [language, setLanguage] = useState(
    preferences.lang ? preferences.lang : SUPPORTED_LANGUAGES[1],
  );

  const onSave = (lang) => {
    props.dispatch(
      saveUserData({
        preferences: {
          ...preferences,
          lang,
        },
      }),
    );

    setLanguage(lang);
    toast.message(i18n("Preferences saved successfully"));
  };

  const reloadMapCache = () => {
    clearMapData();
    props.dispatch(setGlobalLoading(true));
    props.dispatch(setGlobalLoadingMessage(i18n("Clearing old cache...")));

    setTimeout(async () => {
      props.dispatch(fetchMapSearchIndexes());
      await downloadMapData("es");
      toast.success(i18n("Map cache reloaded successfully"), {
        duration: 2000,
      });
    }, 1000);
  };

  return (
    <div className="settings_tabs_content">
      <div className="settings_tabs_content_header">
        <div className="settings_tabs_content_header_row">
          <div className="settings_tabs_content_header_col">
            <h2>{i18n("Language")}</h2>
            <span>{i18n("Your account language")}</span>
          </div>
        </div>
        <div className="divider"></div>
      </div>
      <div className="settings_tabs_content_body">
        <div className="settings_tabs_content_body_row">
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("Language")}</span>
            <Dropdown
              className="standard-dropdown"
              items={SUPPORTED_LANGUAGES.map((lang) => ({
                label: lang.name,
                value: lang.code,
                onSelect: () => {
                  onSave(lang);
                },
              }))}
              label={language.name}
            />
          </div>
        </div>
        {process.env.REACT_APP_NODE_ENV !== "production" && (
          <div className="settings_tabs_content_body_row">
            <div className="settings_tabs_content_body_col">
              <span className="setting-heading">{i18n("Map cache")}</span>
              <Button
                type="primary"
                variant="secondary"
                onClick={() => reloadMapCache()}
                style={{ maxWidth: "160px", justifyContent: "center" }}
              >
                {i18n("Reload map cache")}
              </Button>
            </div>
          </div>
        )}
        {permissions.upload_polygon && (
          <div className="settings_tabs_content_body_row">
            <div className="settings_tabs_content_body_col">
              <span className="setting-heading">Upload polygon</span>
              <Link to="/upload-polygon">
                <Button
                  type="primary"
                  variant="secondary"
                  style={{ maxWidth: "160px", justifyContent: "center" }}
                >
                  Upload polygon
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect((state) => ({
  preferences: state.user.userData.preferences,
  permissions: state.user.userData.permissions,
}))(SettingsPreferencesContent);
