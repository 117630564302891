import Button from "../../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { getLang, i18n } from "i18n/localisation";
import chatIcon from "assets/core/ai_search_2.svg";
import propertyIcon from "assets/core/property_icon.svg";
import cmaIcon from "assets/core/cma_2.svg";
import wcIcon from "assets/core/wc.svg";
import { connect } from "react-redux";

const FreeTrialModal = ({ isOpen, user, closeModal, ...props }) => {
  const openManual = (e) => {
    e.preventDefault();
    window.open(
      `https://dashboard.irealty.app/assets/pdf/manual_${getLang().code}.pdf`,
      "_blank",
    );
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        closeModal();
      }}
      className="free-trial-modal"
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Welcome to iRealty! 🎉")}</DialogTitle>
        </DialogHeader>
        <div className="px-6">
          <p className="mb-2 text-sm font-medium text-gray-700">
            {i18n("To maximise your")}{" "}
            <strong>{i18n("3 day free trial")}</strong>,{" "}
            {i18n("try out these unique features:")}
          </p>
          <div className="free-trial-modal_features mt-4 flex flex-col gap-4">
            <div className="flex gap-4">
              <div className="free-trial-modal_features_feature flex flex-col">
                <div className="free-trial-modal_features_feature_heading flex">
                  <div className="free-trial-modal_features_feature_heading_icon">
                    <img src={chatIcon} />
                  </div>
                  <span>{i18n("AI Assisted Search")}</span>
                </div>
                <span className="free-trial-modal_features_feature_desc mt-3 text-sm">
                  {i18n(
                    "Navigate rapidly by typing in the location and desired filters.",
                  )}
                </span>
              </div>
              <div className="free-trial-modal_features_feature flex flex-col">
                <div className="free-trial-modal_features_feature_heading flex">
                  <div className="free-trial-modal_features_feature_heading_icon">
                    <img src={propertyIcon} />
                  </div>
                  <span>{i18n("Property Data")}</span>
                </div>
                <span className="text-gray free-trial-modal_features_feature_desc mt-3 text-sm">
                  {i18n(
                    "Get access to one of the largest property databases across Spain.",
                  )}
                </span>
              </div>
            </div>

            <div className="flex gap-4">
              <div className="free-trial-modal_features_feature flex flex-col">
                <div className="free-trial-modal_features_feature_heading flex">
                  <div className="free-trial-modal_features_feature_heading_icon">
                    <img src={cmaIcon} />
                  </div>
                  <span>{i18n("CMA")}</span>
                </div>
                <span className="text-gray free-trial-modal_features_feature_desc mt-3 text-sm">
                  {i18n(
                    "Value any property in seconds with Cadastre data & accurate districts.",
                  )}
                </span>
              </div>
              <div className="free-trial-modal_features_feature flex flex-col">
                <div className="free-trial-modal_features_feature_heading flex">
                  <div className="free-trial-modal_features_feature_heading_icon">
                    <img src={wcIcon} />
                  </div>
                  <span>{i18n("Windows Card")}</span>
                </div>
                <span className="text-gray free-trial-modal_features_feature_desc mt-3 text-sm">
                  {i18n(
                    "Generate a WC for your agency instantly with a few clicks.",
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="free-trial-modal_download-manual mt-4 flex justify-between gap-8">
            <span className="free-trial-modal_download-manual_info self-center text-sm">
              <strong>{i18n("Download our guide")}</strong>{" "}
              {i18n("to learn more and unlock the full potential of iRealty:")}
            </span>
            <Button
              onClick={openManual}
              className="free-trial-modal_download-manual_btn"
              variant="secondary"
            >
              <span>{i18n("Download guide")}</span>
            </Button>
          </div>
        </div>
        <DialogFooter className="free-trial-modal_footer">
          <div className="flex">
            <span className="free-trial-modal_footer_text self-center text-sm">
              {i18n("After your")} <strong>{i18n("3 day free trial")}</strong>,{" "}
              {i18n("subscribe for unlimited access.")}
            </span>
          </div>
          <div className="flex justify-end">
            <Button
              variant="primary"
              onClick={() => closeModal()}
              className="ml-auto"
            >
              {i18n("Let's go!")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default connect((state) => ({
  lang: state.user.userData.preferences.lang,
}))(FreeTrialModal);
