import { i18n } from "i18n/localisation";
import { useEffect } from "react";
import { hideHoverDistricts } from "utils/map";
import Checkbox from "../input/Checkbox";
import { connect } from "react-redux";
import { MAX_ALLOWED_AREAS } from "config/constants";

const AreaSelectionPanelContent = ({
  filteredAreas,
  selectedAreas,
  isActive,
  handleCheckboxChange,
  propertyDistrict,
  onAreaHover,
  loading,
  listMode,
  areaActiveIndex,
  setAreaActiveIndex,
  cma,
}) => {
  const allAreaElements = document.querySelectorAll(".district-cb-container");

  // when mouse is moved outside of the district selection panel, remove active class. Otherwise, it will remains styled as hovered
  useEffect(() => {
    const onMouseMove = (e) => {
      if (!e.target.closest(".district-selection-panel")) {
        if (setAreaActiveIndex) {
          setAreaActiveIndex(null);
        }
      }
    };

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  const removeActiveClassName = () =>
    document
      .querySelector(".district-cb-container.active")
      ?.classList.remove("active");

  const getSubdivisionLocale = (area) => {
    if (area.type === "district") {
      return i18n("Property District");
    } else if (area.type === "locality") {
      return i18n("Property Locality");
    } else if (area.type === "zone") {
      return i18n("Property Zone");
    } else {
      return i18n("Property Municipality");
    }
  };

  const handleOnHover = (area) => {
    const activeElement =
      area &&
      Array.from(allAreaElements).find(
        (element) => element.getAttribute("data-id") === area.id,
      );

    hideHoverDistricts();
    removeActiveClassName();

    if (setAreaActiveIndex) {
      setAreaActiveIndex(null);
    }

    if (onAreaHover && area) {
      if (setAreaActiveIndex) {
        setAreaActiveIndex(Array.from(allAreaElements).indexOf(activeElement));
      }

      activeElement?.classList.add("active");
      onAreaHover(area);
    }
  };

  // subdivide districts into sections by district type (district, locality, zone)
  let sections = [];
  ["municipality", "zone", "district", "locality"].forEach((type) => {
    let subAreas = filteredAreas.filter((area) => area.type === type);
    if (subAreas.length > 0) {
      sections.push({
        type,
        areas: subAreas,
      });
    }
  });

  const disableCheckbox = (area) => {
    return (
      selectedAreas.length === MAX_ALLOWED_AREAS &&
      !selectedAreas.includes(area.id) &&
      !cma.cmaProperty
    );
  };

  const renderAreas = (areas) => {
    return areas.map((area) => {
      return (
        <div
          key={area.id}
          className={`district-cb-container 
            ${disableCheckbox(area) ? "disabled" : ""}`}
          onClick={() => handleCheckboxChange(area)}
          onMouseOver={() => handleOnHover(area)}
          onMouseLeave={() => handleOnHover(null)}
          data-id={area.id}
        >
          <Checkbox
            label={
              area.name +
              (area.type === "municipality"
                ? " " + i18n("(entire municipality)")
                : "")
            }
            checked={selectedAreas.find((a) => a.id == area.id)}
          />
          {propertyDistrict && propertyDistrict.id === area.id && (
            <div className="district-property-district">
              {getSubdivisionLocale(propertyDistrict)}
            </div>
          )}
        </div>
      );
    });
  };

  const getSectionTitle = (type) => {
    if (type === "district") {
      return i18n("Districts");
    } else if (type === "locality") {
      return i18n("Localities");
    } else if (type === "zone") {
      return i18n("Zones");
    } else if (type === "municipality") {
      return i18n("Municipality");
    }
  };

  const renderSections = () => {
    const getActiveArea = () => {
      const areaActiveInList = Array.from(allAreaElements)[areaActiveIndex];

      removeActiveClassName();

      const areaActiveElement = Array.from(allAreaElements).find(
        (area) => area === areaActiveInList,
      );

      if (areaActiveElement) {
        const areaActiveElementBoundings =
          areaActiveElement.getBoundingClientRect();

        if (
          areaActiveElementBoundings.bottom >
          document
            .querySelector(".district-selection-panel-footer")
            .getBoundingClientRect().top
        ) {
          areaActiveElement.scrollIntoView({ block: "end" });
        }

        if (
          areaActiveElementBoundings.top <
          document
            .querySelector(".district-selection-panel-header")
            .getBoundingClientRect().bottom
        ) {
          areaActiveElement.scrollIntoView({ block: "start" });
        }
        handleOnHover(
          filteredAreas.find(
            (area) => area.id === areaActiveElement.getAttribute("data-id"),
          ),
        );
      }
    };

    if (listMode === "flat") {
      return renderAreas(filteredAreas);
    }

    if (sections.length === 1) {
      return (
        <>
          {renderAreas(sections[0].areas)}
          {getActiveArea()}
        </>
      );
    }

    return sections.map((section) => (
      <div key={section.type} className="district-section">
        <div className="district-section-title">
          {getSectionTitle(section.type)}
        </div>
        {renderAreas(section.areas)}
        {getActiveArea()}
      </div>
    ));
  };

  return (
    <div
      className={`district-selection-panel-content fade-in ${isActive ? "active" : ""}`}
    >
      <div
        style={{ justifyContent: loading ? "center" : "initial" }}
        className="district-selection-panel-inner"
      >
        {!loading && renderSections()}
        {loading && (
          <div className="district-selection-loading-districts">
            Loading Areas...
          </div>
        )}
      </div>
    </div>
  );
};

export default connect((state) => ({
  cma: state.cma,
}))(AreaSelectionPanelContent);
