import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { PROPERTY_FEATURE_TYPE } from "components/property/PropertyFeatureChip";
import { CORS_PROXY } from "config/constants";
import { PDF_STYLES } from "config/pdf";
import { getLocalisation } from "i18n/localisation";
import {
  capitalizeWord,
  convertIdToRef,
  formatNumber,
  unwatermarkImg,
} from "utils/helpers";
import {
  extractPropertyHistory,
  getFeaturesFromProperty,
  getLocalePropertyPrice,
} from "utils/properties";
import aPropertiesIcon from "../../../../assets/property/aProperties.png";
import arrowDown from "../../../../assets/property/red_arrow.png";
import arrowUp from "../../../../assets/property/green_arrow.png";
import engelVolkersIcon from "../../../../assets/property/engel_volkers.png";
import fotocasaIcon from "../../../../assets/property/fotocasa.png";
import idealistaIcon from "../../../../assets/property/idealista.png";
import pisosIcon from "../../../../assets/property/pisos.png";
import { i18n } from "i18n/localisation";
import moment from "moment";

export const ImagePdfQuandrant = ({ images }) => {
  let rowOne = images.slice(0, 2);
  let rowTwo = images.slice(2, 4);

  return (
    <View style={PDF_STYLES.col}>
      <View style={{ ...PDF_STYLES.row, margin: 0 }}>
        {rowOne.map((image, index) => (
          <View key={index} style={PDF_STYLES.imageQuadrantImageContainer}>
            <Image
              style={PDF_STYLES.quandrantImage}
              src={CORS_PROXY + encodeURIComponent(unwatermarkImg(image.url))}
            />
          </View>
        ))}
      </View>
      <View style={{ ...PDF_STYLES.row, margin: 0 }}>
        {rowTwo.map((image, index) => (
          <View key={index} style={PDF_STYLES.imageQuadrantImageContainer}>
            <Image
              style={PDF_STYLES.quandrantImage}
              src={CORS_PROXY + encodeURIComponent(unwatermarkImg(image.url))}
            />
          </View>
        ))}
      </View>
    </View>
  );
};

export const WindowsCardDocument = ({
  property,
  duplicates,
  propertyName,
  clientName,
  propertySummary,
  isCheckboxChecked,
  ...props
}) => {
  const propertyImages = property.multimedia.images;
  const posterImageUrl =
    CORS_PROXY + encodeURIComponent(unwatermarkImg(propertyImages[0].url));
  const agencyLogoUrl = CORS_PROXY + encodeURIComponent(props.agencyLogoUrl);
  const secondImageUrl =
    propertyImages.length > 1
      ? CORS_PROXY + encodeURIComponent(unwatermarkImg(propertyImages[1].url))
      : null;

  const formatter = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });

  const lang = props.lang;
  const summaryChunks = propertySummary.replaceAll("\r", "").split("\n");
  const features = getFeaturesFromProperty(property)
    .filter(
      (f) =>
        f.type !== PROPERTY_FEATURE_TYPE.bedrooms &&
        f.type !== PROPERTY_FEATURE_TYPE.bathrooms,
    )
    .slice(0, 5);

  // construct 4 pages of two image pages
  // and then the rest pages of 8 images
  let twoImagePages = [];
  let eightImagePages = [];
  let lastImageIndex = 0;

  for (let i = 2; i < propertyImages.length; i += 2) {
    if (twoImagePages.length >= 4) {
      break;
    }

    if (propertyImages.length === 1) {
      twoImagePages.push([propertyImages[i]]);
      lastImageIndex = i;
      break;
    }

    twoImagePages.push([propertyImages[i], propertyImages[i + 1]]);

    if (i + 2 >= propertyImages.length - 1) {
      twoImagePages.push([propertyImages[i + 2]]);
      lastImageIndex = i + 2;
      break;
    }

    lastImageIndex = i + 1;
  }

  // filter out any undefined images
  twoImagePages = twoImagePages.filter((tip) => tip.every((i) => !!i));

  // 8 image pages
  for (let i = lastImageIndex + 1; i < propertyImages.length; i += 8) {
    let nextSegment = i + 8;
    if (nextSegment >= propertyImages.length) {
      let diff = nextSegment - propertyImages.length;
      let sliceOne = propertyImages.slice(i, i + 4);
      let sliceTwo = propertyImages.slice(i + 4, diff);
      let remainingQuadrants = [sliceOne];

      if (sliceTwo.length > 0) {
        remainingQuadrants.push(sliceTwo);
      }

      eightImagePages.push(remainingQuadrants);
      break;
    }

    eightImagePages.push([
      [
        propertyImages[i],
        propertyImages[i + 1],
        propertyImages[i + 2],
        propertyImages[i + 3],
      ],
      [
        propertyImages[i + 4],
        propertyImages[i + 5],
        propertyImages[i + 6],
        propertyImages[i + 7],
      ],
    ]);
  }

  const renderLandFeatures = () => {
    return (
      <>
        <View style={PDF_STYLES.row}>
          <Text style={PDF_STYLES.faintText}>
            {getLocalisation("Land type:", lang)}{" "}
          </Text>
          <Text style={PDF_STYLES.text}>
            {getLocalisation(capitalizeWord(property.land_type), lang)}
          </Text>
          <View style={PDF_STYLES.verticalDivider} />

          <Text style={PDF_STYLES.faintText}>
            {getLocalisation("Total surface area:", lang)}{" "}
          </Text>
          <Text style={PDF_STYLES.text}>{formatNumber(property.size)}m²</Text>
          <View style={PDF_STYLES.verticalDivider} />
        </View>

        <View style={PDF_STYLES.row}>
          {property.land_buildableSize && (
            <>
              <Text style={PDF_STYLES.faintText}>
                {getLocalisation("Buildable area:", lang)}{" "}
              </Text>
              <Text style={PDF_STYLES.text}>
                {formatNumber(property.land_buildableSize)}m²
              </Text>
              <View style={PDF_STYLES.verticalDivider} />
            </>
          )}

          {property.land_buildableFloors && (
            <>
              <Text style={PDF_STYLES.faintText}>
                {getLocalisation("Buildable floors:", lang)}{" "}
              </Text>
              <Text style={PDF_STYLES.text}>
                {property.land_buildableFloors}
              </Text>
              <View style={PDF_STYLES.verticalDivider} />
            </>
          )}
        </View>

        {features.filter((f) => f.type !== PROPERTY_FEATURE_TYPE.land_type)
          .length > 0 && (
          <View style={PDF_STYLES.row}>
            <Text style={PDF_STYLES.faintText}>
              {getLocalisation("Features:", lang)}{" "}
            </Text>
            <Text style={PDF_STYLES.text}>
              {features
                .filter((f) => f.type !== PROPERTY_FEATURE_TYPE.land_type)
                .map((f) => getLocalisation(f.unlocalisedValue, lang))
                .join(", ")}
            </Text>
          </View>
        )}
      </>
    );
  };

  const renderPropertyFeatures = () => {
    return (
      <>
        <View style={PDF_STYLES.row}>
          <Text style={PDF_STYLES.faintText}>
            {getLocalisation("Bedrooms:", lang)}{" "}
          </Text>
          <Text style={PDF_STYLES.text}>{property.rooms}</Text>
          <View style={PDF_STYLES.verticalDivider} />

          <Text style={PDF_STYLES.faintText}>
            {getLocalisation("Bathrooms:", lang)}{" "}
          </Text>
          <Text style={PDF_STYLES.text}>{property.bathrooms}</Text>
          <View style={PDF_STYLES.verticalDivider} />

          <Text style={PDF_STYLES.faintText}>
            {getLocalisation("Area built:", lang)}{" "}
          </Text>
          <Text style={PDF_STYLES.text}>{formatNumber(property.size)}m²</Text>
          <View style={PDF_STYLES.verticalDivider} />
        </View>

        <View style={PDF_STYLES.row}>
          <Text style={PDF_STYLES.faintText}>
            {getLocalisation("Features:", lang)}{" "}
          </Text>
          <Text style={PDF_STYLES.text}>
            {features
              .map((f) => getLocalisation(f.unlocalisedValue, lang))
              .join(", ")}
          </Text>
        </View>
      </>
    );
  };

  const renderFeatures = () => {
    if (property.buildingType === "property") {
      return renderPropertyFeatures();
    }

    if (property.buildingType === "land") {
      return renderLandFeatures();
    }

    return null;
  };

  const propertyHistorySection = () => {
    const propertyHistory = extractPropertyHistory(property, duplicates);

    return (
      <>
        <Page style={PDF_STYLES.page}>
          <Text style={PDF_STYLES.refText}>
            {getLocalisation("Reference", lang)}: {convertIdToRef(property.id)}
          </Text>
          <View style={PDF_STYLES.section}>
            <Text style={PDF_STYLES.historyTitle}>
              {i18n("Property History")}
            </Text>
            <View style={PDF_STYLES.midSpacer} />
            <View style={PDF_STYLES.midSpacer} />
            {propertyHistory.map((item, index) => (
              <>
                <View key={index} style={PDF_STYLES.row}>
                  <Image
                    style={PDF_STYLES.historyLogo}
                    src={item.dataSource.icon}
                  />
                  <View style={PDF_STYLES.historyInfoContent}>
                    <Text style={PDF_STYLES.historyPrice}>
                      {getLocalePropertyPrice(property, property.price)}
                      {item.priceChange && (
                        <>
                          <Image
                            style={
                              item.priceChange == "increase"
                                ? PDF_STYLES.priceHigh
                                : PDF_STYLES.priceLow
                            }
                            src={
                              item.priceChange == "increase"
                                ? arrowUp
                                : arrowDown
                            }
                          ></Image>
                          <Text
                            style={
                              item.priceChange == "increase"
                                ? PDF_STYLES.upPercentage
                                : PDF_STYLES.downPercentage
                            }
                          >
                            {item.priceChangePercentage}%
                          </Text>
                        </>
                      )}
                    </Text>
                    <Text style={PDF_STYLES.text}>{item.dataSource.title}</Text>
                    <Text style={PDF_STYLES.text}>{item.label}</Text>
                    <Text style={{ ...PDF_STYLES.text, fontWeight: 700 }}>
                      {item.advertiser}
                    </Text>
                    <Text style={PDF_STYLES.text}>
                      {moment.unix(item.time).format("DD/MM/yyyy")}
                    </Text>
                  </View>
                </View>
                {index < propertyHistory.length - 1 && (
                  <View key={index} style={PDF_STYLES.verticalLine} />
                )}
              </>
            ))}

            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          </View>
        </Page>
      </>
    );
  };

  return (
    <Document>
      <Page style={PDF_STYLES.page}>
        <Text style={PDF_STYLES.refText}>
          {getLocalisation("Reference", lang)}: {convertIdToRef(property.id)}
        </Text>
        <View style={PDF_STYLES.section}>
          <Text wrap style={{ ...PDF_STYLES.title, fontSize: 40 }}>
            {propertyName}
          </Text>
          <Text style={PDF_STYLES.heading}>{clientName}</Text>
          <View style={PDF_STYLES.midSpacer} />
          <View style={PDF_STYLES.midSpacer} />
          <View style={PDF_STYLES.midSpacer} />
          <View style={PDF_STYLES.imageContainer}>
            <Image style={PDF_STYLES.image} src={posterImageUrl} />
          </View>
          <View style={PDF_STYLES.midSpacer} />
          <View style={PDF_STYLES.midSpacer} />
          <View style={PDF_STYLES.midSpacer} />
          {property.address && (
            <Text style={PDF_STYLES.faintText}>
              {capitalizeWord(property.address)}
            </Text>
          )}
          {property.neighborhood && (
            <Text style={PDF_STYLES.faintText}>{property.neighborhood}</Text>
          )}
          {property.district &&
            property.district !== "n/a" &&
            property.district !== property.municipality && (
              <Text style={PDF_STYLES.faintText}>{property.district}</Text>
            )}
          <Text style={PDF_STYLES.faintText}>{property.municipality}</Text>
          <Text style={PDF_STYLES.faintText}>{property.province}</Text>
          <View style={PDF_STYLES.midSpacer} />
          <View style={PDF_STYLES.midSpacer} />
          <Text style={PDF_STYLES.valuationPriceText}>
            {getLocalePropertyPrice(property, property.price)}
          </Text>
          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>

      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          {secondImageUrl && (
            <>
              <View style={PDF_STYLES.imageContainer}>
                <Image style={PDF_STYLES.image} src={secondImageUrl} />
              </View>
              <View style={PDF_STYLES.midSpacer} />
              <View style={PDF_STYLES.midSpacer} />
            </>
          )}

          {renderFeatures()}

          <View style={PDF_STYLES.midSpacer} />
          <View style={PDF_STYLES.midSpacer} />
          <View style={PDF_STYLES.midSpacer} />

          <Text style={PDF_STYLES.faintText}>
            {getLocalisation("Summary:", lang)}{" "}
          </Text>
          <View style={PDF_STYLES.midSpacer} />
          <View>
            {summaryChunks.map((chunk, index) => (
              <>
                <Text key={index} style={PDF_STYLES.text}>
                  {chunk}
                </Text>
                <View style={PDF_STYLES.midSpacer} />
              </>
            ))}
          </View>
          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>

      {twoImagePages.map((images, index) => (
        <Page key={index} style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            {images.map((image, index) => (
              <View
                key={index}
                style={{ ...PDF_STYLES.imageContainer, maxHeight: 350 }}
              >
                <View style={PDF_STYLES.midSpacer} />
                <View style={PDF_STYLES.midSpacer} />
                <View style={PDF_STYLES.midSpacer} />
                <Image
                  style={PDF_STYLES.image}
                  src={
                    CORS_PROXY + encodeURIComponent(unwatermarkImg(image.url))
                  }
                />
                <View style={PDF_STYLES.midSpacer} />
                <View style={PDF_STYLES.midSpacer} />
                {index < images.length - 1 && (
                  <View style={PDF_STYLES.midSpacer} />
                )}
              </View>
            ))}
            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          </View>
        </Page>
      ))}

      {eightImagePages.map((quadrants, index) => (
        <Page key={index} style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            {quadrants.map((quadrant, index) => (
              <ImagePdfQuandrant key={index} images={quadrant} />
            ))}
            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          </View>
        </Page>
      ))}

      {isCheckboxChecked && propertyHistorySection()}
    </Document>
  );
};
