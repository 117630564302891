import { I18n } from "@aws-amplify/core";

// Localization strings for amplify components
const dict = {
  en: {
    "Sign In": "Sign In",
    "Sign Up": "Sign Up",
    "Forgot Password": "Forgot Password",
    Username: "Username",
    Password: "Password",
    "Change Password": "Change Password",
    "Enter your email": "Enter your email",
    "Enter your Password": "Enter your password",
    "Sign in": "Sign in",
    "Create Account": "Start Free Trial",
    "Forgot your password?": "Forgot your password?",
    "Confirm Password": "Confirm password",
    "Reset Password": "Reset password",
    "Send code": "Send code",
    "Back to Sign In": "Back to Sign In",
  },
  es: {
    "Sign In": "Iniciar sesión",
    "Sign Up": "Regístrate",
    "Forgot Password": "¿Olvidaste tu contraseña?",
    Username: "Nombre de usuario",
    Password: "Contraseña",
    "Change Password": "Cambiar la contraseña",
    "Enter your email": "Introduce su email",
    "Enter your Password": "Introduce su contraseña",
    "Sign in": "Iniciar sesión",
    "Create Account": "Empezar la prueba gratuita",
    "Forgot your password?": "¿Olvidaste su contraseña?",
    "Confirm Password": "Confirmar contraseña",
    "Reset Password": "Restablecer la contraseña",
    "Send code": "Enviar código",
    "Back to Sign In": "Volver a iniciar sesión",
  },
};

const getBrowserLanguage = () => {
  const language = navigator.language || navigator.userLanguage;
  return language.split("-")[0]; // get the language code, e.g., 'en', 'es'
};

const setLanguage = () => {
  const browserLanguage = getBrowserLanguage();
  const supportedLanguages = ["en", "es"]; // add all supported languages here

  if (supportedLanguages.includes(browserLanguage)) {
    I18n.setLanguage(browserLanguage);
  } else {
    I18n.setLanguage("en"); // fallback language
  }
};

export function initAmplifyI18n() {
  I18n.putVocabularies(dict);
  setLanguage();
}
