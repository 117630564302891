/**
 * Extension of google maps overlay that lets us render
 * lat long based overlays onto the map
 */
export default function createOverlay(container, pane, position) {
  class OverlayBase extends window.google.maps.OverlayView {
    constructor(container, pane, position) {
      super();
      this.container = container;
      this.pane = pane;
      this.position = position;
    }

    onAdd() {
      const pane = this.getPanes()?.[this.pane];
      pane.appendChild(this.container);
    }

    draw() {
      const projection = this.getProjection();
      const point = projection.fromLatLngToDivPixel(this.position);

      if (point === null) {
        return;
      }

      this.container.style.position = "absolute";
      this.container.style.left = `${point.x - this.container.offsetWidth / 2}px`;
      this.container.style.top = `${point.y - this.container.offsetHeight}px`;
    }

    onRemove() {
      if (this.container.parentNode !== null) {
        this.container.parentNode.removeChild(this.container);
      }
    }
  }

  return new OverlayBase(container, pane, position);
}
