import { Image, Text, View } from "@aws-amplify/ui-react";
import logo from "../../assets/logo.png";
import bg from "../../assets/bg_texture.png";
import { i18n } from "i18n/localisation";

const components = {
  Header() {
    return (
      <View className="logo-container" textAlign="center">
        <Image className="login-logo" alt="Amplify logo" src={logo} />
        <Image className="login-bg" src={bg} />
      </View>
    );
  },

  Footer() {
    return (
      <View className="login-footer" textAlign="center">
        <Text>iRealty App S.L. &copy; All Rights Reserved</Text>
      </View>
    );
  },

  SignUpHeader() {
    return (
      <View className="signup-free-trial-text">
        <Text className="signup-free-trial-text_header">
          {i18n("Get your 3 day Free Trial")}.
        </Text>
        <Text className="signup-free-trial-text_subheader">
          {i18n("No credit card details required")}.
        </Text>
      </View>
    );
  },
};

export default components;
