import fireApiRequest from "./core";

var FETCH_SUBSCRIPTION_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_subscription";
var CHECKOUT_SESSION_URL =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/create_checkout_session";
var UPDATE_PAYMENT_URL =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/create_update_payment_session";
var CANCEL_SUBSCRIPTION_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/cancel_subscription";
var RESUME_SUBSCRIPTION_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/resume_subscription";
var MODIFY_SUBSCRIPTION_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/modify_subscription";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_SUBSCRIPTION_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_subscription";
  CHECKOUT_SESSION_URL =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/create_checkout_session";
  UPDATE_PAYMENT_URL =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/create_update_payment_session";
  RESUME_SUBSCRIPTION_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/resume_subscription";
  CANCEL_SUBSCRIPTION_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/cancel_subscription";
  MODIFY_SUBSCRIPTION_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/modify_subscription";
}

// gets the url that creates a checkout for the user to subscribe
export function getCheckoutUrl(
  userId,
  plan = "standard",
  interval = "monthly",
  seats = 1,
) {
  // convert the plan to lowercase
  plan = plan.toLowerCase();

  // convert the interval to lowercase
  interval = interval.toLowerCase();

  // convert the seats to string if it's not
  if (typeof seats !== "string") {
    seats = seats.toString();
  }
  return (
    CHECKOUT_SESSION_URL +
    "?user_id=" +
    userId +
    "&plan=" +
    plan +
    "&interval=" +
    interval +
    "&seats=" +
    seats
  );
}

// gets the url that creates a checkout for the user to subscribe
export function getUpdatePaymentUrl(userId) {
  return UPDATE_PAYMENT_URL + "?user_id=" + userId;
}

// update an existing subscription
export async function modifySubscriptionRequest(
  userId,
  plan = "standard",
  interval = "monthly",
  seats = 1,
) {
  return await fireApiRequest(MODIFY_SUBSCRIPTION_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      user_id: userId,
      plan,
      interval,
      seats,
    }),
  });
}

/**
 * Fetch a subscription object tied to a user
 * @param {String} userId
 * @returns
 */
export async function fetchSubscriptionRequest() {
  return await fireApiRequest(FETCH_SUBSCRIPTION_ENDPOINT, { method: "POST" });
}

export async function cancelSubscriptionRequest() {
  return await fireApiRequest(CANCEL_SUBSCRIPTION_ENDPOINT, { method: "POST" });
}

export async function resumeSubscriptionRequest() {
  return await fireApiRequest(RESUME_SUBSCRIPTION_ENDPOINT, { method: "POST" });
}

export { FETCH_SUBSCRIPTION_ENDPOINT, CHECKOUT_SESSION_URL };
