import React, { useRef, useState } from "react";
import defaultProfilePic from "../../assets/core/default_profile.svg";
import Button from "components/ui/Button/Button";
import PrimaryInput from "components/core/PrimaryInput";
import { connect } from "react-redux";
import { saveUserData } from "actions/userActions";
import { toast } from "sonner";
import { getUrlForKey, uploadFile } from "utils/file";
import { i18n } from "i18n/localisation";

const SettingsCompanyContent = ({ agencyDetails, ...props }) => {
  const [editMode, setEditMode] = useState(false);
  const fileInput = useRef(null);
  const [agencyName, setAgencyName] = useState(
    agencyDetails ? agencyDetails.name : "",
  );
  const [agencyAddress, setAgencyAddress] = useState(
    agencyDetails ? agencyDetails.address : "",
  );
  const [pictureUrl, setPictureUrl] = useState(
    agencyDetails ? agencyDetails.picture : null,
  );

  const onSave = async () => {
    props.dispatch(
      saveUserData({
        agencyDetails: {
          name: agencyName,
          address: agencyAddress,
          picture: pictureUrl,
        },
      }),
    );

    toast.message(i18n("Company details saved successfully"));
    setEditMode(false);
  };

  const onCancel = () => {
    setEditMode(false);
    setAgencyName(agencyDetails.name);
    setAgencyAddress(agencyDetails.address);
    setPictureUrl(agencyDetails.picture);
  };

  const onFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      uploadFile(reader.result, file.name, file.type).then((result) => {
        setPictureUrl(getUrlForKey(result.key));
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const renderBody = () => {
    if (editMode) {
      return (
        <div className="settings_tabs_content_body">
          <div className="settings_tabs_content_body_row">
            <div className="settings_tabs_content_body_logo">
              <img src={pictureUrl ? pictureUrl : defaultProfilePic} />
            </div>
            <div className="settings_tabs_content_body_company">
              <span>{i18n("Agency logo")}</span>
              <span>{i18n("PNG or JPG")}</span>
            </div>
            <input
              ref={fileInput}
              style={{ display: "none" }}
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => onFileUpload(e)}
            />
            <div className="upload_new_logo">
              <Button
                onClick={(e) => fileInput.current.click()}
                variant="secondary"
              >
                {i18n("Upload new image")}
              </Button>
            </div>
          </div>
          <div className="settings_tabs_content_body_row">
            <div className="settings_tabs_content_body_col">
              <span className="setting-heading">{i18n("Agency name")}</span>
              <PrimaryInput
                value={agencyName}
                onChange={(e) => setAgencyName(e.target.value)}
              />
            </div>
            <div className="settings_tabs_content_body_col">
              <span className="setting-heading">{i18n("Agency address")}</span>
              <PrimaryInput
                value={agencyAddress}
                onChange={(e) => setAgencyAddress(e.target.value)}
              />
            </div>
          </div>
          <Button
            className="settings-save"
            variant="primary"
            onClick={() => onSave()}
          >
            {i18n("Save")}
          </Button>
        </div>
      );
    }

    return (
      <div className="settings_tabs_content_body">
        <div className="settings_tabs_content_body_row">
          <div className="settings_tabs_content_body_logo">
            <img src={pictureUrl ? pictureUrl : defaultProfilePic} />
          </div>
        </div>
        <div className="settings_tabs_content_body_row">
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("Agency name")}</span>
            <span>
              {agencyDetails && agencyDetails.name
                ? agencyDetails.name
                : i18n("Agency name empty")}
            </span>
          </div>
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("Agency address")}</span>
            <span>
              {agencyDetails && agencyDetails.address
                ? agencyDetails.address
                : i18n("Agency address empty")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="settings_tabs_content">
      <div className="settings_tabs_content_header">
        <div className="settings_tabs_content_header_row">
          <div className="settings_tabs_content_header_col">
            <h2>{i18n("Agency Details")}</h2>
            <span>
              {i18n(
                "Your company details that will appear on client valuation documents",
              )}
            </span>
          </div>
          <div className="settings_tabs_content_header_col">
            {editMode ? (
              <Button
                className="settings-cancel"
                variant="secondary"
                onClick={() => onCancel()}
              >
                {i18n("Cancel")}
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => setEditMode(true)}>
                {i18n("Edit")}
              </Button>
            )}
          </div>
        </div>
        <div className="divider"></div>
      </div>
      {renderBody()}
    </div>
  );
};

export default connect((state) => ({
  agencyDetails: state.user.userData.agencyDetails,
  lang: state.user.userData.preferences.lang,
}))(SettingsCompanyContent);
