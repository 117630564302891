import { addFilter, addPropertyFilter } from "actions/filterActions";
import { toCapitalCase } from "utils/helpers";
import store from "../../store";
import {
  AREA_RANGE_FILTER_ID,
  BATHROOMS_RANGE_FILTER_ID,
  BEDROOMS_RANGE_FILTER_ID,
  BUILDABLE_AREA_RANGE_FILTER_ID,
  BUILDABLE_FLOORS_RANGE_FILTER_ID,
  BUILDING_ALLOWED_USE_FILTERS,
  BUILDING_TYPE_FILTERS,
  COMMERCIAL_BUSINESS_ACTIVITIES_FILTERS,
  COMMERCIAL_FEATURES_FILTERS,
  COMMERCIAL_TYPE_FILTERS,
  FILTER_PREDICATE_MAPPINGS,
  GARAGE_FEATURES_FILTERS,
  GARAGE_SPACES_RANGE_FILTER_ID,
  LAND_FEATURES_FILTERS,
  LAND_TYPE_FILTERS,
  LIFTS_RANGE_FILTER_ID,
  NUM_FLOORS_RANGE_FILTER_ID,
  PRICE_RANGE_FILTER_ID,
  PRIVATE_BANK_PROPERTY_FILTERS,
  PROPERTY_FEATURES_FILTERS,
  PROPERTY_FILTER_GROUPS,
  PROPERTY_FILTER_OPERATIONS,
  PROPERTY_FILTER_TYPES,
  PROPERTY_TYPE_FILTERS,
  STORAGE_FEATURES_FILTERS,
  SELLER_TYPE_FILTERS,
} from "./filterTypes";
import { getSortFunction } from "./sort";

export function getActiveFilters() {
  let filters = store.getState().filters;
  return [].concat(filters.filters, filters.invisibleFilters);
}

/**
 *  Extracts a value from an object path such that
 * 'features.hasFeature' gets the hasFeature value of the features property
 **/
export function extractValueFromObjectPath(object, path) {
  let keys = path.split(".");
  let curValue = object;

  for (let key of keys) {
    if (curValue) {
      curValue = curValue[key];
    } else {
      curValue = null;
      break;
    }
  }

  return curValue;
}

/**
 * Runs through and filters each property by every filter
 * that has been set by the user
 */
export function filterProperties(_properties, filtersOverride = null) {
  let allFilters = filtersOverride || getActiveFilters();
  let buildingTypeFilter = store.getState().filters.buildingType;
  let sort = store.getState().filters.sort;
  let sortFunc = getSortFunction(sort);
  let properties = _properties.filter(
    (p) => p.buildingType == buildingTypeFilter.value,
  );

  if (allFilters.length === 0) {
    return sortFunc(properties);
  }

  let preSort = properties.filter((property) => {
    let metConditions = [];

    // each filter group has an operator (either 'and' or 'or')
    // for each filter group we check if all conditions are met for an 'and' operation
    // and we check if one of the conditions are met for an 'or' operation
    for (let filterGroup of PROPERTY_FILTER_GROUPS) {
      let filters = allFilters.filter(
        (filter) => filter.type === filterGroup.type,
      );
      let results = [];
      let conditionMet = false;

      for (let filter of filters) {
        // predicate filters have a custom function tied to them
        if (filter.operation === PROPERTY_FILTER_OPERATIONS.predicate) {
          let predicate = FILTER_PREDICATE_MAPPINGS[filter.predicate];
          results.push(predicate(property));
        } else {
          let value = extractValueFromObjectPath(property, filter.path);

          // equality filter
          if (filter.operation === PROPERTY_FILTER_OPERATIONS.equality) {
            results.push(filter.value === value);
          } else if (filter.operation === PROPERTY_FILTER_OPERATIONS.range) {
            // range filter, check against lower and upper bounds
            const { lowerBound, upperBound } = filter;
            results.push(value >= lowerBound && value <= upperBound);
          } else if (filter.operation === PROPERTY_FILTER_OPERATIONS.contains) {
            // contains filter - check if value in array
            results.push(value.includes(filter.value));
          }

          // notEquality filter
          if (filter.operation === PROPERTY_FILTER_OPERATIONS.notEquality) {
            results.push(filter.value !== value);
          }
        }
      }

      // no filters applied in this group, return true
      if (results.length === 0) {
        conditionMet = true;
      } else if (filterGroup.operator === "or") {
        // or operation, only one condition has to be true
        conditionMet = results.some((result) => result === true);
      } else {
        // and operation, all conditions have to be true
        conditionMet = results.every((result) => result === true);
      }

      metConditions.push(conditionMet);
    }

    return metConditions.every((condition) => condition === true);
  });

  return sortFunc(preSort);
}

// main function to get filtered properties for state update
export function getFilteredPropertiesForState() {
  let properties = store.getState().property.properties;
  return filterProperties(properties);
}

// main function to get filtered properties from state
export function getFilteredProperties() {
  return store.getState().property.filteredProperties;
}

// ai response is snake cased while our frontend filters use camel case
// so correct some of the values
function cleanPropertyValueFromAiResponse(propertyValue) {
  let result = propertyValue;

  if (propertyValue === "semi_detached_house") {
    result = "semidetachedHouse";
  }

  if (propertyValue === "detached_house") {
    result = "independantHouse";
  }

  if (propertyValue === "terraced_house") {
    result = "terracedHouse";
  }

  if (propertyValue === "country_house") {
    result = "countryHouse";
  }

  return result;
}

// extracts the desired building type from the ai generated filters
export function aiFiltersToBuildingType(aiFilters) {
  if (
    aiFilters.propertyType == "land" ||
    aiFilters.landType ||
    aiFilters.hasRoadAccess
  ) {
    return BUILDING_TYPE_FILTERS.find((b) => b.value == "land");
  }

  if (aiFilters.propertyType == "commercial") {
    return BUILDING_TYPE_FILTERS.find((b) => b.value == "commercial");
  }

  if (aiFilters.propertyType == "building") {
    return BUILDING_TYPE_FILTERS.find((b) => b.value == "building");
  }

  if (aiFilters.propertyType == "office") {
    return BUILDING_TYPE_FILTERS.find((b) => b.value == "office");
  }

  if (aiFilters.propertyType == "garage") {
    return BUILDING_TYPE_FILTERS.find((b) => b.value == "garage");
  }

  if (aiFilters.propertyType == "storage") {
    return BUILDING_TYPE_FILTERS.find((b) => b.value == "storage");
  }

  return BUILDING_TYPE_FILTERS[0];
}

/**
 * Converts response from ai api to frontend filters
 * @param {AiFilterObject} aiFilters
 * @returns {Array<Filter>} frontend ready filters
 */
export function aiFiltersToFrontendFilters(aiFilters, buildingType) {
  let filters = [];

  // property filters
  if (aiFilters.propertyType && buildingType === "property") {
    if (aiFilters.propertyType === "villa") {
      filters = [].concat(filters, [
        PROPERTY_TYPE_FILTERS.find((f) => f.path === "detailedType.isVilla"),
      ]);
    }

    // 'house' is many different property types
    if (aiFilters.propertyType === "house") {
      let houseFilters = PROPERTY_TYPE_FILTERS.filter((propertyType) => {
        return (
          propertyType.value === "independantHouse" ||
          propertyType.value === "semidetachedHouse" ||
          propertyType.value === "terracedHouse" ||
          propertyType.value === "countryHouse"
        );
      });

      filters = filters.concat(houseFilters);
    } else {
      for (let propertyType of PROPERTY_TYPE_FILTERS) {
        let aiFilterPropertyTypeResponse = cleanPropertyValueFromAiResponse(
          aiFilters.propertyType,
        );

        if (propertyType.value === aiFilterPropertyTypeResponse) {
          filters.push(propertyType);
          break;
        }
      }
    }
  }

  if (aiFilters.rooms) {
    let filter = {
      id: BEDROOMS_RANGE_FILTER_ID,
      lowerBound: aiFilters.rooms.from,
      upperBound: aiFilters.rooms.to,
      path: "rooms",
      type: PROPERTY_FILTER_TYPES.bedrooms,
      operation: PROPERTY_FILTER_OPERATIONS.range,
    };

    filters.push(filter);
  }

  if (aiFilters.bathrooms) {
    let filter = {
      id: BATHROOMS_RANGE_FILTER_ID,
      lowerBound: aiFilters.bathrooms.from,
      upperBound: aiFilters.bathrooms.to,
      path: "bathrooms",
      type: PROPERTY_FILTER_TYPES.bathrooms,
      operation: PROPERTY_FILTER_OPERATIONS.range,
    };

    filters.push(filter);
  }

  if (aiFilters.price_range) {
    let filter = {
      id: PRICE_RANGE_FILTER_ID,
      lowerBound: aiFilters.price_range.from,
      upperBound: aiFilters.price_range.to,
      path: "price",
      type: PROPERTY_FILTER_TYPES.price,
      operation: PROPERTY_FILTER_OPERATIONS.range,
    };

    filters.push(filter);
  }

  if (aiFilters.size) {
    let filter = {
      id: AREA_RANGE_FILTER_ID,
      lowerBound: aiFilters.size.from,
      upperBound: aiFilters.size.to,
      path: "size",
      type: PROPERTY_FILTER_TYPES.area,
      operation: PROPERTY_FILTER_OPERATIONS.range,
    };

    filters.push(filter);
  }

  if (aiFilters.isPrivateBankProperty) {
    let filter = PRIVATE_BANK_PROPERTY_FILTERS.find(
      (f) => f.value === aiFilters.isPrivateBankProperty,
    );

    filters.push(filter);
  }

  if (aiFilters.sellerType) {
    if (aiFilters.sellerType === "owner") {
      let filter = SELLER_TYPE_FILTERS.find((f) => f.label === "Owner");
      filters.push(filter);
    }

    if (aiFilters.sellerType === "agent") {
      let filter = SELLER_TYPE_FILTERS.find((f) => f.label === "Agent");
      filters.push(filter);
    }
  }

  if (buildingType === "property") {
    if (aiFilters.hasSwimmingPool) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasSwimmingPool",
      );
      filters.push(filter);
    }

    if (aiFilters.hasAirConditioning) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasAirConditioning",
      );
      filters.push(filter);
    }

    if (aiFilters.hasTerrace) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasTerrace",
      );
      filters.push(filter);
    }

    if (aiFilters.hasGarden) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasGarden",
      );
      filters.push(filter);
    }

    if (aiFilters.hasSeaView) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasSeaView",
      );
      filters.push(filter);
    }

    if (aiFilters.hasCentralHeating) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasCentralHeating",
      );
      filters.push(filter);
    }

    if (aiFilters.hasStorage) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasStorage",
      );
      filters.push(filter);
    }

    if (aiFilters.hasFittedWardrobes) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasFittedWardrobes",
      );
      filters.push(filter);
    }

    if (aiFilters.hasBalcony) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasBalcony",
      );
      filters.push(filter);
    }

    if (aiFilters.hasGreenery) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasGreenery",
      );
      filters.push(filter);
    }

    if (aiFilters.hasGarage) {
      let filter = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.path === "features.hasGarage",
      );
      filters.push(filter);
    }
  }

  // land filters
  if (buildingType == "land") {
    if (aiFilters.landType) {
      let filter = LAND_TYPE_FILTERS.find((l) => l.value == aiFilters.landType);

      if (filter) {
        filters.push(filter);
      }
    }

    if (aiFilters.buildable_size) {
      let filter = {
        id: BUILDABLE_AREA_RANGE_FILTER_ID,
        lowerBound: aiFilters.buildable_size.from,
        upperBound: aiFilters.buildable_size.to,
        path: "land_buildableSize",
        type: PROPERTY_FILTER_TYPES.landBuildableSize,
        operation: PROPERTY_FILTER_OPERATIONS.range,
      };

      filters.push(filter);
    }

    if (aiFilters.buildable_floors) {
      let filter = {
        id: BUILDABLE_FLOORS_RANGE_FILTER_ID,
        lowerBound: aiFilters.buildable_floors.from,
        upperBound: aiFilters.buildable_floors.to,
        path: "land_buildableFloors",
        type: PROPERTY_FILTER_TYPES.landBuildableFloors,
        operation: PROPERTY_FILTER_OPERATIONS.range,
      };

      filters.push(filter);
    }

    if (aiFilters.hasWater) {
      let filter = LAND_FEATURES_FILTERS.find(
        (f) => f.path === "land_features.hasWater",
      );
      filters.push(filter);
    }

    if (aiFilters.hasSewage) {
      let filter = LAND_FEATURES_FILTERS.find(
        (f) => f.path === "land_features.hasSewage",
      );
      filters.push(filter);
    }

    if (aiFilters.hasElectricity) {
      let filter = LAND_FEATURES_FILTERS.find(
        (f) => f.path === "land_features.hasElectricity",
      );
      filters.push(filter);
    }

    if (aiFilters.hasNaturalGas) {
      let filter = LAND_FEATURES_FILTERS.find(
        (f) => f.path === "land_features.hasNaturalGas",
      );
      filters.push(filter);
    }

    if (aiFilters.hasStreetLighting) {
      let filter = LAND_FEATURES_FILTERS.find(
        (f) => f.path === "land_features.hasStreetLighting",
      );
      filters.push(filter);
    }

    if (aiFilters.hasSidewalk) {
      let filter = LAND_FEATURES_FILTERS.find(
        (f) => f.path === "land_features.hasSidewalk",
      );
      filters.push(filter);
    }

    if (aiFilters.hasRoadAccess) {
      let filter = LAND_FEATURES_FILTERS.find(
        (f) => f.path === "land_roadAccess",
      );
      filters.push(filter);
    }
  }

  // commercial filters
  if (buildingType == "commercial") {
    if (aiFilters.commercialType) {
      let filter = COMMERCIAL_TYPE_FILTERS.find(
        (l) => l.value == aiFilters.commercialType,
      );

      if (filter) {
        filters.push(filter);
      }
    }

    if (aiFilters.hasSafetyGate) {
      let filter = COMMERCIAL_FEATURES_FILTERS.find(
        (f) => f.path === "commercial_features.hasSafetyGate",
      );
      filters.push(filter);
    }

    if (aiFilters.hasCCTV) {
      let filter = COMMERCIAL_FEATURES_FILTERS.find(
        (f) => f.path === "commercial_features.hasCCTV",
      );
      filters.push(filter);
    }

    if (aiFilters.isOnCorner) {
      let filter = COMMERCIAL_FEATURES_FILTERS.find(
        (f) => f.path === "commercial_features.isOnCorner",
      );
      filters.push(filter);
    }

    if (aiFilters.hasFullyEquippedKitchen) {
      let filter = COMMERCIAL_FEATURES_FILTERS.find(
        (f) => f.path === "commercial_features.hasFullyEquippedKitchen",
      );
      filters.push(filter);
    }

    if (aiFilters.hasAlarmSystem) {
      let filter = COMMERCIAL_FEATURES_FILTERS.find(
        (f) => f.path === "commercial_features.hasAlarmSystem",
      );
      filters.push(filter);
    }

    if (aiFilters.hasStoreroom) {
      let filter = COMMERCIAL_FEATURES_FILTERS.find(
        (f) => f.path === "commercial_features.hasStoreroom",
      );
      filters.push(filter);
    }

    if (aiFilters.commercialActivities) {
      let filter = COMMERCIAL_BUSINESS_ACTIVITIES_FILTERS.find(
        (f) => f.value === aiFilters.commercialActivities,
      );
      filters.push(filter);
    }
  }

  // building filters
  if (buildingType == "building") {
    if (aiFilters.building_numLifts) {
      let filter = {
        id: LIFTS_RANGE_FILTER_ID,
        lowerBound: aiFilters.building_numLifts.from,
        upperBound: aiFilters.building_numLifts.to,
        path: "building_numLifts",
        type: PROPERTY_FILTER_TYPES.buildingLifts,
        operation: PROPERTY_FILTER_OPERATIONS.range,
      };

      filters.push(filter);
    }

    if (aiFilters.numFloors) {
      let filter = {
        id: NUM_FLOORS_RANGE_FILTER_ID,
        lowerBound: aiFilters.numFloors.from,
        upperBound: aiFilters.numFloors.to,
        path: "numFloors",
        type: PROPERTY_FILTER_TYPES.floors,
        operation: PROPERTY_FILTER_OPERATIONS.range,
      };

      filters.push(filter);
    }

    if (aiFilters.building_garageSpaces) {
      let filter = {
        id: GARAGE_SPACES_RANGE_FILTER_ID,
        lowerBound: aiFilters.building_garageSpaces.from,
        upperBound: aiFilters.building_garageSpaces.to,
        path: "building_garageSpaces",
        type: PROPERTY_FILTER_TYPES.buildingGarageSpaces,
        operation: PROPERTY_FILTER_OPERATIONS.range,
      };

      filters.push(filter);
    }

    if (aiFilters.building_use) {
      let filter = BUILDING_ALLOWED_USE_FILTERS.find(
        (f) => f.value === aiFilters.building_use,
      );

      filters.push(filter);
    }
  }

  // office filters
  if (buildingType == "garage") {
    if (aiFilters.hasAutomaticDoor) {
      let filter = GARAGE_FEATURES_FILTERS.find(
        (f) => f.path === "garage_features.hasAutomaticDoor",
      );

      filters.push(filter);
    }

    if (aiFilters.hasSecurity) {
      let filter = GARAGE_FEATURES_FILTERS.find(
        (f) => f.path === "garage_features.hasSecurity",
      );

      filters.push(filter);
    }

    if (aiFilters.hasCCTV) {
      let filter = GARAGE_FEATURES_FILTERS.find(
        (f) => f.path === "garage_features.hasCCTV",
      );

      filters.push(filter);
    }

    if (aiFilters.hasAlarmSystem) {
      let filter = GARAGE_FEATURES_FILTERS.find(
        (f) => f.path === "garage_features.hasAlarm",
      );

      filters.push(filter);
    }

    if (aiFilters.hasLift) {
      let filter = GARAGE_FEATURES_FILTERS.find(
        (f) => f.path === "garage_features.hasLift",
      );

      filters.push(filter);
    }
  }

  // storage filters
  if (buildingType == "storage") {
    if (aiFilters.is24hAccess) {
      let filter = STORAGE_FEATURES_FILTERS.find(
        (f) => f.path === "storage_features.is24hAccess",
      );

      filters.push(filter);
    }

    if (aiFilters.hasLoadingBay) {
      let filter = STORAGE_FEATURES_FILTERS.find(
        (f) => f.path === "storage_features.hasLoadingBay",
      );

      filters.push(filter);
    }
  }

  if (aiFilters.tags && aiFilters.tags.length > 0) {
    for (let tag of aiFilters.tags) {
      let filter = {
        id: Math.random(),
        path: "description",
        type: PROPERTY_FILTER_TYPES.tags,
        operation: PROPERTY_FILTER_OPERATIONS.contains,
        value: tag.value,
        label: toCapitalCase(tag.label),
      };

      filters.push(filter);
    }
  }

  return filters;
}

const TYPOLOGY_MAPPING = {
  house: "house",
  flat: "flat",
  villa: "house",
  semidetachedHouse: "house",
  independantHouse: "house",
  villa: "house",
  penthouse: "flat",
  apartment: "flat",
  terracedHouse: "house",
  countryHouse: "house",
  duplex: "flat",
};

// for filtering properties by relevant property typology in the cma
export function filterByRelevantTypology(property, properties) {
  let filteredProperties = properties.filter((p) => {
    if (!property) {
      return false;
    }

    // always return the catastro property
    if (p.isCmaProperty) {
      return true;
    }

    // filter out properties without a price
    if (!p.price) {
      return false;
    }

    if (property.buildingType == "land") {
      if (p.buildingType != "land") {
        return false;
      }

      if (property.land_type == "all") return true; // all lands
      return p.land_type == property.land_type;
    }

    if (property.buildingType == "commercial") {
      return p.commercial_type == property.commercial_type;
    }

    // now properties are either houses or flats
    let typology = property.typology;

    // for flats we can include penthouses, apartments and duplexes
    if (
      typology === "flat" ||
      typology === "duplex" ||
      typology === "penthouse"
    ) {
      return (
        p.detailedType.subTypology === "penthouse" ||
        p.detailedType.subTypology === "apartment" ||
        p.detailedType.subTypology === "duplex" ||
        p.detailedType.subTypology === "studio" ||
        p.detailedType.subTypology === ""
      );
    }

    // for semi detached and terraced we can include both
    if (typology === "semidetachedHouse" || typology === "terracedHouse") {
      return (
        p.detailedType.subTypology === "semidetachedHouse" ||
        p.detailedType.subTypology === "terracedHouse"
      );
    }

    // for villas it needs to match by villa
    if (typology === "villa" && p.detailedType.isVilla) {
      return true;
    }

    // for houses it needs to be an exact match by subtypology
    return p.detailedType.subTypology === typology;
  });

  // finally filter by condition
  return filteredProperties.filter((p) => {
    if (property.buildingType == "property" && property.status != "all") {
      return property.status == p.status;
    }

    if (property.buildingType == "commercial" && property.status != "all") {
      return property.status == p.status;
    }

    return true;
  });
}

// converts from serialized saved search to one compatible with the frontend
export function convertSavedSearchFilterToFrontendFilter(filter) {
  let result = { ...filter };
  result.id = parseFloat(filter.id);
  result.type = parseFloat(filter.type);
  result.operation = parseFloat(filter.operation);

  if (filter.upperBound) {
    result.upperBound = parseFloat(filter.upperBound);
  }

  if (filter.lowerBound) {
    result.lowerBound = parseFloat(filter.lowerBound);
  }

  return result;
}

export function getFeatureFilterArrayFromProperty(property) {
  let features = [];

  for (let feature of PROPERTY_FEATURES_FILTERS) {
    let value = extractValueFromObjectPath(property, feature.path);
    if (value) {
      features.push(feature);
    }
  }

  return features;
}

export function addTagFilter(tag) {
  let filter = {
    id: Math.random(),
    type: PROPERTY_FILTER_TYPES.tags,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: tag.value,
    label: tag.label,
  };

  store.dispatch(addPropertyFilter(filter));
}
