const formFields = {
  signIn: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
  signUp: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
  resetPassword: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
};

export default formFields;
