import { useEffect } from "react";
import { connect } from "react-redux";
import { setUser } from "../actions/userActions";

/**
 * Helper component that injects the AWS Amplify user into the redux state
 */
const UserProvider = (props) => {
  useEffect(() => {
    if (!props.user) {
      props.dispatch(setUser(props.amplifyUser.attributes));
    }
  });

  return null;
};

export default connect((state) => ({
  user: state.auth.user,
}))(UserProvider);
