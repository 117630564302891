import {
  setCanDrawGeographicPolygons,
  setHideBounds,
} from "actions/mapActions";
import { i18n } from "i18n/localisation";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { disableAreaClickability, enableAreaClickability } from "utils/map";
import { saveUserData } from "../../actions/userActions";
import closeIcon from "../../assets/core/close.svg";
import closeInactiveIcon from "../../assets/core/close_inactive.svg";
import minusIcon from "../../assets/core/minus.svg";
import plusIcon from "../../assets/core/plus.svg";
import handIcon from "../../assets/map/hand.svg";
import handIconSelected from "../../assets/map/hand_selected.svg";
import polygonIcon from "../../assets/map/polygon.svg";
import polygonIconSelected from "../../assets/map/polygon_selected.svg";

const MapToolbar = (props) => {
  const onMapTypeChange = () => {
    let preferences = Object.assign({}, props.user.userData.preferences);
    let mapType = "hybrid";

    if (
      !props.user.userData.preferences.mapType ||
      props.user.userData.preferences.mapType === "hybrid"
    ) {
      mapType = "roadmap";
    } else {
      mapType = "hybrid";
    }

    preferences.mapType = mapType;
    props.dispatch(saveUserData({ preferences }));
    props.onMapTypeChange(mapType);
  };

  const onPolygonClick = () => {
    disableAreaClickability();
    window.googleMapsDrawingManager.setDrawingMode("polygon");
    props.setDrawingMode(true);

    if (!props.map.areaSelectionMode) {
      props.dispatch(setCanDrawGeographicPolygons(false));
    }
  };

  const onHandClick = () => {
    enableAreaClickability();
    window.googleMapsDrawingManager.setDrawingMode(null);
    props.setDrawingMode(false);

    if (!props.map.areaSelectionMode) {
      props.dispatch(setCanDrawGeographicPolygons(true));
      props.shouldDrawGeographicBounds();
    }
  };

  const onShowBoundsClick = () => {
    props.dispatch(setHideBounds(!props.map.hideBounds));
  };

  return (
    <div
      className={
        "map-toolbar-container" +
        (props.page === "cma" ? " with-side-panel" : "")
      }
    >
      <div className="map-toolbar">
        <div
          onClick={onHandClick}
          className={
            "map-toolbar_item" + (props.drawingMode ? "" : " selected")
          }
          data-tooltip-id="drag-tool-tooltip"
          data-tooltip-content={i18n("Drag")}
        >
          {props.drawingMode ? (
            <img draggable={false} src={handIcon} alt="hand icon" />
          ) : (
            <img draggable={false} src={handIconSelected} alt="hand icon" />
          )}
        </div>
        <div
          onClick={onPolygonClick}
          className={
            "map-toolbar_item" + (props.drawingMode ? " selected" : "")
          }
          data-tooltip-id="draw-polygon-tooltip"
          data-tooltip-content={i18n("Draw polygon")}
        >
          {props.drawingMode ? (
            <img
              draggable={false}
              src={polygonIconSelected}
              alt="poligon icon"
            />
          ) : (
            <img draggable={false} src={polygonIcon} alt="poligon icon" />
          )}
        </div>
        <div
          onClick={props.onClearSelection}
          className={
            "map-toolbar_item" + (!props.canClearSelection ? " inactive" : "")
          }
          data-tooltip-id="clear-tooltip"
          data-tooltip-content={i18n("Clear selection")}
        >
          {props.canClearSelection ? (
            <img draggable={false} src={closeIcon} alt="close icon" />
          ) : (
            <img draggable={false} src={closeInactiveIcon} alt="close icon" />
          )}
        </div>
        {!props.page === "cma" && (
          <>
            <div className="map-toolbar_divider"></div>
            <div
              onClick={onMapTypeChange}
              className={
                "map-toolbar_item" +
                (!props.user.userData.preferences.mapType ||
                props.user.userData.preferences.mapType === "hybrid"
                  ? " selected"
                  : "")
              }
            >
              <span>{i18n("Satellite")}</span>
            </div>
          </>
        )}
        {/* {props.map.canShowBoundsToolbarItem &&
          props.polygon.drawnPolygons.length === 0 && (
            <div
              onClick={onShowBoundsClick}
              className={
                "map-toolbar_item" + (props.map.hideBounds ? "" : " selected")
              }
            >
              <span>{i18n("Boundaries")}</span>
            </div>
          )} */}
        <div className="map-toolbar_zoom-controls">
          <div className="map-toolbar_divider"></div>
          <div onClick={() => props.zoomBy(1)} className="map-toolbar_item">
            <img draggable={false} src={plusIcon} alt="Plus icon" />
          </div>
          <div onClick={() => props.zoomBy(-1)} className="map-toolbar_item">
            <img draggable={false} src={minusIcon} alt="Minus icon" />
          </div>
        </div>
      </div>
      <Tooltip id="clear-tooltip" />
      <Tooltip id="draw-polygon-tooltip" />
      <Tooltip id="drag-tool-tooltip" />
    </div>
  );
};

export default connect((state) => ({
  user: state.user,
  map: state.map,
  polygon: state.polygon,
}))(MapToolbar);
