import {
  deleteNotificationsRequest,
  fetchUserNotificationsRequest,
  markNotificationsAsReadRequest,
} from "api/notifications";
import store from "store";

const { createAction } = require("@reduxjs/toolkit");

const setNotifications = createAction("notifications/setNotifications");
const setNotificationsOpen = createAction("notifications/setNotificationsOpen");

export function fetchUserNotifications() {
  return async (dispatch) => {
    try {
      const data = await fetchUserNotificationsRequest();

      // make sure the collection or searches which
      // the user notifications are related to exist
      const { collections } = store.getState().collections;
      const searches = collections.reduce(
        (acc, collection) => [...acc, ...collection.searches],
        [],
      );

      let notifications = data.notifications.filter((n) => {
        if (n.type === "property_alert") {
          return (
            searches.some((s) => s.id === n.data.search_id) &&
            collections.some((c) => c.id === n.data.collection_id)
          );
        }

        return false;
      });

      dispatch(setNotifications(notifications));
    } catch (e) {
      console.log(e);
    }
  };
}

export function markNotificationAsRead(notificationId) {
  return async (dispatch) => {
    let notifications = store.getState().notifications.notifications;

    dispatch(
      setNotifications(
        notifications.map((n) => {
          if (n.id === notificationId) {
            return { ...n, status: "read" };
          }

          return n;
        }),
      ),
    );

    await markNotificationsAsReadRequest([notificationId]);
  };
}

export function markAllNotificationsAsRead() {
  return async (dispatch) => {
    let notifications = store.getState().notifications.notifications;

    dispatch(
      setNotifications(
        notifications.map((n) => {
          return { ...n, status: "read" };
        }),
      ),
    );

    await markNotificationsAsReadRequest(notifications.map((n) => n.id));
  };
}

export function deleteNotifications(notificationIds) {
  return async (dispatch) => {
    let notifications = store.getState().notifications.notifications;

    dispatch(
      setNotifications(
        notifications.filter((n) => !notificationIds.includes(n.id)),
      ),
    );

    await deleteNotificationsRequest(notificationIds);
  };
}

export { setNotifications, setNotificationsOpen };
