import fireApiRequest from "./core";

var SEND_CONTACT_FORM_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/contact_form_submission";

if (process.env.REACT_APP_NODE_ENV === "production") {
  SEND_CONTACT_FORM_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/contact_form_submission";
}

export async function sendContactForm({
  propertyId,
  fullName,
  email,
  phoneNumber,
  comments,
}) {
  return await fireApiRequest(SEND_CONTACT_FORM_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      property_id: propertyId,
      full_name: fullName,
      email,
      phone_number: phoneNumber,
      comments,
    }),
  });
}
