import Dashboard from "../Dashboard";
import PropertyMap from "../map/PropertyMap";

export default function SearchPage({}) {
  return (
    <Dashboard>
      <PropertyMap page="search" />
    </Dashboard>
  );
}
