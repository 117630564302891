import {
  setAvailableAreas,
  setAreaSelectionMode,
  setSelectedAreas,
  toggleSelectedArea,
} from "actions/mapActions";
import { deleteGeoboundary } from "api/admin";
import ConfirmModal from "components/ui/Modal/ConfirmModal";
import { deleteMapObject } from "db/mapStore";
import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "sonner";
import {
  clearDrawnHoverDistricts,
  clearDrawnSelectedDistricts,
  onHoverOverDistrict,
} from "utils/map";
import { normalizeString } from "../../utils/helpers";
import "./AreaSelectionPanel.scss";
import AreaSelectionPanelContent from "./AreaSelectionPanelContent";
import closeIcon from "assets/core/close_white.svg";
import { MAX_ALLOWED_AREAS } from "config/constants";

const AreaSelectionPanel = (props) => {
  const { areas, isActive, selectedAreas } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [areaActiveIndex, setAreaActiveIndex] = useState(null);

  useEffect(() => {
    if (props.isActive) {
      document.querySelector("#search-districts-input").focus();
    }
  }, [props.isActive]);

  useEffect(() => {
    window.removeEventListener("keydown", onKeyDown);
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const handleCheckboxChange = (area) => {
    if (
      selectedAreas.length == MAX_ALLOWED_AREAS &&
      !selectedAreas.find((a) => a.id == area.id)
    ) {
      toast.error(i18n("Max number of areas reached"));
    } else {
      props.dispatch(toggleSelectedArea(area));
    }
  };

  const getMunicipality = () => {
    if (props.areas.length === 1 && props.areas[0].type == "municipality") {
      return props.areas[0].name;
    }

    return props.areas.length > 1 && props.areas[1].municipality;
  };

  const handleAreasSelected = (apply) => {
    clearDrawnHoverDistricts();
    clearDrawnSelectedDistricts();

    const { selectedAreas } = props;
    props.dispatch(setSelectedAreas([]));
    props.dispatch(setAreaSelectionMode(null));
    props.dispatch(setAvailableAreas([]));

    if (apply) {
      props.handleAreasSelected(selectedAreas);
    } else {
      props.handleAreasSelected([]);
    }

    // reset panel
    setTimeout(() => {
      setSearchTerm("");

      // scroll to top
      let scrollPlane = document.querySelector(
        ".district-selection-panel-content",
      );
      if (scrollPlane) {
        scrollPlane.scrollTo(0, 0);
      }
    }, 1000);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // if the serch term has a space, check against whole district name
  // if the search term is a compound name, split it into parts
  // and check against each part of the district name
  const filteredAreas = areas.filter((area) =>
    searchTerm.includes(" ")
      ? normalizeString(area.name).includes(searchTerm.toLocaleLowerCase())
      : normalizeString(area.name)
          .split(/[\s-]+/) // split by space or hyphen
          .some((word) => word.startsWith(searchTerm.toLocaleLowerCase())),
  );

  const onAreaHover = (area) => {
    onHoverOverDistrict(area);
  };

  // admin function to delete districts from system
  const deleteAreas = async () => {
    for (let area of selectedAreas) {
      await deleteGeoboundary(area.id);
      await deleteMapObject(area.id, "es", area.type);
    }

    let deletedIds = selectedAreas.map((a) => a.id);
    props.dispatch(
      setAvailableAreas(areas.filter((a) => !deletedIds.includes(a.id))),
    );

    props.dispatch(setSelectedAreas([]));
    setDeleteModalOpen(false);
    toast.success(i18n("Areas deleted successfully"));
  };

  const onKeyDown = (e) => {
    if (!isActive) {
      return;
    }

    const areaElements = document.querySelectorAll(".district-cb-container");
    const areaActiveElement = document.querySelector(
      ".district-cb-container.active",
    );
    const index = Array.from(areaElements).indexOf(areaActiveElement);

    if (e.key === "ArrowDown") {
      if (index === Array.from(areaElements).length - 1) {
        setAreaActiveIndex(0);
        return;
      }

      setAreaActiveIndex(areaActiveElement ? index + 1 : 0);
    }

    if (e.key === "ArrowUp" && areaActiveElement) {
      setAreaActiveIndex(index === 0 ? index : index - 1);
    }

    if (e.key === "Enter" && areaActiveElement) {
      const areaSelected = filteredAreas.find(
        (area) => area.id === areaActiveElement.getAttribute("data-id"),
      );

      handleCheckboxChange(areaSelected);
    }

    if (e.key === "Escape") {
      handleAreasSelected(false);
      window.removeEventListener("keydown", onKeyDown);
    }

    if (e.metaKey && e.key === "Enter") {
      handleAreasSelected(true);
    }
  };

  const getTitle = () => {
    if (props.areas.filter((a) => a.type == "municipality").length > 1) {
      return i18n("Areas Extracted");
    }

    return i18n("Areas in") + " " + getMunicipality();
  };

  return (
    <>
      <div
        className={`district-selection-panel fade-in ${isActive ? "active" : ""}`}
      >
        <div className="district-selection-panel-header">
          <h3>{getTitle()}</h3>
          {/* <p className="mt-4">
            {i18n("Select districts in")} {getMunicipality()}{" "}
            {i18n("to search (max 10).")}
          </p>
          <p className="mb-4">{i18n("Or draw your own area.")}</p> */}
          <div className="search-input">
            <input
              id="search-districts-input"
              type="text"
              placeholder={i18n("Search Areas")}
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={(e) => isActive && onKeyDown(e)}
              className="mt-3"
            />
          </div>
        </div>
        <AreaSelectionPanelContent
          filteredAreas={filteredAreas}
          selectedAreas={selectedAreas}
          onAreaHover={onAreaHover}
          isActive={isActive}
          handleCheckboxChange={(area) => handleCheckboxChange(area)}
          areaActiveIndex={areaActiveIndex}
          setAreaActiveIndex={setAreaActiveIndex}
        />
        <div className="district-selection-panel-footer">
          {selectedAreas.length >= 1 && (
            <div className="district-selection-panel-footer-container">
              <p>
                {i18n("Selected areas")} {selectedAreas.length}/
                {MAX_ALLOWED_AREAS}
              </p>
              <div className="selected-districts">
                {selectedAreas.map((area) => {
                  return (
                    <div key={area.id} className="selected-district">
                      <span>{area.name}</span>
                      <img
                        onClick={() => handleCheckboxChange(area)}
                        src={closeIcon}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="district-selection-panel-footer-bt">
            <div
              className="primary-btn borderless-btn"
              onClick={() => {
                handleAreasSelected(false);
              }}
            >
              {i18n("Cancel")}
            </div>
            {props.permissions.delete_polygon && (
              <div
                className="primary-btn primary-btn-hover"
                onClick={() => setDeleteModalOpen(true)}
              >
                {i18n("Delete")}
              </div>
            )}
            <div
              className="primary-btn primary-btn-hover"
              onClick={() => {
                handleAreasSelected(true);
              }}
            >
              {i18n("Apply")}
            </div>
          </div>
        </div>

        <ConfirmModal
          open={deleteModalOpen}
          title={i18n("Delete districts")}
          confirmationText={i18n(
            "Are you sure you want to delete these districts?",
          )}
          onCancel={() => setDeleteModalOpen(false)}
          onConfirm={() => deleteAreas()}
        />
      </div>
    </>
  );
};

export default connect((state) => ({
  selectedAreas: state.map.selectedAreas,
  areas: state.map.availableAreas,
  isActive: state.map.areaSelectionMode,
  permissions: state.user.userData.permissions,
}))(AreaSelectionPanel);
