import { useNavigate, useParams } from "react-router-dom";

/**
 * Wrapper class that injects react router params into
 * class components
 */
const withRouter = (WrappedComponent) => (props) => {
  const urlParams = useParams();
  const navigate = useNavigate();

  return <WrappedComponent {...props} {...{ urlParams }} navigate={navigate} />;
};

export default withRouter;
