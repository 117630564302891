import { createReducer } from "@reduxjs/toolkit";
import { setSubscription, setUser } from "../actions/userActions";

const initialState = {
  user: null,
  subscription: {
    data: null,
    subscriptionDataFetched: false,
  },
};

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUser, (state, action) => {
      state.user = action.payload;
    })
    .addCase(setSubscription, (state, action) => {
      state.subscription = action.payload;
    });
});

export default authReducer;
