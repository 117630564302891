import fireApiRequest from "./core";

var VALUATE_PROPERTY_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/valuate_property_v2";
var SUMMARY_VALUATION_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/valuation_summary";
var GET_CATASTRAL_BY_REF_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_catastral_by_ref";
var GET_CATASTRAL_BY_COORDS_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_catastral_by_coords";
var GET_PROPERTY_DISTRICT_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_property_district";

if (process.env.REACT_APP_NODE_ENV === "production") {
  VALUATE_PROPERTY_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/valuate_property_v2";
  SUMMARY_VALUATION_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/valuation_summary";
  GET_CATASTRAL_BY_REF_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_catastral_by_ref";
  GET_CATASTRAL_BY_COORDS_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_catastral_by_coords";
  GET_PROPERTY_DISTRICT_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_property_district";
}

export async function valuatePropertyRequest(
  property,
  refProperties,
  catastroRef,
) {
  return await fireApiRequest(VALUATE_PROPERTY_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      property,
      ref_properties: refProperties,
      catastro_ref: catastroRef,
    }),
  });
}

export async function propertyValuationSummaryRequest(
  valuation,
  propertyObject,
  relevantProperties,
  lang = "en",
) {
  try {
    let data = await fireApiRequest(SUMMARY_VALUATION_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        property_valuation: valuation,
        property_obj: propertyObject,
        relevant_properties: relevantProperties,
        lang,
      }),
    });

    return data;
  } catch (e) {
    console.log(e);
  }

  return null;
}

export async function getCatastralByCoords(lat, lng) {
  try {
    let data = await fireApiRequest(GET_CATASTRAL_BY_COORDS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        latitude: lat,
        longitude: lng,
      }),
    });

    return data;
  } catch (e) {
    console.log(e);
  }

  return null;
}

// Catastral API through our proxy (which fetches more info)
export async function getCatastralByRef(ref) {
  try {
    let data = await fireApiRequest(GET_CATASTRAL_BY_REF_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        ref,
      }),
    });

    return data;
  } catch (e) {
    console.log(e);
  }

  return null;
}

// Direct Catastral API
export async function getCatastralByRefDirect(ref) {
  console.log(ref);
  let response = await fetch(
    `https://ovc.catastro.meh.es/OVCServWeb/OVCWcfCallejero/COVCCallejero.svc/json/Consulta_DNPRC?RefCat=${ref}`,
  );
  let data = await response.json();

  // single ref
  if (data.consulta_dnprcResult.bico) {
    return {
      ...data.consulta_dnprcResult.bico.bi,
      lspr: data.consulta_dnprcResult.bico.lspr,
    };
  }

  // multi ref
  return data.consulta_dnprcResult.lrcdnp.rcdnp;
}

export async function getPropertyDistrict(property) {
  try {
    let data = await fireApiRequest(GET_PROPERTY_DISTRICT_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        property,
      }),
    });

    return data;
  } catch (e) {
    console.log(e);
  }

  return null;
}
