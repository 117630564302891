import searchIcon from "assets/search/search_icon.svg";
import { useState } from "react";
import "./searchInput.scss";

export default function SearchInput(props) {
  const [searchValue, setSearchValue] = useState("");

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      props.onEnter(searchValue);
    }
  };

  return (
    <div className="generic-search-input">
      <div className="generic-search-input_container">
        <div className="generic-search-input_container_search-input">
          <img src={searchIcon} alt="" />
          <input
            value={searchValue}
            type="text"
            onKeyDown={(e) => onKeyDown(e)}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={props.placeholder}
          />
        </div>
      </div>
    </div>
  );
}
