import { i18n } from "i18n/localisation";

export default function StatisticsCard({ statistics, title }) {
  return (
    <div className="chart-card details-card">
      <div className="details-card_contents">
        <p className="details-card_title">{title || i18n("Statistics")}</p>
        <div className="horizontal-divider"></div>
        {statistics && (
          <div className="details-card_statistics">
            {statistics.map((stat, index) => (
              <div key={index} className="details-card_statistics_item">
                <p className="details-card_statistics_item_label">
                  {stat.label}
                </p>
                <p
                  className={
                    "details-card_statistics_item_value" +
                    (stat.positive
                      ? " positive"
                      : stat.negative
                        ? " negative"
                        : "")
                  }
                >
                  {stat.value}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
