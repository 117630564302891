import { useContext, useState } from "react";
import { CollectionsContext } from "../../../context/CollectionsContext";
import Button from "../../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { i18n } from "i18n/localisation";

const CollectionDeleteModal = ({ onSubmit, onCancel }) => {
  const { isDeleteModalOpen, closeDeleteModal } =
    useContext(CollectionsContext);

  const handleOnSubmit = () => {
    // pass the current collection to delete back to the context
    onSubmit(isDeleteModalOpen);
  };

  return (
    <Dialog
      open={isDeleteModalOpen}
      onOpenChange={(open) => {
        closeDeleteModal();
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Delete collection")}</DialogTitle>
        </DialogHeader>
        <div className="p-4">
          <p className="mb-2 text-sm font-medium text-gray-700">
            {i18n("Are you sure you want to delete the collection:")}
          </p>
          {isDeleteModalOpen && <p>'{isDeleteModalOpen.title}'?</p>}
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="text" onClick={() => closeDeleteModal()}>
              {i18n("Cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={() => handleOnSubmit()}
              className="ml-auto"
            >
              {i18n("Yes, delete")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CollectionDeleteModal;
