import { createReducer } from "@reduxjs/toolkit";
import {
  addProperties,
  deleteProperties,
  setFetchingProperties,
  setProperties,
  updateProperty,
  setFilteredProperties,
} from "../actions/propertiesActions";
import { filterProperties } from "lib/filter/filters";

const initialState = {
  properties: [],
  filteredProperties: [],
  fetchingProperties: false,
};

const propertyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setProperties, (state, action) => {
      state.properties = action.payload;
    })
    .addCase(addProperties, (state, action) => {
      state.properties = [].concat(state.properties, action.payload);
    })
    .addCase(deleteProperties, (state, action) => {
      const payloadIds = new Set(action.payload.map((p) => p.id));
      state.properties = state.properties.filter((p) => !payloadIds.has(p.id));
      state.filteredProperties = state.filteredProperties.filter(
        (p) => !payloadIds.has(p.id),
      );
    })
    .addCase(setFetchingProperties, (state, action) => {
      state.fetchingProperties = action.payload;
    })
    .addCase(updateProperty, (state, action) => {
      state.properties = state.properties.map((p) =>
        p.id === action.payload.id ? action.payload : p,
      );
    })
    .addCase(setFilteredProperties, (state, action) => {
      state.filteredProperties = action.payload;
    });
});

export default propertyReducer;
