import { getLang, i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics"; // Add this line
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { fetchPropertyRequest } from "../../../api/properties";
import aiChatIcon from "../../../assets/property/ai_chat.svg";
import whiteArrow from "../../../assets/property/white_arrow.svg";
import { CollectionsContext } from "../../../context/CollectionsContext";
import {
  fetchCachedProperties,
  fetchPropertiesByPolygon,
  getPropertyGeoboundary,
  getPropertyTitle,
} from "../../../utils/properties";
import Dashboard from "../../Dashboard";
import AdvertisersCommentsCard from "./AdvertisersCommentsCard";
import ContactFormWidget from "./contactFormWidget";
import ListingsWidget from "./ListingsWidget";
import LocationCard from "./LocationCard";
import MainPropertyDetailsCard from "./MainPropertyDetailsCard";
import PropertyChat from "./PropertyChat";
import "./propertyDetails.scss";
import PropertyDetailsHeader from "./PropertyDetailsHeader";
import ReportModal from "./ReportModal";
import { geoJsonFeatureToPolygon } from "utils/polygon";
import ChartCard from "./ChartCard";
import { statisticsPlugin } from "utils/stats";
import StatisticsCard from "./StatisticsCard";
import { getPriceDistributionData } from "utils/stats";

const PropertyDetails = ({ urlParams, collections }) => {
  const [property, setProperty] = useState(null);
  const [duplicates, setDuplicates] = useState([]);
  const [aiChatOpen, setAiChatOpen] = useState(false);
  const [isDeletedProperty, setIsDeletedProperty] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [aiChatPopupOpen, setAiChatPopupOpen] = useState(
    !localStorage.getItem("AI_POPUP_COLLAPSED"),
  );
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [tabTitle, setTabTitle] = useState(null);
  const [priceDistributionData, setPriceDistributionData] = useState(null);
  const [propertyDistrictName, setPropertyDistrictName] = useState(null);

  const { propertyId } = useParams();
  const location = useLocation();

  const { openAddToCollectionModal } = useContext(CollectionsContext);

  useEffect(() => {
    let saleType = "sale";
    if (location.pathname.includes("/rental/")) {
      saleType = "rent";
    }

    fetchPropertyRequest(propertyId, saleType).then(async (response) => {
      if (response.property) {
        setProperty(response.property);
        setDuplicates(response.duplicates);
        setIsDeletedProperty(response.is_deleted_property);
        setTabTitle(getPropertyTitle(response.property));

        // get price distribution data
        const propertyDistrict = await getPropertyGeoboundary(
          response.property,
        );
        const priceDistributionData = await getPriceDistributionData(
          response.property,
        );
        setPriceDistributionData(priceDistributionData);

        // get property district name
        if (propertyDistrict.type === "district") {
          setPropertyDistrictName(
            propertyDistrict.name + ", " + propertyDistrict.municipality,
          );
        } else {
          setPropertyDistrictName(propertyDistrict.name);
        }
      }
    });
  }, [propertyId]);

  useEffect(() => {
    if (aiChatOpen) {
      const onKeyDown = (e) => {
        if (e.key === "Escape") {
          document.querySelector(".property-chat").classList.add("slide-out");
          setTimeout(() => {
            setAiChatOpen(false);
          }, 200);
          window.removeEventListener("keydown", onKeyDown);
        }
      };

      window.addEventListener("keydown", onKeyDown);
    }
  }, [aiChatOpen]);

  setTimeout(() => {
    window.onscroll = () => {
      const navbarElement = document.querySelector(".navbar-mobile");
      const fixedHeaderElement = document.querySelector(".mobile");

      if (
        document.querySelector(".details-card").getBoundingClientRect()
          .bottom <= navbarElement.getBoundingClientRect().bottom
      ) {
        navbarElement.classList.add("hidden");
        fixedHeaderElement?.classList.remove("hidden");
      } else {
        navbarElement.classList.remove("hidden");
        fixedHeaderElement?.classList.add("hidden");
      }
    };
  }, 100);

  const onLikeProperty = (e) => {
    e.preventDefault();
    e.stopPropagation();

    openAddToCollectionModal({ ...property, objectType: "property" });
    sendAnalyticsEvent("Property Page Click", {
      type: "like",
      details: property,
    });
  };

  const onUnlikeProperty = (e) => {
    e.preventDefault();
    e.stopPropagation();

    openAddToCollectionModal({ ...property, objectType: "property" });
  };

  const toggleAiChat = () => {
    setAiChatOpen(!aiChatOpen);
    localStorage.setItem("AI_POPUP_COLLAPSED", true);
    setAiChatPopupOpen(false);

    // auto focus onto the property chat input
    if (!aiChatOpen) {
      setTimeout(() => {
        document.getElementById("property-chat-input").focus();
      }, 100);
    }
  };

  // fill property with any data that its missing from the duplicates
  const saturateProperty = () => {
    if (duplicates.length > 0) {
      for (let duplicate of duplicates) {
        if (
          duplicate.multimedia.videos.length > property.multimedia.videos.length
        ) {
          property.multimedia.videos = duplicate.multimedia.videos;
        }

        if (
          duplicate.multimedia.virtualTour &&
          !property.multimedia.virtualTour
        ) {
          property.multimedia.virtualTour = duplicate.multimedia.virtualTour;
        }

        if (
          duplicate.multimedia.plans.length > property.multimedia.plans.length
        ) {
          property.multimedia.plans = duplicate.multimedia.plans;
        }

        for (let key of Object.keys(duplicate.features)) {
          if (!property.features[key]) {
            property.features[key] = duplicate.features[key];
          }
        }

        // only get the property description of dupe if higher AND
        // if the language of the description matches the current language
        if (duplicate.description.length > property.description.length) {
          let { code } = getLang();
          if (code === property.country) {
            property.description = duplicate.description;
          }
        }
      }
    }
  };

  saturateProperty(property);

  return (
    <Dashboard>
      <div className="details-backdrop"></div>
      {property && (
        <div className="property-details">
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
          <PropertyDetailsHeader
            property={property}
            isDeletedProperty={isDeletedProperty}
            onLikeProperty={onLikeProperty}
            onUnlikeProperty={onUnlikeProperty}
            isReportModalOpen={isReportModalOpen}
            setIsReportModalOpen={setIsReportModalOpen}
          />
          <div className="property-details_container">
            <div className="property-details_wrapper">
              <MainPropertyDetailsCard
                property={property}
                onLikeProperty={onLikeProperty}
                onUnlikeProperty={onUnlikeProperty}
                duplicates={duplicates}
              />
              {!property.isPrivateBankProperty && (
                <AdvertisersCommentsCard property={property} />
              )}
              {priceDistributionData && (
                <>
                  <StatisticsCard
                    title={i18n("Statistics") + " " + propertyDistrictName}
                    statistics={priceDistributionData.statistics}
                  />
                  <ChartCard
                    chartType="bar"
                    title={
                      i18n("Price/m² Distribution in") +
                      " " +
                      propertyDistrictName
                    }
                    data={priceDistributionData.chartData}
                    options={priceDistributionData.options}
                    plugins={[statisticsPlugin]}
                  />
                </>
              )}
              <LocationCard property={property} />
            </div>
            <div className="property-details_listing-wrapper">
              {property.isPrivateBankProperty ? (
                <ContactFormWidget property={property} />
              ) : (
                <ListingsWidget property={property} duplicates={duplicates} />
              )}
            </div>
          </div>
          <div className="ai-property-chat-btn">
            {aiChatPopupOpen && (
              <div className="ai-property-chat-btn_popup">
                <img
                  alt="white-arrow"
                  className="ai-property-chat-btn_popup_nibble"
                  src={whiteArrow}
                />
                <div className="ai-property-chat-btn_popup_rect">
                  <span>
                    {i18n("Ask me anything you want about the property!")}
                  </span>
                </div>
              </div>
            )}
            <div onClick={toggleAiChat} className="ai-property-chat-btn_btn">
              <img alt="chat-icon" src={aiChatIcon} />
              <span>{i18n("AI Property Chat")}</span>
            </div>
          </div>
          {aiChatOpen && (
            <PropertyChat
              setMessages={setChatMessages}
              messages={chatMessages}
              onCloseChat={toggleAiChat}
              property={property}
              statistics={priceDistributionData.statistics}
            />
          )}
          <ReportModal
            isOpen={isReportModalOpen}
            closeModal={setIsReportModalOpen}
            propertyId={property.id}
          />
        </div>
      )}
    </Dashboard>
  );
};

export default connect((state) => {
  return {
    collections: state.collections,
    user: state.user,
  };
})(PropertyDetails);
