import { sendUserReport } from "api/userReports";
import Checkbox from "components/input/Checkbox";
import { i18n } from "i18n/localisation";
import { useState } from "react";
import { toast } from "sonner";
import Button from "../../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";

const REPORT_REASONS_MAPPING = [
  {
    key: "duplicate",
    label: "It's a duplicate",
  },
  {
    key: "wrong_price",
    label: "Wrong price",
  },
  {
    key: "wrong_location",
    label: "Wrong location",
  },
  {
    key: "other",
    label: "Other",
  },
];

const submitReport = async (objectId, reason, comments, duplicateURLs) => {
  const duplicateURLsArray = duplicateURLs.replace(/\s+/g, "").split(",");

  await sendUserReport({
    objectId,
    reason,
    comments,
    duplicateURLsArray,
  });
};

const ReportModal = ({ isOpen, closeModal, propertyId, ...props }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [duplicateURLs, setDuplicateURLs] = useState("");
  const [comments, setComments] = useState("");

  const handleOnSubmit = async () => {
    closeModal();
    await submitReport(propertyId, selectedReason, comments, duplicateURLs);
    toast.success(i18n("Property reported"));
  };

  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Report Property")}</DialogTitle>
        </DialogHeader>
        <div className="report-modal_subtitle">
          <p>
            {i18n(
              "Select the reason for reporting this property. iRealty will review your request and amend the property information accordingly.",
            )}
          </p>
        </div>
        <div className="report-modal_reasons-content">
          {REPORT_REASONS_MAPPING.map((reason) => (
            <div
              key={reason.key}
              className="report-modal_reasons-content_reason"
              onClick={() => setSelectedReason(reason.key)}
            >
              <Checkbox
                label={i18n(reason.label)}
                checked={selectedReason === reason.key}
              />
            </div>
          ))}
        </div>
        <div className="report-modal_text-input-content">
          {selectedReason === "duplicate" && (
            <div className="px-4">
              <p className="report-modal_text-input-content_duplicate-url-label-input mb-2 text-sm font-medium text-gray-700">
                {i18n("Duplicate URL's")}
                <span>({i18n("Optional")})</span>
              </p>
              <input
                type="text"
                className="w-full rounded-lg border border-gray-300 p-2"
                value={duplicateURLs}
                placeholder={i18n(
                  "Paste duplicate URL's, separated by commas for multiple",
                )}
                onChange={(e) => setDuplicateURLs(e.target.value)}
                maxLength={300}
              />
            </div>
          )}
          <div className="px-4">
            <p className="mb-2 text-sm font-medium text-gray-700">
              {i18n("Comments")}
            </p>
            <input
              type="text"
              className="w-full rounded-lg border border-gray-300 p-2"
              value={comments}
              placeholder={i18n("Add any necessary comments")}
              onChange={(e) => setComments(e.target.value)}
              maxLength={100}
            />
            <p className="mt-2 text-right text-sm text-gray-500">
              {comments.length}/100 {i18n("characters")}
            </p>
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button
              variant={selectedReason ? "primary" : "secondary"}
              disabled={!selectedReason}
              onClick={() => handleOnSubmit()}
              className="ml-auto"
            >
              {i18n("Submit")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ReportModal;
