import { createReducer } from "@reduxjs/toolkit";
import { setCmaProperty, setIsPDFGenerated } from "../actions/cmaActions";

const initialState = {
  cmaProperty: null,
  isPDFGenerated: false,
};

const cmaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCmaProperty, (state, action) => {
      state.cmaProperty = action.payload;
    })
    .addCase(setIsPDFGenerated, (state, action) => {
      state.isPDFGenerated = action.payload;
    });
});

export default cmaReducer;
