import { createAction } from "@reduxjs/toolkit";

const setDrawnPolygons = createAction("polygon/setDrawnPolygons");
const setSelectedPolygons = createAction("polygon/setSelectedPolygons");
const incrementPolygonColourCode = createAction(
  "polygon/incrementPolygonColour",
);
const incrementNumberOfPolygonsFetched = createAction(
  "polygon/incrementNumberOfPolygonsFetched",
);

export {
  incrementNumberOfPolygonsFetched,
  incrementPolygonColourCode,
  setDrawnPolygons,
  setSelectedPolygons,
};
