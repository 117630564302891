import { createReducer } from "@reduxjs/toolkit";
import {
  setNotifications,
  setNotificationsOpen,
} from "actions/notificationsActions";

const initialState = {
  notifications: [],
  notificationsOpen: false,
};

const notificationsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setNotifications, (state, action) => {
    state.notifications = action.payload;
  });
  builder.addCase(setNotificationsOpen, (state, action) => {
    state.notificationsOpen = action.payload;
  });
});

export default notificationsReducer;
