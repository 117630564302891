import { createReducer } from "@reduxjs/toolkit";
import {
  clearUploadProperty,
  setCatastroProperty,
  setListingAgent,
  setListingType,
  setLocationPreference,
  setPropertyType,
} from "actions/myPropertiesActions";

const initialState = {
  catastroProperty: null,
  listingType: null,
  propertyType: null,
  listingAgent: null,
  locationPreference: null,
};

const myPropertiesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCatastroProperty, (state, action) => {
      state.catastroProperty = action.payload;
    })
    .addCase(setListingType, (state, action) => {
      state.listingType = action.payload;
    })
    .addCase(setPropertyType, (state, action) => {
      state.propertyType = action.payload;
    })
    .addCase(setListingAgent, (state, action) => {
      state.listingAgent = action.payload;
    })
    .addCase(setLocationPreference, (state, action) => {
      state.locationPreference = action.payload;
    })
    .addCase(clearUploadProperty, (state, action) => {
      state.catastroProperty = null;
      state.listingType = null;
      state.propertyType = null;
      state.listingAgent = null;
      state.locationPreference = null;
    });
});

export default myPropertiesReducer;
