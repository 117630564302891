export const mapConfig = {
  cma: {
    style: {
      desktop: { width: "calc(100vw - 88px)", height: "100svh", left: "88px" },
      mobile: {
        width: "100vw",
        height: "calc(100svh - 50px)",
        left: "0",
        top: "50px",
        overflow: "hidden",
      },
    },
    center: {
      desktop: { lat: 39.4168, lng: 0 },
      mobile: { lat: 39.4168, lng: 0 },
    },
    zoom: {
      desktop: 6,
      mobile: 5,
    },
  },
  myProperties: {
    style: {
      desktop: { width: "100%", height: "360px" },
      mobile: { width: "100%", height: "360px", top: 0 },
    },
    center: {
      desktop: { lat: 40.4168, lng: -3.7038 },
      mobile: { lat: 40.4168, lng: -3.7038 },
    },
    zoom: {
      desktop: 5,
      mobile: 5,
    },
  },
  search: {
    style: {
      desktop: { width: "calc(100vw - 88px)", height: "100svh", left: "88px" },
      mobile: {
        width: "100vw",
        height: "calc(100svh - 50px)",
        left: "0",
        top: "50px",
        overflow: "hidden",
      },
    },
    center: {
      desktop: { lat: 39.4168, lng: -3.7038 },
      mobile: { lat: 39.4168, lng: -3.7038 },
    },
    zoom: {
      desktop: 6,
      mobile: 5,
    },
  },
};

// set the maps center to the user's location
export function setDefaultCenter(map, page) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude + (page === "cma" ? 0.05 : 0),
        };

        // Set the map's center to the current location
        map.setCenter(pos);
        map.setZoom(12);
      },
      null,
      {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: Infinity,
      },
    );
  }
}

export const polygonColourLadder = [
  "#FFFFFF",
  "#EE3943",
  "#7CB3FF",
  "#7CF081",
  "#FFDF33",
  "#C384FE",
  "#FFBA3C",
  "#7AEEEE",
  "#FF75FF",
];

export const polygonColours = {
  primary: "white",
  hover: "#ee3943",
  selected: "yellow",
  active: "#FF7A7A",
  fillOpacity: 0.1,
};

export const mapStyles = [
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.government",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.medical",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e6e56",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.place_of_worship",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.school",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#696969",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e6e56",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {},
];

export const roadMapStyles = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.country",
    elementType: "geometry.stroke",
    stylers: [{ visibility: "on" }, { color: "#FFFFFF" }, { weight: 1 }],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];
