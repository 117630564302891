import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { unwatermarkImg } from "utils/helpers";
import leftChevronIcon from "../../assets/core/left_chevron.svg";
import rightChevronIcon from "../../assets/core/right_chevron.svg";
import FullscreenButton from "./FullscreenButton";

const VISIBLE_DOTS = 5;

const MediaCarousel = ({
  images,
  videos,
  hideArrows,
  hideDots,
  lazyLoad,
  onOpenFullscreen,
  hideFullscreenButton,
  allowArrowNavigation = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const prevProps = useRef({ images: [], videos: [] });

  const minSwipeDistance = 50;

  // swipe navigation
  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      isLeftSwipe ? nextImage() : prevImage();
    }
  };

  // arrow navigation
  const onKeyPress = (e) => {
    if (e.key === "ArrowRight") {
      nextImage(e);
    } else if (e.key === "ArrowLeft") {
      prevImage(e);
    }
  };

  // reset index on media change
  useEffect(() => {
    const { images: prevImages, videos: prevVideos } = prevProps.current;

    if (
      (images && images.length !== prevImages.length) ||
      (videos && videos.length !== prevVideos.length)
    ) {
      setCurrentIndex(0);
    }

    prevProps.current = { images: images || [], videos: videos || [] };
  }, [images, videos]);

  // allow arrow navigation
  useEffect(() => {
    if (!allowArrowNavigation) return;
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  });

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  /**
   * Generally renders 5 dots on the screen that represent the image carousel indices
   */
  const renderDots = () => {
    const mediaArray = images || videos;
    const totalPages = Math.ceil(mediaArray.length / VISIBLE_DOTS); // Total number of pages
    const currentPage = Math.floor(currentIndex / VISIBLE_DOTS) + 1; // Current page index

    // Determine the number of dots to render
    let remainingDots =
      totalPages - currentPage >= 1
        ? VISIBLE_DOTS
        : mediaArray.length % VISIBLE_DOTS;

    // Calculate the active dot index
    let activeDotIndex = currentIndex % VISIBLE_DOTS;

    // If it's the last page and there are fewer than VISIBLE_DOTS images remaining
    if (
      currentPage === totalPages &&
      mediaArray.length % VISIBLE_DOTS < VISIBLE_DOTS
    ) {
      remainingDots = mediaArray.length % VISIBLE_DOTS;
      if (remainingDots === 0) remainingDots = VISIBLE_DOTS;
    }

    // Render the dots
    return Array.from({ length: remainingDots }, (_, index) => {
      return (
        <div
          key={index}
          className="dot-wrapper"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDotClick((currentPage - 1) * VISIBLE_DOTS + index);
          }}
        >
          <div className={`dot ${activeDotIndex === index ? "active" : ""}`} />
        </div>
      );
    });
  };

  const nextImage = (e) => {
    if (!allowArrowNavigation) {
      e.preventDefault();
      e.stopPropagation();
    }

    let mediaArray = images || videos;
    let nextIndex = Math.min(currentIndex + 1, mediaArray.length - 1);
    setCurrentIndex(nextIndex);
  };

  const prevImage = (e) => {
    if (!allowArrowNavigation) {
      e.preventDefault();
      e.stopPropagation();
    }

    let prevIndex = Math.max(0, currentIndex - 1);
    setCurrentIndex(prevIndex);
  };

  const handleImageLoad = (event) => {
    event.target.classList.add("loaded");
  };

  // renders images if images are passed
  // or videos if videos are passed
  const renderMedia = () => {
    // lazy load mode should trigger the lazy load image component
    if (images && images.length > 0 && lazyLoad) {
      return images.map((image, index) => {
        const isVisible =
          index === currentIndex ||
          index === currentIndex + 1 ||
          index === currentIndex - 1;

        return (
          <LazyLoadImage
            key={index}
            src={isVisible ? unwatermarkImg(image) : undefined}
            alt={`slide-${index}`}
            className="carousel-image"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            threshold={750}
            onLoad={handleImageLoad}
          />
        );
      });
    }

    // non lazy load mode
    if (images && images.length > 0) {
      return images.map((image, index) => (
        <img
          key={index}
          src={unwatermarkImg(image)}
          alt={`slide-${index}`}
          className="carousel-image"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          onLoad={handleImageLoad}
          decoding="async"
        />
      ));
    }

    if (videos && videos.length > 0) {
      return videos.map((video, index) => (
        <video
          key={index}
          poster={video.poster}
          src={video.url}
          controls={true}
          className="carousel-image"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        />
      ));
    }
  };

  return (
    <div className="image-carousel">
      {((images && images.length > 1) || (videos && videos.length > 1)) &&
        !hideArrows && (
          <div className="carousel-control-container">
            <div onClick={prevImage} className="carousel-control">
              <img src={leftChevronIcon} />
            </div>
            <div
              onClick={nextImage}
              className="carousel-control carousel-control-right"
            >
              <img src={rightChevronIcon} />
            </div>
          </div>
        )}
      <div
        className="carousel-container"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {renderMedia()}
      </div>
      {!hideFullscreenButton && (
        <FullscreenButton onOpenFullscreen={() => onOpenFullscreen()} />
      )}
      {!hideDots && <div className="dot-container">{renderDots()}</div>}
    </div>
  );
};

export default React.memo(MediaCarousel);
