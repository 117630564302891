import Button from "components/ui/Button/Button";
import { i18n } from "i18n/localisation";
import { connect } from "react-redux";
import closeIcon from "assets/core/close.svg";
import notAllowedIcon from "assets/core/not_allowed.svg";
import { canAddUser, getTotalSeats, getUsedSeats } from "utils/userHelpers";
import InviteMemberModal from "./InviteMemberModal";
import { useState } from "react";
import { toast } from "sonner";
import ConfirmModal from "components/ui/Modal/ConfirmModal";
import { deleteUserFromTeam } from "api/team";
import { fetchUsersForTeam } from "actions/teamActions";

function SettingsTeamContent(props) {
  const { users } = props.team;
  const [inviteMemberModalOpen, setInviteMemberModalOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);

  const renderStatus = (status) => {
    if (status == "pending") {
      return <span className="invitation-pending">{i18n("Pending")}</span>;
    }

    return <span className="invitation-accepted">{i18n("Accepted")}</span>;
  };

  const openInviteMemberModal = () => {
    if (canAddUser()) {
      setInviteMemberModalOpen(true);
    } else {
      toast.error(i18n("You have reached the maximum number of seats"));
    }
  };

  const onRemoveUser = (user) => {
    setUserToRemove(null);

    let promise = new Promise(async (resolve, reject) => {
      try {
        await deleteUserFromTeam(user.id);
        props.dispatch(fetchUsersForTeam(props.user.teamId));
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    toast.promise(promise, {
      loading: i18n("Removing user"),
      success: i18n("User removed"),
      error: i18n("Failed to remove user"),
    });
  };

  const renderUser = (user) => {
    return (
      <>
        <div className="settings_tabs_content_body_row" key={user.id}>
          <div className="settings_tabs_content_body_col">
            <span>{user.email}</span>
          </div>
          <div className="settings_tabs_content_body_col">
            {renderStatus(user.invitationStatus)}
          </div>
          <div className="settings_tabs_content_body_col">
            {user.id === props.auth.user.sub ? (
              <div className="remove-team-user-btn not-allowed">
                <img src={notAllowedIcon} alt="delete" />
              </div>
            ) : (
              <div
                onClick={() => setUserToRemove(user)}
                className="remove-team-user-btn"
              >
                <img src={closeIcon} alt="delete" />
              </div>
            )}
          </div>
        </div>
        <div className="divider"></div>
      </>
    );
  };

  return (
    <div className="settings_tabs_content">
      <div className="settings_tabs_content_header">
        <div className="settings_tabs_content_header_row">
          <div className="settings_tabs_content_header_col team">
            <h2>{i18n("My team")}</h2>
          </div>
          <div className="settings_tabs_content_header_col team">
            <span>
              {getUsedSeats()}/{getTotalSeats()} {i18n("seats")}
            </span>
            <Button variant="secondary" onClick={() => openInviteMemberModal()}>
              {i18n("Invite team member")}
            </Button>
          </div>
        </div>
        <div className="divider"></div>
      </div>
      <div className="settings_tabs_content_body team">
        <div className="settings_tabs_content_body_row">
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("Email")}</span>
          </div>
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("Status")}</span>
          </div>
          <div className="settings_tabs_content_body_col">
            <span className="setting-heading">{i18n("Remove")}</span>
          </div>
        </div>
        <div className="divider"></div>
        {users.map((user) => renderUser(user))}
      </div>

      <InviteMemberModal
        open={inviteMemberModalOpen}
        teamId={props.user.teamId}
        closeModal={() => setInviteMemberModalOpen(false)}
      />

      <ConfirmModal
        open={!!userToRemove}
        title={i18n("Remove team member")}
        confirmationText={
          i18n("Are you sure you want to remove") +
          " " +
          userToRemove?.email +
          "?"
        }
        confirmText={i18n("Remove")}
        cancelText={i18n("Cancel")}
        onConfirm={() => {
          onRemoveUser(userToRemove);
        }}
        onCancel={() => setUserToRemove(null)}
      />
    </div>
  );
}

export default connect((state) => ({
  team: state.team,
  user: state.user.userData,
  auth: state.auth,
}))(SettingsTeamContent);
