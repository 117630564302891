import { i18n } from "i18n/localisation";
import checkIcon from "assets/core/check_red.svg";
import "./login.scss";
import searchIcon from "assets/login/login_search.svg";
import cmaIcon from "assets/login/login_cma.svg";
import houseIcon from "assets/login/login_house.svg";
import pdfIcon from "assets/login/login_pdf.svg";

const LoginBlurb = () => {
  return (
    <div className="login-blurb">
      <h1>
        {i18n("Create your")} <span>{i18n("free account")}</span>
        <br /> {i18n("in")} <span>{i18n("10 seconds")}</span> {i18n("today")}.
      </h1>
      <div className="login-blurb_container">
        <p className="login-blurb_subheading">
          {i18n(
            "Get access to industry leading tools to boost your efficiency",
          )}
          <br />
          <span>{i18n("6x faster than our competition.")}</span>
        </p>

        <div className="login-blurb_benefits">
          <div className="login-blurb_benefits_benefit">
            <img src={houseIcon} />
            <div className="login-blurb_benefits_benefit_text">
              <h2>{i18n("Real Estate Data")}</h2>
              <span>{i18n("Access to Spain's largest databases")}</span>
            </div>
          </div>

          <div className="login-blurb_benefits_benefit">
            <img src={searchIcon} />
            <div className="login-blurb_benefits_benefit_text">
              <h2>{i18n("AI Assistant")}</h2>
              <span>{i18n("Find exactly what you need")}</span>
            </div>
          </div>

          <div className="login-blurb_benefits_benefit">
            <img src={cmaIcon} />
            <div className="login-blurb_benefits_benefit_text">
              <h2>{i18n("Professional Valuation")}</h2>
              <span>{i18n("Integrated with Catastro")}</span>
            </div>
          </div>

          <div className="login-blurb_benefits_benefit">
            <img src={pdfIcon} />
            <div className="login-blurb_benefits_benefit_text">
              <h2>{i18n("Generate WC")}</h2>
              <span>{i18n("Generate PDF property reports")}</span>
            </div>
          </div>
        </div>

        {/* <p className="login-blurb_footer-text">
          {i18n(
            "Plus, all of the features you need to get the job done right.",
          )}
        </p> */}

        <div className="login-blurb_more-features">
          <div className="login-blurb_more-features_feature">
            <img src={checkIcon} />
            <span>{i18n("Saved Searches")}</span>
          </div>

          <div className="login-blurb_more-features_feature">
            <img src={checkIcon} />
            <span>{i18n("Notifications")}</span>
          </div>

          <div className="login-blurb_more-features_feature">
            <img src={checkIcon} />
            <span>{i18n("Client Folders")}</span>
          </div>

          <div className="login-blurb_more-features_feature">
            <img src={checkIcon} />
            <span>{i18n("Commercial Properties")}</span>
          </div>

          <div className="login-blurb_more-features_feature">
            <img src={checkIcon} />
            <span>{i18n("Land Listings")}</span>
          </div>

          <div className="login-blurb_more-features_feature">
            <img src={checkIcon} />
            <span>{i18n("Bank Properties")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginBlurb;
