import { createAction } from "@reduxjs/toolkit";
import { fetchMapSearchIndexesRequest } from "../api/geoboundaries";

const setMapIndexes = createAction("geo/mapIndexes");
export const MAP_INDEXES_CACHE_KEY = "MAP_INDEXES_CACHE_KEY";

// fetches the map indexes and adds them to the map index array in store
function fetchMapSearchIndexes() {
  return async (dispatch, getState) => {
    try {
      let mapIndexes = await fetchMapSearchIndexesRequest();

      // cache the results with a timestamp (for versioning)
      localStorage.setItem(
        MAP_INDEXES_CACHE_KEY,
        JSON.stringify({
          timestamp: parseInt((new Date().getTime() / 1000).toFixed(0)),
          version: 1,
          indexes: mapIndexes,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };
}

// gets the cached search index database
function getSearchIndexes() {
  let indexes = localStorage.getItem(MAP_INDEXES_CACHE_KEY);

  if (indexes) {
    return JSON.parse(indexes);
  }

  return [];
}

export { setMapIndexes, fetchMapSearchIndexes, getSearchIndexes };
