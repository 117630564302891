import { setHideMapTools } from "actions/mapActions";
import Dropdown from "components/core/Dropdown";
import Button from "components/ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import RadioGroup from "components/ui/RadioGroup/RadioGroup";
import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { formatNumber } from "utils/helpers";
import { setCanDrawGeographicPolygons } from "../../actions/mapActions";
import {
  setListingAgent,
  setListingType,
  setLocationPreference,
  setPropertyType,
} from "../../actions/myPropertiesActions";
import rightChevron from "../../assets/core/right_chevron.svg";
import {
  BUILDING_TYPE_FILTERS,
  FILTER_LABEL_MAPPINGS,
} from "../../lib/filter/filterTypes";
import PropertyMap from "../map/PropertyMap";

const UploadPropertyModal = ({ isOpen, closeModal, ...props }) => {
  const [step, setStep] = useState();
  const dispatch = useDispatch();

  const {
    listingAgent,
    catastroProperty,
    listingType,
    propertyType,
    locationPreference,
  } = props.myProperties;

  useEffect(() => {
    if (!catastroProperty) {
      setStep(1);
    }
  }, [isOpen]);

  const getAgentFullName = (agent) => {
    //if agent firstName is not set, default to email
    if (!agent.personalDetails?.firstName) {
      return agent.email || props.user.user.email;
    }

    return `${agent.personalDetails.firstName} ${agent.personalDetails.lastName}`;
  };

  const isNextButtonDisabled = () => {
    if (step === 1) {
      return !(propertyType && listingType);
    }

    return !locationPreference;
  };

  const renderStepOne = () => {
    return (
      <div className="mt-6 flex max-h-[calc(100svh-200px)] w-full flex-col flex-wrap gap-[24px] overflow-y-auto p-[24px] pt-[0px]">
        <span className="text-base font-normal text-[#717171]">
          {i18n(
            "Select the listing type and property type to get started with your listing",
          )}
        </span>
        <div className="flex flex-col  gap-[16px] sm:flex-row">
          <div className="flex-col">
            <p className="mb-2 text-sm font-medium text-gray-700">
              {i18n("Listing Type")}
            </p>
            <RadioGroup
              items={["Sale", "Rent"]}
              onSelect={(item) => dispatch(setListingType(item))}
              selected={listingType}
            />
          </div>
          <div className="w-full">
            <p className="mb-2 text-sm font-medium text-gray-700">
              {i18n("Property Type")}
            </p>
            <Dropdown
              className="upload-modal-dropdown"
              placeholder={propertyType ? "" : i18n("Select property type")}
              items={BUILDING_TYPE_FILTERS.map((filter, index) => ({
                label: FILTER_LABEL_MAPPINGS[filter.label](),
                value: index,
                onSelect: () => dispatch(setPropertyType(filter)),
              }))}
              label={
                propertyType ? FILTER_LABEL_MAPPINGS[propertyType.label]() : ""
              }
            />
          </div>
        </div>
        <div className="h-[1px] w-full bg-[#E1E4EB]"></div>
        <div className="flex flex-col gap-[12px]">
          <p className="mb-2 text-sm font-medium text-gray-700">
            {i18n("Listing Agent")}
          </p>
          <span className="text-base font-normal text-[#717171]">
            {i18n(
              "Select an agent from your team to manage this property listing.",
            )}
          </span>
          <Dropdown
            className="upload-modal-dropdown"
            items={props.team.users.map((teamMember) => ({
              label: getAgentFullName(teamMember),
              value: teamMember.id,
              onSelect: () => {
                dispatch(setListingAgent(teamMember));
              },
            }))}
            // if listingAgent is not set, default to the user
            label={getAgentFullName(listingAgent || props.userData)}
          />
        </div>
      </div>
    );
  };

  const renderStepTwo = () => {
    dispatch(setHideMapTools(true));
    dispatch(setCanDrawGeographicPolygons(false));

    return (
      <div>
        <PropertyMap page="myProperties" hideSearchBar />
        {catastroProperty && (
          <div className="max-h-[calc(100svh-550px)] w-screen overflow-scroll px-[24px] sm:w-full">
            <div className="py-[24px]">
              <div className="mb-[8px] flex w-full justify-between text-[16px] font-[500] text-[#222222]">
                <p>{catastroProperty.ref}</p>
                {catastroProperty.url && (
                  <a
                    target={catastroProperty.ref}
                    className="flex"
                    href={catastroProperty.url}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span>Catastro</span>
                    <img src={rightChevron} alt={"chevron icon"} />
                  </a>
                )}
              </div>
              <p className="w-full sm:w-[400px]">{catastroProperty.address}</p>
              <p className="mb-[12px]">{catastroProperty.zip_code}</p>
              <div className="flex gap-[8px]">
                <span>{catastroProperty.type}</span>
                <span>
                  {catastroProperty.buildingType === "land"
                    ? formatNumber(catastroProperty.plotSize)
                    : formatNumber(catastroProperty.size)}
                  ㎡
                </span>
              </div>
            </div>
            <div className="h-[1px] w-full bg-[#E1E4EB]"></div>
            <div className="flex flex-col gap-[8px] py-[24px]">
              <p>{i18n("Location preferences")}</p>
              <p className="text-base font-normal text-[#717171]">
                {i18n(
                  "Showing exact location increases sale rate by an average of X amount",
                )}
              </p>
              <RadioGroup
                direction={window.innerWidth < 1100 ? "column" : "row"}
                items={["Show exact location", "Show approximate location"]}
                onSelect={(item) => dispatch(setLocationPreference(item))}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        closeModal();
      }}
    >
      <DialogContent className="max-h-[calc(100svh-10px)] gap-0 sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {step === 1
              ? i18n("Upload a new property")
              : i18n("Enter Catastral Reference")}
          </DialogTitle>
        </DialogHeader>
        {step === 1 && renderStepOne()}
        {step === 2 && renderStepTwo()}
        <DialogFooter>
          <div className="flex w-full justify-between">
            <Button
              variant="ghost"
              className={step === 1 ? "invisible" : "visible"}
              onClick={() => setStep(1)}
            >
              <div>{i18n("Back")}</div>
            </Button>
            <div className="flex items-center gap-[6px]">
              <div
                className={`h-[6px] w-[6px] rounded-full ${step === 1 ? "bg-[#717171]/40" : "bg-[#717171]"}`}
              />
              <div
                className={`h-[6px] w-[6px] rounded-full ${step === 2 ? "bg-[#717171]/40" : "bg-[#717171]"}`}
              />
            </div>
            <Button
              onClick={() => (step === 1 ? setStep(2) : closeModal())}
              disabled={isNextButtonDisabled()}
            >
              <div>{step === 1 ? i18n("Next") : i18n("Start")}</div>
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default connect((state) => ({
  team: state.team,
  user: state.auth,
  userData: state.user.userData,
  cma: state.cma,
  selectedPlot: state.plots.selectedPlot,
  myProperties: state.myProperties,
}))(UploadPropertyModal);
