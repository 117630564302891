import clsx from "clsx";
import DeleteIcon from "../../../assets/core/delete.svg";
import DuplicateIcon from "../../../assets/core/duplicate.svg";
import EditIcon from "../../../assets/core/edit.svg";
import LikeIcon from "../../../assets/core/like_outline.svg";
import PlusIcon from "../../../assets/core/plus.svg";
import RedoIcon from "../../../assets/core/redo.svg";
import ReportIcon from "../../../assets/core/report.svg";
import SearchBlackIcon from "../../../assets/core/search_black.svg";
import SearchWhiteIcon from "../../../assets/core/search_white.svg";
import SpinnerIcon from "../../../assets/core/spinner.svg";
import SpinnerBlackIcon from "../../../assets/core/spinner_black.svg";
import LikedIcon from "../../../assets/map/favourite_icon_hovered.svg";

const icons = {
  delete: DeleteIcon,
  duplicate: DuplicateIcon,
  edit: EditIcon,
  plus: PlusIcon,
  "search-white": SearchWhiteIcon,
  "search-black": SearchBlackIcon,
  report: ReportIcon,
  like: LikeIcon,
  liked: LikedIcon,
  loading: SpinnerIcon,
  loadingBlack: SpinnerBlackIcon,
  redo: RedoIcon,
};

const Icon = ({ name, className, color, ...rest }) => {
  const SvgIcon = icons[name];
  return (
    <img
      src={SvgIcon}
      alt={name}
      className={clsx(className, "h-[20px]")}
      {...rest}
    />
  );
};

export default Icon;
