import React, { useRef, useState } from "react";
import "./switch.scss";

const Switch = ({ onToggle, isOn }) => {
  return (
    <div className={"switcher" + (isOn ? " is_on" : "")}>
      <div onClick={onToggle} className="switcher_container">
        <div className={"switcher_slider" + (isOn ? " is_on" : "")}></div>
      </div>
    </div>
  );
};

export default Switch;
