import React from "react";
import rightChevron from "../../assets/core/right_chevron.svg";
import { i18n } from "i18n/localisation";
import { formatNumber } from "utils/helpers";

const CmaPropertyCard = ({ property, ...rest }) => {
  return (
    <div
      {...rest}
      className={"cma-property-card" + (rest.selected ? " selected" : "")}
    >
      <h2>{property.ref}</h2>
      <p>{property.address}</p>
      <p>{property.zip_code}</p>
      <div className="cma-property-card_footer">
        <span>{property.type}</span>
        <div className="vertical-divider"></div>
        <span className="cma-property-card_footer_constructed">
          {property.buildingType == "land"
            ? formatNumber(property.plotSize)
            : formatNumber(property.size)}
          ㎡
        </span>
        {property.url && (
          <a
            target={property.ref}
            className="cma-property-card_footer_link"
            href={property.url}
            onClick={(e) => e.stopPropagation()}
          >
            {property.isCatastro ? (
              <span>Catastro</span>
            ) : (
              <span>{i18n("View")}</span>
            )}
            <img src={rightChevron} />
          </a>
        )}
      </div>
    </div>
  );
};

export default CmaPropertyCard;
