import { createReducer } from "@reduxjs/toolkit";
import {
  setDupeProperty,
  setMarkDupeMode,
  setMarkLocationMode,
  setMarkLocationProperty,
} from "actions/adminActions";

const initialState = {
  markDupeMode: false,
  dupeProperty: null,
  markLocationMode: false,
  markLocationProperty: null,
};

const adminReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setMarkDupeMode, (state, action) => {
      state.markDupeMode = action.payload;
    })
    .addCase(setDupeProperty, (state, action) => {
      state.dupeProperty = action.payload;
    })
    .addCase(setMarkLocationMode, (state, action) => {
      state.markLocationMode = action.payload;
    })
    .addCase(setMarkLocationProperty, (state, action) => {
      state.markLocationProperty = action.payload;
    });
});

export default adminReducer;
