import fireApiRequest from "./core";

var MARK_PROPERTY_AS_DUPE_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/mark_property_dupe_admin";
var BLACKLIST_PROPERTY_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/blacklist_property";
var MARK_PROPERTY_LOCATION_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/mark_location";
var UPLOAD_GEOBOUNDARY_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/upload_geoboundary";
var DELETE_GEOBOUNDARY_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/delete_geoboundary";

if (process.env.REACT_APP_NODE_ENV === "production") {
  MARK_PROPERTY_AS_DUPE_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/mark_property_dupe_admin";
  BLACKLIST_PROPERTY_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/blacklist_property";
  MARK_PROPERTY_LOCATION_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/mark_location";
  UPLOAD_GEOBOUNDARY_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/upload_geoboundary";
  DELETE_GEOBOUNDARY_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/delete_geoboundary";
}

export async function markPropertyAsDupe(parentId, dupeId) {
  try {
    await fireApiRequest(MARK_PROPERTY_AS_DUPE_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        parent_id: parentId,
        dupe_id: dupeId,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

export async function blacklistProperty(id) {
  try {
    await fireApiRequest(BLACKLIST_PROPERTY_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        id,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

export async function markPropertyLocation(id, latitude, longitude) {
  try {
    await fireApiRequest(MARK_PROPERTY_LOCATION_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        id,
        latitude,
        longitude,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

export async function uploadGeoboundary(
  name,
  type,
  country,
  municipality,
  geojson,
) {
  try {
    await fireApiRequest(UPLOAD_GEOBOUNDARY_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        name,
        type,
        country,
        municipality,
        geojson,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

export async function deleteGeoboundary(id) {
  try {
    await fireApiRequest(DELETE_GEOBOUNDARY_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        id,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}
