import { useEffect, useState } from "react";
import { GOOGLE_MAPS_API_KEY } from "../../../config/constants";
import { i18n } from "i18n/localisation";
import { capitalizeWord } from "utils/helpers";

export default function LocationCard({ property }) {
  const location = `${property.latitude},${property.longitude}`;
  const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${location}&zoom=15&size=700x364&key=${GOOGLE_MAPS_API_KEY}&scale=2&markers=color:0xef5533%7C${location}`;

  return (
    <div className="location-card details-card">
      <div className="details-card_contents">
        <p className="details-card_title">{i18n("Location")}</p>
        <div className="horizontal-divider"></div>
        <div className="details-card_location-description">
          <p>{capitalizeWord(property.address)}</p>
          {property.neighborhood && (
            <p>{capitalizeWord(property.neighborhood)}</p>
          )}
          {property.district &&
            property.district != "n/a" &&
            property.district != "none" &&
            property.district != property.municipality && (
              <p>{capitalizeWord(property.district)}</p>
            )}
          <p>{capitalizeWord(property.municipality)}</p>
          <p>{capitalizeWord(property.province)}</p>
        </div>
        <div className="details-card_map">
          <img src={imageUrl} />
        </div>
      </div>
    </div>
  );
}
