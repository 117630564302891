import { i18n } from "i18n/localisation";
import React from "react";
import {
  formatNumber,
  formatTimestampByRelativeTime,
  getCollectionById,
  getSearchById,
} from "utils/helpers";
import { getPropertyTitle } from "utils/properties";
import moment from "moment";
import HamburgerMenu from "components/core/HamburgerMenu";
import { useDispatch } from "react-redux";
import {
  deleteNotifications,
  markNotificationAsRead,
} from "actions/notificationsActions";
import likeIcon from "assets/core/like_outline.svg";
import deleteIcon from "assets/core/delete.svg";
import checkIcon from "assets/core/check.svg";
import { addPropertyToCollection } from "actions/collectionActions";
import { toast } from "sonner";

const NotificationItem = (props) => {
  const { notification } = props;
  const isUnread = notification.status == "unread";
  const dispatch = useDispatch();

  // notification hamburger menu items
  const menuItems = [
    {
      text: i18n("Save"),
      icon: likeIcon,
      onClick: () => {
        if (notification.data.property) {
          let collection = getCollectionById(notification.data.collection_id);

          dispatch(
            addPropertyToCollection(collection, notification.data.property),
          );

          setTimeout(() => {
            toast.success(i18n("Property saved to collection"), {
              duration: 1000,
            });
          }, 2000);
        }
      },
    },
    {
      text: i18n("Mark Seen"),
      icon: checkIcon,
      onClick: () => {
        dispatch(markNotificationAsRead(notification.id));
      },
    },
    {
      text: i18n("Delete"),
      icon: deleteIcon,
      onClick: () => {
        dispatch(deleteNotifications([notification.id]));
        toast.success(i18n("Notification deleted"), {
          duration: 1000,
        });
      },
    },
  ];

  const renderPropertyNotification = (notification) => {
    const collection = getCollectionById(notification.data.collection_id);
    const search = getSearchById(notification.data.search_id);

    return (
      <div className="flex flex-col">
        <div className="divider"></div>
        <div
          onClick={() => {
            dispatch(markNotificationAsRead(notification.id));
            const propertyId = notification.data.property.id;
            window.open(
              `/${notification.data.property.saleType == "rent" ? "rental" : "property"}/${propertyId}`,
              propertyId,
            );
          }}
          className={
            "notifications-panel_body_item" + (isUnread ? " unread" : "")
          }
        >
          <div className="notifications-panel_body_item_spacer"></div>
          {isUnread && (
            <div className="notifications-panel_body_item_unread-dot"></div>
          )}
          <div className="notifications-panel_body_item_thumbnail">
            <img
              src={
                notification.data.property.multimedia.images.length > 0
                  ? notification.data.property.multimedia.images[0].url
                  : null
              }
            />
          </div>
          <div className="notifications-panel_body_item_content">
            <div className="notifications-panel_body_item_content_collection">
              <strong>{i18n("New property")}</strong> - {collection.title}
            </div>
            <div className="notifications-panel_body_item_content_search">
              {search.title}
            </div>
            <div className="notifications-panel_body_item_content_property">
              {getPropertyTitle(notification.data.property)}
            </div>
            <div className="notifications-panel_body_item_content_price">
              €{formatNumber(notification.data.property.price)}
            </div>
          </div>
          <div className="notifications-panel_body_item_actions">
            <HamburgerMenu items={menuItems} id={notification.id} />
            <div className="notifications-panel_body_item_actions_timestamp">
              {formatTimestampByRelativeTime(parseInt(notification.timestamp))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (notification.type == "property_alert") {
    return renderPropertyNotification(notification);
  }
};

export default NotificationItem;
