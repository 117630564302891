import React from "react";

const DiamondIcon = ({ color = "black", width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    style={{ color: color }}
  >
    <path
      d="M5.318 2.5H18.682L22.5 9.15L12 21.5L1.5 9.15L5.318 2.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.318 2.5L12 21.5L18.682 2.5M1.5 9.15H22.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.70508 9.15L12.0001 2.5L16.2956 9.15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DiamondIcon;
