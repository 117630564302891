import closeIcon from "assets/core/close.svg";
import { i18n } from "i18n/localisation";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { searchPolygonToGeoJson } from "utils/polygon";
import { getFilteredProperties } from "../../lib/filter/filters";

function PolygonSelectionTray({
  drawnPolygons,
  zoomToDrawnPolygons,
  deletePolygon,
  activeDistrictMunicipality, // avoid conflict with district selection panel
  ...props
}) {
  // only render if the areas drawn are district, locality or hand drawn polygons
  let allowedPolygons = drawnPolygons.filter(
    (dp) =>
      !dp.metadata ||
      !dp.metadata.mun_code ||
      dp.metadata.mun_code !== activeDistrictMunicipality,
  );

  if (allowedPolygons.length === 0 || props.mapReducer.hideMapTools) {
    return null;
  }

  // create rows of 3 controls
  let rows = [];

  for (let i = 0; i < allowedPolygons.length; i += 3) {
    rows.push(allowedPolygons.slice(i, i + 3));
  }

  const propertiesCountInPolygon = (polygon) => {
    return getFilteredProperties().filter(
      (property) => property.polygonId === polygon.irealtyId,
    ).length;
  };

  const propertiesCountText = (polygon) => {
    const propertiesCount = propertiesCountInPolygon(polygon);
    if (propertiesCount === 1) {
      return `${propertiesCount} ${i18n("property")}`;
    } else {
      return `${propertiesCount} ${i18n("properties")}`;
    }
  };

  const onDeletePolygon = (e, polygon) => {
    e.stopPropagation();
    deletePolygon(polygon.irealtyId);
  };

  const goToPolygon = (polygon) => {
    zoomToDrawnPolygons([searchPolygonToGeoJson(polygon)]);
  };

  const renderPill = (polygon, index) => {
    const tooltipId = `polygon-selection-tooltip-polygon-${polygon.irealtyId}`;

    return (
      <div
        key={index}
        className="polygon-selection-tray_row_item"
        onClick={() => goToPolygon(polygon)}
        data-tooltip-id={tooltipId}
      >
        <div
          className="polygon-selection-tray_row_item_circle"
          style={{ backgroundColor: polygon.colourCode }}
        ></div>
        <img
          onClick={(e) => onDeletePolygon(e, polygon)}
          src={closeIcon}
          alt="Close icon"
        />
        <Tooltip className="polygon-selection-tray_tooltip" id={tooltipId}>
          <span>
            {polygon.metadata ? polygon.metadata.name : i18n("Custom area")}
          </span>
          <span>{propertiesCountText(polygon)}</span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className="polygon-selection-tray">
      <div className="polygon-selection-tray_row">
        <span>{i18n("Drawn Areas")}</span>
      </div>
      {rows.map((polygons, index) => (
        <div key={index} className="polygon-selection-tray_row">
          {polygons.map((polygon, index) => renderPill(polygon, index))}
        </div>
      ))}
    </div>
  );
}

export default connect((state) => ({
  drawnPolygons: state.polygon.drawnPolygons,
  mapReducer: state.map,
}))(PolygonSelectionTray);
