import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  BlobProvider,
  Image,
  Font,
} from "@react-pdf/renderer";
import { getLargeTextLocalisation, getLocalisation } from "i18n/localisation";
import { CORS_PROXY } from "config/constants";
import { PDF_STYLES } from "config/pdf";
import { getLocalePropertyPrice } from "utils/properties";

export const CmaDocument = (props) => {
  const propertyImageUrl =
    CORS_PROXY + encodeURIComponent(props.propertyImageUrl);

  const agencyLogoUrl = CORS_PROXY + encodeURIComponent(props.agencyLogoUrl);

  const lang = props.lang;
  const pieChartUrl =
    CORS_PROXY +
    encodeURIComponent(
      `https://dashboard.irealty.app/assets/cma/${lang}_pie_chart_spain_cma.png`,
    );

  let studyAreaText = props.studyAreas
    .filter((s) => s.metadata)
    .map((s) => s.metadata.name)
    .join(", ");

  if (studyAreaText.length == 0) {
    studyAreaText = getLocalisation("Custom area", lang);
  }

  return (
    <Document>
      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <Text
            style={{ ...PDF_STYLES.title, fontSize: lang == "es" ? 38 : 50 }}
          >
            {getLocalisation("Property Valuation Report", lang)}
          </Text>
          <Text style={PDF_STYLES.heading}>
            {props.clientName} {props.clientSurname}
          </Text>
          <View style={PDF_STYLES.divider} />
          <Image
            style={PDF_STYLES.propertyImage}
            src={props.customImage ? props.customImage : propertyImageUrl}
          />
          <Text style={{ ...PDF_STYLES.heading, marginTop: 16 }}>
            {props.propertyName}
          </Text>
          <View style={PDF_STYLES.midSpacer} />
          <Text style={PDF_STYLES.subHeading}>
            {props.personalDetails.firstName} {props.personalDetails.lastName},{" "}
            {props.agencyDetails.name}
          </Text>
          <Text style={PDF_STYLES.subHeading}>
            {props.personalDetails.phone} / {props.personalDetails.email}
          </Text>
          <Text style={PDF_STYLES.subHeading}>
            {props.agencyDetails.address}
          </Text>
          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>
      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <View style={PDF_STYLES.row}>
            <View style={PDF_STYLES.col}>
              <Text style={PDF_STYLES.subHeading}>
                {getLocalisation("Valuation estimate", lang)}
              </Text>
              <Text style={PDF_STYLES.valuationPriceText}>
                {getLocalePropertyPrice(props.property, props.valuationValue)}
              </Text>
            </View>
            <View style={PDF_STYLES.col}>
              <Text style={PDF_STYLES.subHeading}>
                {getLocalisation("Estimated range", lang)}
              </Text>
              <Text style={PDF_STYLES.rangePriceText}>
                {getLocalePropertyPrice(props.property, props.lowerRange)} -{" "}
                {getLocalePropertyPrice(props.property, props.upperRange)}
              </Text>
            </View>
          </View>
          <View style={PDF_STYLES.divider} />
          <View style={PDF_STYLES.row}>
            <Text style={PDF_STYLES.faintText}>
              {getLocalisation("Study area:", lang)}{" "}
            </Text>
            <Text style={PDF_STYLES.text}>{studyAreaText}</Text>
            <View style={PDF_STYLES.verticalDivider} />
            {props.rooms > 0 && (
              <>
                <Text style={PDF_STYLES.faintText}>
                  {getLocalisation("Bedrooms:", lang)}{" "}
                </Text>
                <Text style={PDF_STYLES.text}>{props.rooms}</Text>
                <View style={PDF_STYLES.verticalDivider} />
              </>
            )}
            {props.bathrooms && (
              <>
                <Text style={PDF_STYLES.faintText}>
                  {getLocalisation("Bathrooms:", lang)}{" "}
                </Text>
                <Text style={PDF_STYLES.text}>{props.bathrooms}</Text>
              </>
            )}
          </View>
          <View style={PDF_STYLES.row}>
            <Text style={PDF_STYLES.faintText}>
              {getLocalisation("Area built:", lang)}{" "}
            </Text>
            <Text style={PDF_STYLES.text}>{props.property.size}m²</Text>
            {/* <View style={PDF_STYLES.verticalDivider} /> */}
            {/* {props.features && (
              <>
                <Text style={PDF_STYLES.faintText}>{getLocalisation("Features:", lang)} </Text>
                <Text style={{...PDF_STYLES.text, ...PDF_STYLES.wordWrapTextContainer}}>{props.features.join(", ")}</Text>
                <View style={PDF_STYLES.verticalDivider} />
              </>
            )}  */}
          </View>
          <View style={PDF_STYLES.spacer} />
          <Image styles={PDF_STYLES.propertyImage} src={props.locationUrl} />
          <View style={PDF_STYLES.spacer} />
          <View style={PDF_STYLES.row}>
            <View style={PDF_STYLES.col}>
              <Text style={PDF_STYLES.faintText}>
                {getLocalisation("Address:", lang)}
              </Text>
              {props.property.address && (
                <>
                  <View style={PDF_STYLES.smallSpacer} />
                  {props.property.address.includes("Escalera") ? (
                    <View>
                      <Text style={PDF_STYLES.text}>
                        {props.property.address.split(" Escalera")[0]}
                      </Text>
                      <View style={PDF_STYLES.smallSpacer} />
                      <Text style={PDF_STYLES.text}>
                        Escalera {props.property.address.split(" Escalera")[1]}
                      </Text>
                    </View>
                  ) : (
                    <Text style={PDF_STYLES.text}>
                      {props.property.address}
                    </Text>
                  )}
                  <View style={PDF_STYLES.smallSpacer} />
                </>
              )}
              {!props.property.address && (
                <View style={PDF_STYLES.smallSpacer} />
              )}
              <Text style={PDF_STYLES.text}>{props.district}</Text>
              <View style={PDF_STYLES.smallSpacer} />
              <Text style={PDF_STYLES.text}>{props.municipality}</Text>
              <View style={PDF_STYLES.smallSpacer} />
              <Text style={PDF_STYLES.text}>{props.province}</Text>
            </View>
            {props.property.isCatastro && (
              <View style={PDF_STYLES.col}>
                <Text style={PDF_STYLES.faintText}>
                  {getLocalisation("Catastral reference:", lang)}
                </Text>
                <View style={PDF_STYLES.smallSpacer} />
                <Text style={PDF_STYLES.text}>{props.property.ref}</Text>
              </View>
            )}
          </View>
          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>

      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <Text style={PDF_STYLES.title2}>
            {getLocalisation(
              "The 3 factors influencing the sale of your property",
              lang,
            )}
          </Text>
          <View style={PDF_STYLES.spacer} />
          <View style={PDF_STYLES.greyBox}>
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_2_text_1", lang)}
            </Text>
          </View>
          <View style={PDF_STYLES.spacer2} />
          <View style={PDF_STYLES.row}>
            <View style={PDF_STYLES.circle}>
              <Text style={PDF_STYLES.numText}>1</Text>
            </View>
            <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
              {getLocalisation("The State of Conservation", lang)}
            </Text>
          </View>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_2_text_2", lang)}
          </Text>

          <View style={PDF_STYLES.spacer2} />
          <View style={PDF_STYLES.row}>
            <View style={PDF_STYLES.circle}>
              <Text style={PDF_STYLES.numText}>2</Text>
            </View>
            <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
              {getLocalisation("The Price", lang)}
            </Text>
          </View>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_2_text_3", lang)}
          </Text>

          <View style={PDF_STYLES.spacer2} />
          <View style={PDF_STYLES.row}>
            <View style={PDF_STYLES.circle}>
              <Text style={PDF_STYLES.numText}>3</Text>
            </View>
            <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
              {getLocalisation("Marketing", lang)}
            </Text>
          </View>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_2_text_4", lang)}
          </Text>
          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>

      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <Text style={PDF_STYLES.title2}>
            {getLocalisation("Explanation of the report", lang)}
          </Text>
          <View style={PDF_STYLES.spacer} />
          <View style={PDF_STYLES.greyBox}>
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_3_text_1", lang)}
            </Text>
          </View>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.faintText}>
            {getLocalisation("This analysis includes:", lang)}
          </Text>

          <View style={PDF_STYLES.spacer2} />
          <View style={PDF_STYLES.row}>
            <View style={PDF_STYLES.circle}>
              <Text style={PDF_STYLES.numText}>1</Text>
            </View>
            <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
              {getLocalisation(
                "Similar properties for sale in your immediate area",
                lang,
              )}
            </Text>
          </View>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_3_text_2", lang)}
          </Text>

          <View style={PDF_STYLES.spacer2} />
          <View style={PDF_STYLES.row}>
            <View style={PDF_STYLES.circle}>
              <Text style={PDF_STYLES.numText}>2</Text>
            </View>
            <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
              {getLocalisation(
                "Properties that have not been sold for a long time",
                lang,
              )}
            </Text>
          </View>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_3_text_3", lang)}
          </Text>
          <View style={PDF_STYLES.midSpacer} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_3_text_3", lang)}
          </Text>
          <View style={PDF_STYLES.midSpacer} />
          <View style={PDF_STYLES.divider} />
          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>

      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <Text style={{ ...PDF_STYLES.heading2 }}>
            {getLocalisation(
              "The valuation of your property is a balancing exercise",
              lang,
            )}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_4_text_1", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <Text style={{ ...PDF_STYLES.heading2 }}>
            {getLocalisation("The true market value of your property", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_4_text_2", lang)}
          </Text>
          <View style={PDF_STYLES.midSpacer} />
          <View style={PDF_STYLES.divider} />
          <View style={PDF_STYLES.midSpacer} />
          <Text style={{ ...PDF_STYLES.heading2, fontWeight: 700 }}>
            {getLocalisation("Main sources buyers use to find homes", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <View style={PDF_STYLES.spacer2} />
          <Image style={PDF_STYLES.propertyImage} src={pieChartUrl} />
          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>

      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <Text style={PDF_STYLES.title2}>
            {getLocalisation("Expenses after selling your property", lang)}
          </Text>
          <View style={PDF_STYLES.spacer} />
          <View style={PDF_STYLES.greyBox}>
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_5_text_1", lang)}
            </Text>
          </View>

          <View style={PDF_STYLES.spacer2} />
          <Text style={{ ...PDF_STYLES.heading2 }}>
            {getLocalisation("For non-residents in Spain", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_5_text_2", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />

          <Text style={{ ...PDF_STYLES.heading2 }}>
            {getLocalisation("Taxes on your Income Tax Return", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {" "}
            • {getLocalisation("Profits up to 6.000€: 19%", lang)}
          </Text>
          <Text style={PDF_STYLES.text2}>
            {" "}
            • {getLocalisation("Profits between 6.000€ - 50.000€: 21%", lang)}
          </Text>
          <Text style={PDF_STYLES.text2}>
            {" "}
            • {getLocalisation("Profits between 50.000€ - 200.000€: 23%", lang)}
          </Text>
          <Text style={PDF_STYLES.text2}>
            {" "}
            •{" "}
            {getLocalisation("Profits between 200.000€ - 300.000€: 27%", lang)}
          </Text>
          <Text style={PDF_STYLES.text2}>
            {" "}
            • {getLocalisation("Profits over €300,000: 28%", lang)}
          </Text>

          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLocalisation(
              "These percentages are applied progressively.",
              lang,
            )}
          </Text>

          <View style={PDF_STYLES.spacer2} />
          <Text style={{ ...PDF_STYLES.heading2 }}>
            {getLocalisation("Municipality Tax", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLocalisation("This is dependent on your municipality.", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />

          <Text style={{ ...PDF_STYLES.heading2 }}>
            {getLocalisation("Proprietors' Community", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_5_text_3", lang)}
          </Text>

          <View style={PDF_STYLES.spacer2} />
          <Text style={{ ...PDF_STYLES.heading2 }}>
            {getLocalisation("IBI and BASURA TAX", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_5_text_4", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />

          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>

      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <Text style={{ ...PDF_STYLES.heading2 }}>
            {getLocalisation("Energy Certificate", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_6_text_1", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />

          <Text style={{ ...PDF_STYLES.heading2 }}>
            {getLocalisation("Cancellation of Mortgage", lang)}
          </Text>
          <View style={PDF_STYLES.spacer2} />
          <Text style={PDF_STYLES.text2}>
            {getLargeTextLocalisation("cma_pdf_page_6_text_2", lang)}
          </Text>
          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>
    </Document>
  );
};

const CmaPDFProvider = (props) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  return (
    <BlobProvider document={<CmaDocument {...props} />}>
      {({ blob, url, loading, error }) => {
        // If the blob is available and the PDF URL hasn't been set yet, create a URL for the blob
        if (!loading && !error && blob && !pdfUrl) {
          setPdfUrl(URL.createObjectURL(blob));
        }

        return (
          <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
            Download PDF
          </a>
        );
      }}
    </BlobProvider>
  );
};

export default CmaPDFProvider;
