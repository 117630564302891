import { createAction } from "@reduxjs/toolkit";

const setHoveredArea = createAction("map/setHoveredArea");
const setCanDrawGeographicPolygons = createAction(
  "map/setCanDrawGeographicPolygons",
);
const setCanShowBoundsToolbarItem = createAction(
  "map/setCanShowBoundsToolbarItem",
);
const setHideBounds = createAction("map/setHideBounds");
const toggleSelectedArea = createAction("map/toggleSelectedArea");
const setSelectedAreas = createAction("map/setSelectedAreas");
const setAreaSelectionMode = createAction("map/setAreaSelectionMode");
const setAvailableAreas = createAction("map/setAvailableAreas");
const setHideMapTools = createAction("map/setHideMapTools");

export {
  setHoveredArea,
  setCanDrawGeographicPolygons,
  setCanShowBoundsToolbarItem,
  setHideBounds,
  toggleSelectedArea,
  setSelectedAreas,
  setAvailableAreas,
  setAreaSelectionMode,
  setHideMapTools,
};
