import { i18n } from "i18n/localisation";
import { Helmet } from "react-helmet";
import Dashboard from "../Dashboard";
import PropertyMap from "../map/PropertyMap";
import "./cma.scss";

export default function CmaPage({}) {
  return (
    <Dashboard>
      <Helmet>
        <title>{i18n("CMA")}</title>
      </Helmet>
      <PropertyMap page="cma" hideSearchBar />
    </Dashboard>
  );
}
