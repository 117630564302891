import { createReducer } from "@reduxjs/toolkit";
import {
  setClientMode,
  setGlobalLoading,
  setGlobalLoadingMessage,
  setInitialDataLoaded,
  setAppWideLogoLoader,
  setPricingModal,
  setHighlightProIcon,
  setChangePlanModal,
} from "../actions/miscActions";

const initialState = {
  globalLoading: false,
  globalLoadingMessage: "",
  clientMode: localStorage.getItem("clientMode") === "true",
  initialDataLoaded: false,
  appWideLogoLoader: false,
  pricingModal: false,
  highlightProIcon: false,
  changePlanModal: false,
};

const miscReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setGlobalLoading, (state, action) => {
      state.globalLoading = action.payload;
    })
    .addCase(setGlobalLoadingMessage, (state, action) => {
      state.globalLoadingMessage = action.payload;
    })
    .addCase(setClientMode, (state, action) => {
      state.clientMode = action.payload;
    })
    .addCase(setInitialDataLoaded, (state, action) => {
      state.initialDataLoaded = action.payload;
    })
    .addCase(setAppWideLogoLoader, (state, action) => {
      state.appWideLogoLoader = action.payload;
    })
    .addCase(setPricingModal, (state, action) => {
      state.pricingModal = action.payload;
    })
    .addCase(setHighlightProIcon, (state, action) => {
      state.highlightProIcon = action.payload;
    })
    .addCase(setChangePlanModal, (state, action) => {
      state.changePlanModal = action.payload;
    });
});

export default miscReducer;
