import classNames from "clsx";
import Icon from "../Icon/Icon";

const Button = ({
  children,
  type = "button",
  variant = "primary",
  size = "sm",
  className,
  disabled = false,
  icon = null,
  loading = false,
  ...rest
}) => {
  const primaryClasses = classNames(
    "bg-gray-dark hover:bg-gray-600 active:bg-gray-700 text-white border-2 border-gray-dark font-medium rounded-lg",
    {
      "opacity-30 cursor-default pointer-events-none": disabled,
    },
  );
  const secondaryClasses = classNames(
    "bg-white hover:bg-gray-50 active:bg-gray-100 text-gray-dark border-2 border-gray-400 font-bold rounded-lg",
    {
      "opacity-30 cursor-default pointer-events-none": disabled,
    },
  );
  const textClasses = classNames(
    "bg-transparent hover:opacity-75 text-gray-800 font-semibold border-2 border-transparent",
    {
      "opacity-30 cursor-default pointer-events-none": disabled,
    },
  );

  const specialClasses = classNames(
    "bg-orange hover:bg-orange-dark active:opacity-90 text-white border-2 border-gray-500 font-medium rounded-lg",
    {
      "opacity-30 cursor-default pointer-events-none": disabled,
    },
  );

  const transitionClasses = classNames("transition duration-100 ease-in-out");

  const sizeClasses = classNames({
    "text-base py-3 px-3": size === "md",
    "text-sm py-2.5 px-3": size === "sm",
  });

  const buttonClasses = classNames("inline-flex cursor-pointer align-middle");

  const classes = classNames(
    {
      [primaryClasses]: variant === "primary",
      [secondaryClasses]: variant === "secondary",
      [textClasses]: variant === "text",
      [specialClasses]: variant === "special",
    },
    buttonClasses,
    sizeClasses,
    transitionClasses,
    className,
  );

  return loading ? (
    <div className={classes} disabled={disabled} {...rest}>
      <Icon
        name={variant === "secondary" ? "loadingBlack" : "loading"}
        className={classNames({ "animate-spin": children })}
      />
    </div>
  ) : (
    <div className={classes} disabled={disabled} {...rest}>
      {icon && (
        <Icon name={icon} className={classNames({ "mr-2": children })} />
      )}
      {children}
    </div>
  );
};

export default Button;
