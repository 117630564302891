import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsExports from "./config/aws-exports";
import { Font } from "@react-pdf/renderer";
import { CORS_PROXY, MIXPANEL_PROJECT_TOKEN } from "config/constants";
import { initAmplifyI18n } from "i18n/amplify-i18n";
import { downloadMapData } from "db/mapStore";
import mixpanel from "mixpanel-browser";
import { initGTag } from "utils/helpers";

// configure mixpanel
mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
  debug: process.env.REACT_APP_NODE_ENV !== "production",
  track_pageview: true,
  persistence: "localStorage",
  ignore_dnt: true,
});

// Configure Amplify
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    identityPoolId: awsExports.IDENTITY_POOL_ID,
  },
  Storage: {
    AWSS3: {
      region: awsExports.REGION,
      bucket: awsExports.MEDIA_BUCKET,
      customEndpoint: `https://s3-accelerate.amazonaws.com`,
    },
  },
});

// initialise gtag for non embeddable pages
if (!window.location.pathname.includes("/embedded")) {
  initGTag();
}

// font registering for pdf renderers
Font.register({
  family: "Inter",
  fonts: [
    {
      src:
        CORS_PROXY +
        encodeURIComponent("https://dashboard.irealty.app/font/Inter.ttf"),
    },
    {
      src:
        CORS_PROXY +
        encodeURIComponent(
          "https://dashboard.irealty.app/font/Inter-SemiBold.ttf",
        ),
      fontWeight: 600,
    },
    {
      src:
        CORS_PROXY +
        encodeURIComponent("https://dashboard.irealty.app/font/Inter-Bold.ttf"),
      fontWeight: 700,
    },
  ],
});

initAmplifyI18n();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
