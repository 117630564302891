import { pdf } from "@react-pdf/renderer";
import { setGlobalLoading, setGlobalLoadingMessage } from "actions/miscActions";
import { generateDescriptionRequest } from "api/ai";
import { translateRequest } from "api/translate";
import Dropdown from "components/core/Dropdown";
import Button from "components/ui/Button/Button";
import Checkbox from "components/input/Checkbox";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { SUPPORTED_LANGUAGES } from "config/constants";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { useState } from "react";
import { connect } from "react-redux";
import { getPropertyTitle } from "utils/properties";
import { WindowsCardDocument } from "./WindowCardPDF";

const WindowsCardModal = ({
  isOpen,
  property,
  closeModal,
  agencyDetails,
  duplicates,
  ...props
}) => {
  const [language, setLanguage] = useState(SUPPORTED_LANGUAGES[0]);
  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [propertyName, setPropertyName] = useState(
    getPropertyTitle(property).length <= 40 ? getPropertyTitle(property) : "",
  );
  const [propertyDescription, setPropertyDescription] = useState(
    property.description,
  );
  const [iaDescription, setIaDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  let agencyLogoUrl = "https://dashboard.irealty.app/logo.png";
  if (agencyDetails && agencyDetails.picture) {
    agencyLogoUrl = agencyDetails.picture;
  }

  const getAiDescription = async () => {
    sendAnalyticsEvent("Windows Card Modal", {
      type: "Generate AI Description",
      details: {
        propertyId: property.id,
      },
    });
    setLoading(true);
    const data = await generateDescriptionRequest(property, language.code);
    setPropertyDescription(data.response);
    setLoading(false);
  };

  const openPdf = async () => {
    sendAnalyticsEvent("Windows Card Modal", {
      type: "Download PDF",
      details: {
        propertyId: property.id,
        hasPropertyHistory: isCheckboxChecked,
      },
    });
    try {
      const blob = await pdf(
        <WindowsCardDocument
          clientName={clientName + " " + clientSurname}
          propertyName={propertyName}
          property={property}
          agencyLogoUrl={agencyLogoUrl}
          lang={language.code}
          propertySummary={propertyDescription}
          duplicates={duplicates}
          isCheckboxChecked={isCheckboxChecked}
        />,
      ).toBlob();
      const pdfURL = URL.createObjectURL(blob);
      window.open(pdfURL, "_blank");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  // translate property description to set language
  const translateProperty = async (targetLang) => {
    props.dispatch(setGlobalLoading(true));
    props.dispatch(setGlobalLoadingMessage(i18n("Translating...")));
    let deeplCompatibleLang = targetLang.code.toUpperCase();

    if (targetLang.code == "en") {
      deeplCompatibleLang = "EN-US";
    }

    let titleText = propertyName;
    let descriptionText = propertyDescription;

    if (descriptionText.length == 0) {
      descriptionText = property.description;
    }

    if (titleText.length == 0) {
      titleText = getPropertyTitle(property);
    }

    let title = await translateRequest(titleText, deeplCompatibleLang);
    let description = await translateRequest(
      descriptionText,
      deeplCompatibleLang,
    );
    setPropertyName(title);
    setPropertyDescription(description);
    props.dispatch(setGlobalLoading(false));
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        closeModal();
      }}
    >
      <DialogContent className="max-h-screen gap-0 overflow-hidden sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Download Windows Card")}</DialogTitle>
        </DialogHeader>
        <div className="mt-6 flex max-h-[calc(100vh-200px)] flex-wrap overflow-y-auto">
          <div className="cma-model_content">
            <span className="cma-model_content_header">
              {i18n("Confirm client details to add to your Windows Card.")}
            </span>
            <div className="cma-model_content_form">
              <div className="cma-model_content_form_row">
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Client first name")}*
                  </p>
                  <input
                    type="text"
                    onChange={(e) => setClientName(e.target.value)}
                    placeholder={i18n("Enter customer name") + "..."}
                    className="w-full rounded-lg border border-[#BABABA] p-2 pl-4 placeholder-[#717171]"
                  />
                </div>
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Client surname")}*
                  </p>
                  <input
                    type="text"
                    onChange={(e) => setClientSurname(e.target.value)}
                    placeholder={i18n("Enter customer name") + "..."}
                    className="w-full rounded-lg border border-[#BABABA] p-2 pl-4 placeholder-[#717171]"
                  />
                </div>
              </div>
              <div className="cma-model_content_form_row">
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Property name")}*
                  </p>
                  <input
                    type="text"
                    value={propertyName}
                    onChange={(e) => setPropertyName(e.target.value)}
                    placeholder={i18n("Enter property name") + "..."}
                    className="h-11 w-full rounded-lg border border-[#BABABA] p-2 pl-4 placeholder-[#717171]"
                    maxLength="40"
                  />
                </div>
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Language")}
                  </p>
                  <Dropdown
                    className="windows-card-dropdown"
                    items={SUPPORTED_LANGUAGES.map((lang) => ({
                      label: lang.name,
                      value: lang.code,
                      onSelect: () => {
                        setLanguage(lang);
                        translateProperty(lang);
                      },
                    }))}
                    label={language.name}
                  />
                </div>
              </div>
            </div>
            <div
              className={"cursor-pointer"}
              onClick={() => setIsCheckboxChecked(!isCheckboxChecked)}
            >
              <Checkbox
                checked={isCheckboxChecked}
                label={i18n("Show property history")}
                className={"absolute right-4 top-4"}
              />
            </div>
            <div className="cma-model_content_form_row">
              <div className="cma-model_content_form_row_col">
                <p className="mb-2 text-sm font-medium text-gray-700">
                  {i18n("Property description")}*
                </p>
                <textarea
                  type="text"
                  value={iaDescription || propertyDescription}
                  onChange={(e) => setPropertyDescription(e.target.value)}
                  placeholder={i18n("Enter property description") + "..."}
                  className="h-32 w-full rounded-lg border border-[#BABABA] p-2 placeholder-[#717171]"
                />
                <Button
                  icon="redo"
                  onClick={getAiDescription}
                  loading={loading}
                  variant="secondary"
                  className={
                    "my-4 h-11 w-[243px] border-[1.5px] border-[#BABABA]"
                  }
                >
                  <div className={"align-middle text-base font-medium"}>
                    {i18n("Generate AI Description")}
                  </div>
                </Button>
                <p className="mb-2 text-sm font-medium text-[#717171]">
                  {i18n(
                    "Generate a detailed AI description based on property features and description to include on your Windows Card. This will replace the current property description.",
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button onClick={openPdf}>
              <div>{i18n("Download PDF")}</div>
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default connect((state) => ({
  agencyDetails: state.user.userData.agencyDetails,
}))(WindowsCardModal);
