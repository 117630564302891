import PriceComponent from "components/PriceComponent";
import { i18n } from "i18n/localisation";
import { subscribeToPlatform } from "utils/helpers";
import checkIcon from "assets/core/check_red.svg";
import Button from "components/ui/Button/Button";
import { setPricingModal } from "actions/miscActions";
import { useDispatch } from "react-redux";

const SubscriptionPlanContent = ({ headerTitle, subscribeBtn }) => {
  const dispatch = useDispatch();

  const handleSubscribeClick = () => {
    dispatch(setPricingModal(true));
  };

  return (
    <div className="px-6">
      <p className="mb-4 text-sm font-medium text-gray-500">{headerTitle}</p>
      <div className="subscription-end-modal_plan mb-4 px-8 pb-4">
        <div className="subscription-end-modal_plan_header mb-4 mt-4 flex flex-col">
          <div className="flex items-center justify-between">
            <h2>iRealty</h2>
          </div>
          <div className="subscription-end-modal_plan_header_sub flex items-center justify-between">
            <span>{i18n("Unlimited access to:")}</span>
            {/* <span>{i18n("billed monthly")}</span> */}
          </div>
        </div>
        <div className="divider"></div>
        <div className="mt-6 flex">
          <div className="flex flex-1 flex-col">
            <div className="flex gap-3">
              <img src={checkIcon} />
              <span className="font-medium">{i18n("AI Assisted Search")}</span>
            </div>
            <div className="flex gap-3">
              <img src={checkIcon} />
              <span className="font-medium">{i18n("Windows Card")}</span>
            </div>
            <div className="flex gap-3">
              <img src={checkIcon} />
              <span className="font-medium">{i18n("CMA")}</span>
            </div>
            <div className="flex gap-3">
              <img src={checkIcon} />
              <span className="font-medium">{i18n("Collections")}</span>
            </div>
          </div>

          <div className="flex flex-1 flex-col">
            <div className="flex gap-3">
              <img src={checkIcon} />
              <span className="font-medium">{i18n("Save search")}</span>
            </div>
            <div className="flex gap-3">
              <img src={checkIcon} />
              <span className="font-medium">{i18n("AI Property Chat")}</span>
            </div>
            <div className="flex gap-3">
              <img src={checkIcon} />
              <span className="font-medium">{i18n("Property Data")}</span>
            </div>
            <div className="flex gap-3">
              <img src={checkIcon} />
              <span className="font-medium">{i18n("No Duplicates")}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <span className="flex-1 self-center text-sm font-medium text-gray-500">
          {i18n("Need more information?")}{" "}
          <a className="underline" href="mailto:support@irealty.app">
            {i18n("Contact support")}
          </a>
          .
        </span>
        {subscribeBtn && (
          <Button
            className="flex-end self-center"
            variant="primary"
            onClick={() => handleSubscribeClick()}
          >
            {i18n("Subscribe")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPlanContent;
