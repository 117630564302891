import { createAction } from "@reduxjs/toolkit";
import { fetchPropertiesRequest } from "../api/properties";

const setProperties = createAction("properties/set");
const addProperties = createAction("properties/add");
const deleteProperties = createAction("properties/delete");
const setFetchingProperties = createAction("properties/fetching");
const updateProperty = createAction("properties/update");
const setFilteredProperties = createAction("properties/setFiltered");

// fetches properties and adds them to the properties array in store
function fetchProperties(criteria) {
  return async (dispatch, getState) => {
    const state = getState();
    let properties = await fetchPropertiesRequest(criteria);
    dispatch(setProperties([].concat(state.properties, properties)));
  };
}

// clears the properties from the store
function clearProperties() {
  return (dispatch) => {
    dispatch(setProperties([]));
    dispatch(setFilteredProperties([]));
  };
}

export {
  setProperties,
  clearProperties,
  fetchProperties,
  addProperties,
  deleteProperties,
  setFetchingProperties,
  updateProperty,
  setFilteredProperties,
};
