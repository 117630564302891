import ConfirmModal from "components/ui/Modal/ConfirmModal";
import Switch from "components/ui/Switch/Switch";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { toast } from "sonner";
import {
  getSerializedSearchObject,
  getUnreadNotifications,
} from "utils/helpers";
import { getSubscriptionPlan } from "utils/userHelpers";
import profilePic from "../../assets/core/default_profile.svg";
import logo from "../../assets/logo.svg";
import cmaIcon from "../../assets/navbar/cma.svg";
import DiamondIcon from "../../assets/navbar/diamond_svg";
import dismissIcon from "../../assets/navbar/dismiss_icon.svg";
import favoritesIcon from "../../assets/navbar/favorites.svg";
import feedbackIcon from "../../assets/navbar/feedback.svg";
import matchIcon from "../../assets/navbar/match.svg";
import menuIcon from "../../assets/navbar/menu.svg";
import myPropertiesIcon from "../../assets/navbar/my_properties.svg";
import newDevelopmentsIcon from "../../assets/navbar/new_developments.svg";
import notificationIcon from "../../assets/navbar/notification.svg";
import searchIcon from "../../assets/navbar/search.svg";
import "./navbar.scss";

const Navbar = (props) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [nextPath, setNextPath] = useState(null);
  const [willTriggerWarningModal, setWillTriggerWarningModal] = useState(false);
  const unreadNotifications = getUnreadNotifications();
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [subscriptionLoaded, setSubscriptionLoaded] = useState(false);

  let location = useLocation();
  let navigate = useNavigate();
  let pathname = location.pathname;
  let profile =
    props.agencyDetails && props.agencyDetails.picture
      ? props.agencyDetails.picture
      : profilePic;

  // Fetch subscription plan whenever the subscription data changes or the state.subscription changes
  useEffect(() => {
    // re-render the navbar when the subscription data is fetched
    // set the subscription plan
    setSubscriptionPlan(getSubscriptionPlan(props.user));
    setSubscriptionLoaded(props.subscription.subscriptionDataFetched);
  }, [props.subscription]);

  useEffect(() => {
    // condition to trigger warning dialog when navigating away from the search page
    if (
      pathname === "/" &&
      props.properties.length > 0 &&
      !props.searchContext
    ) {
      window.onbeforeunload = () => true;
      setWillTriggerWarningModal(true);
    }

    // condition to trigger warning dialog when navigating away from the valuation page
    if (
      pathname === "/valuation" &&
      props.properties.length > 0 &&
      !props.cma.isPDFGenerated
    ) {
      window.onbeforeunload = () => true;
      setWillTriggerWarningModal(true);
    }

    // remove event when component unmounts
    return () => {
      window.onbeforeunload = null;
      setWillTriggerWarningModal(false);
    };
  }, [
    pathname,
    props.cma.isPDFGenerated,
    props.searchContext,
    props.properties,
  ]);

  // reset certain slices of the state on location change
  const onLocationChange = (e, newLocation) => {
    e.preventDefault();
    sendAnalyticsEvent("Click Navbar", {
      type: newLocation,
    });

    // if we are on the search page and there are drawn polygons, ask the user if they want to save the search
    // before navigating away
    if (willTriggerWarningModal) {
      setIsConfirmModalOpen(true);
      setNextPath(newLocation);
      return;
    }

    if (willTriggerWarningModal) {
      setIsConfirmModalOpen(true);
      setNextPath(newLocation);
      return;
    }

    props.setProperties([]);
    props.setFilteredProperties([]);
    props.setDrawnPolygons([]);
    props.incrementNumberOfPolygonsFetched(0);
    props.setFilters([]);
    props.setCmaProperty(null);
    props.setInvisibleFilters([]);
    props.setLastAppliedFilters([]);
    props.setCanDrawPlots(true);
    props.setSelectedPlot(null);
    props.setSort(require("../../lib/filter/sort").SORT_OPTIONS[0]);
    props.setIsPDFGenerated(false);
    window.drawnPolygons.forEach((p) => {
      p.setMap(null);
    });
    window.drawnPlots = [];
    window.drawnPolygons = [];
    navigate(newLocation);
  };

  const onCancelConfirmModal = () => {
    setIsConfirmModalOpen(false);

    if (nextPath) {
      navigate(nextPath);
      setNextPath(null);
    }
  };

  const onConfirmConfirmModal = async () => {
    setIsConfirmModalOpen(false);
    props.setGlobalLoading(true);
    props.setGlobalLoadingMessage(i18n("Saving search..."));
    const searchObject = await getSerializedSearchObject();
    props.setSearchObject(searchObject);
    props.setGlobalLoading(false);
    props.setSaveSearchModalOpen(true);
  };

  const onToggleClientMode = () => {
    let isOn = localStorage.getItem("clientMode") === "true";
    sendAnalyticsEvent("Click Navbar", {
      type: "client mode",
      details: isOn ? "deactivated" : "activated",
    });

    if (isOn) {
      localStorage.removeItem("clientMode");
    } else {
      localStorage.setItem("clientMode", "true");
    }

    props.setClientMode(!isOn);
    toast.message(
      `${i18n("Client mode")} ${isOn ? i18n("deactivated") : i18n("activated")}`,
      {
        duration: 1000,
      },
    );
  };

  // set the pricing modal to open
  const onOpenPricingModal = () => {
    props.setPricingModal(true);
    sendAnalyticsEvent("Click Navbar", {
      type: "pricing modal",
    });
  };

  const onToggleNotificationPanel = () => {
    props.setNotificationsOpen(!props.notificationsOpen);
  };

  const [isTooltipVisible, setTooltipVisible] = useState(
    props.highlightProIcon,
  );

  if (props.highlightProIcon && !isTooltipVisible) {
    setTooltipVisible(true);
  } else if (!props.highlightProIcon && isTooltipVisible) {
    setTooltipVisible(false);
  }

  const dismissTooltip = () => {
    setTooltipVisible(false);
  };

  const ConfirmExitModal = () => {
    return pathname === "/" ? (
      <ConfirmModal
        open={isConfirmModalOpen}
        title={i18n("Save current search")}
        confirmationText={i18n("Do you want to save the current search?")}
        confirmText={i18n("Save Search")}
        cancelText={i18n("Don't Save")}
        onCancel={() => onCancelConfirmModal()}
        onConfirm={() => onConfirmConfirmModal()}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    ) : (
      // trigerred when navigating away from the valuation page
      <ConfirmModal
        open={isConfirmModalOpen}
        title={i18n("Lose unsaved changes")}
        confirmationText={i18n("Do you want to quit without saving?")}
        confirmText={i18n("Go back")}
        cancelText={i18n("Yes, continue")}
        onCancel={() => onCancelConfirmModal()}
        onConfirm={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    );
  };

  const MenuList = () => {
    return (
      <div
        onClick={() => {
          document.querySelector(".menu-list").classList.toggle("active");
          document
            .querySelector(".menu-list-background")
            .classList.toggle("active");
        }}
        className="menu-list-background"
      >
        <div className="menu-list">
          <Link
            to="/"
            onClick={(e) => onLocationChange(e, "/")}
            className="menu-list_item"
          >
            <img src={searchIcon} alt="Search icon" />
            <span>{i18n("Search Properties")}</span>
          </Link>
          <Link
            to="/collections"
            onClick={(e) => onLocationChange(e, "/collections")}
            className="menu-list_item"
          >
            <img src={favoritesIcon} alt="Favorites icon" />
            <span>{i18n("Collections")}</span>
          </Link>
          <Link
            to="/valuation"
            onClick={(e) => onLocationChange(e, "/valuation")}
            className="menu-list_item"
          >
            <img src={cmaIcon} alt="CMA icon" />
            <span>{i18n("CMA")}</span>
          </Link>
          <div className="menu-list_item disabled">
            <img src={matchIcon} alt="Match icon" />
            <span>{i18n("Matches")}</span>
          </div>
          <div className="menu-list_item disabled">
            <img src={newDevelopmentsIcon} alt="New developments icon" />
            <span>{i18n("New Developments")}</span>
          </div>
          <div
            className="menu-list_item"
            onClick={() => onToggleNotificationPanel()}
          >
            <img src={notificationIcon} alt="Notification icon" />
            <span>{i18n("Notifications")}</span>
          </div>
          <div className="menu-list_item">
            <img src={feedbackIcon} alt="Feedback icon" />
            <span>{i18n("Send feedback")}</span>
          </div>
          {process.env.REACT_APP_NODE_ENV !== "production" && (
            <Link
              to="/my-properties"
              onClick={(e) => onLocationChange(e, "/my-properties")}
              className="menu-list_item"
            >
              <img src={myPropertiesIcon} alt="My Properties icon" />
              <span>{i18n("My Properties")}</span>
            </Link>
          )}
          <div className="spacer"></div>
          <Link to="/settings" className="menu-list_item">
            <img src={profile} alt="Profile icon" />
            <span>{i18n("Account Settings")}</span>
          </Link>

          <div className="menu-list_item">
            <Switch onToggle={onToggleClientMode} isOn={props.clientMode} />
          </div>
        </div>
      </div>
    );
  };

  const DesktopNavbar = () => {
    return (
      <div className="navbar">
        <div className="navbar_contents">
          <div className="navbar_logo-container">
            <Link to="/" onClick={(e) => onLocationChange(e, "/")}>
              <div className="navbar_logo-container_wrapper">
                <img src={logo} alt="iRealty beta logo" />
              </div>
            </Link>
          </div>
          <div className="navbar_items">
            <Link
              to="/"
              data-tooltip-id="search-tooltip"
              data-tooltip-content={i18n("Search Properties")}
              onClick={(e) => onLocationChange(e, "/")}
              className={
                "navbar-item" +
                (pathname === "/" || pathname.startsWith("/search")
                  ? " active"
                  : "")
              }
            >
              <img src={searchIcon} alt="Search icon" />
            </Link>
            <Link
              data-tooltip-id="collections-tooltip"
              data-tooltip-content={i18n("Collections")}
              to="/collections"
              onClick={(e) => onLocationChange(e, "/collections")}
              className={
                "navbar-item" +
                (pathname.startsWith("/collections") ? " active" : "")
              }
            >
              <img src={favoritesIcon} alt="Favorites icon" />
            </Link>
            <Link
              data-tooltip-id="cma-tooltip"
              data-tooltip-content={i18n("Valuation")}
              to="/valuation"
              onClick={(e) => onLocationChange(e, "/valuation")}
              className={
                "navbar-item" + (pathname === "/valuation" ? " active" : "")
              }
            >
              <img src={cmaIcon} alt="CMA icon" />
            </Link>
            <div
              data-tooltip-id="matches-tooltip"
              data-tooltip-content={i18n("Smart Match (Coming Soon)")}
              href="/matches"
              className={
                "navbar-item disabled" +
                (pathname === "/matches" ? " active" : "")
              }
            >
              <img src={matchIcon} alt="Match icon" />
            </div>
            <div
              data-tooltip-id="new-developments-tooltip"
              data-tooltip-content={i18n("New Developments (Coming Soon)")}
              href="/new-developments"
              className={
                "navbar-item disabled" +
                (pathname === "/new-developments" ? " active" : "")
              }
            >
              <img src={newDevelopmentsIcon} alt="New developments icon" />
            </div>
            {subscriptionPlan !== "ultra" && subscriptionLoaded && (
              <div
                data-tooltip-id="bank-properties-tooltip"
                className={
                  "navbar-item" +
                  (pathname === "#" ? " active" : "") +
                  (props.highlightProIcon ? " new" : "")
                }
                onClick={() => {
                  onOpenPricingModal();
                }}
              >
                <DiamondIcon
                  color={
                    props.highlightProIcon
                      ? "rgba(0, 122, 255, 1)"
                      : "rgba(34, 34, 34, 1)"
                  }
                  width={20}
                  height={20}
                />
              </div>
            )}

            {/* Tooltip Content */}
            {isTooltipVisible &&
              getSubscriptionPlan(props.user) !== "ultra" &&
              subscriptionLoaded && (
                <Tooltip
                  id="bank-properties-tooltip"
                  place="right"
                  isOpen={isTooltipVisible}
                  className="bank-properties-tooltip"
                  clickable={true}
                  border={"2px solid rgba(0, 122, 255, 1)"}
                >
                  <div className="react-tooltip-content">
                    <span className="title">
                      {i18n("Upgrade your license")}
                    </span>
                    <span>
                      {i18n(
                        "Get ahead by upgrading to create Valuations, Unlock off-market properties and access exclusive new tools.",
                      )}
                    </span>
                    <button className="dismiss-btn" onClick={dismissTooltip}>
                      <img src={dismissIcon} />
                    </button>
                  </div>
                </Tooltip>
              )}
            <div
              data-tooltip-id="notifications-tooltip"
              data-tooltip-content={i18n("Notifications")}
              className={
                "navbar-item" + (pathname === "/notifications" ? " active" : "")
              }
              onClick={() => onToggleNotificationPanel()}
            >
              <img src={notificationIcon} alt="Notification icon" />
              {unreadNotifications.length > 0 && (
                <div
                  className={
                    "notification-badge" +
                    (unreadNotifications.length > 99 ? " overflow" : "")
                  }
                >
                  {unreadNotifications.length > 99 ? (
                    <span className="notification-badge_overflow">99+</span>
                  ) : (
                    <span>{unreadNotifications.length}</span>
                  )}
                </div>
              )}
            </div>
            {process.env.REACT_APP_NODE_ENV !== "production" && (
              <Link
                to="/my-properties"
                data-tooltip-id="my-properties-tooltip"
                data-tooltip-content={i18n("My Properties")}
                onClick={(e) => onLocationChange(e, "/my-properties")}
                className={
                  "navbar-item" +
                  (pathname.startsWith("/my-properties") ? " active" : "")
                }
              >
                <img src={myPropertiesIcon} alt="My Properties icon" />
              </Link>
            )}
            <div className="flex-grow"></div>
            <a
              data-canny-link
              className="navbar-item"
              href="https://irealty.canny.io"
              target="__blank"
              data-tooltip-id="feedback-tooltip"
              data-tooltip-content={i18n("Send feedback")}
              onClick={() => {
                sendAnalyticsEvent("Click Navbar", {
                  type: "feedback",
                });
              }}
            >
              <img src={feedbackIcon} alt="Feedback icon" />
            </a>
          </div>
          <Link
            to="/settings"
            onClick={(e) => onLocationChange(e, "/settings")}
            className="navbar_account-container"
          >
            <div
              data-tooltip-id="settings-tooltip"
              data-tooltip-content={i18n("Account Settings")}
              className="navbar_profile-image"
            >
              <img src={profile} alt="Profile icon" />
            </div>
          </Link>
          <div className="navbar_divider"></div>
          <div
            data-tooltip-id="client-mode-tooltip"
            data-tooltip-content={i18n("Client mode")}
            className="navbar_footer"
          >
            <Switch onToggle={onToggleClientMode} isOn={props.clientMode} />
          </div>
        </div>
        <Tooltip id="search-tooltip" />
        <Tooltip id="collections-tooltip" />
        <Tooltip id="cma-tooltip" />
        <Tooltip id="new-developments-tooltip" />
        <Tooltip id="matches-tooltip" />
        <Tooltip id="notifications-tooltip" />
        <Tooltip id="my-properties-tooltip" />
        <Tooltip id="settings-tooltip" />
        <Tooltip id="client-mode-tooltip" />
        <Tooltip id="help-tooltip" />
        <Tooltip id="feedback-tooltip" />

        <ConfirmExitModal />
      </div>
    );
  };

  const MobileNavbar = () => {
    return (
      <div className="navbar-mobile">
        <div className="navbar-mobile_logo-container">
          <Link to="/" onClick={(e) => onLocationChange(e, "/")}>
            <div className="navbar-mobile_logo-container_wrapper">
              <img src={logo} alt="iRealty logo" />
            </div>
          </Link>
        </div>
        <div
          onClick={() => {
            document
              .querySelector(".menu-list-background")
              .classList.toggle("active");
            document.querySelector(".menu-list").classList.toggle("active");
          }}
          className="navbar-mobile_menu"
        >
          <img src={menuIcon} alt="Menu icon" />
        </div>
        <MenuList />

        <ConfirmExitModal />
      </div>
    );
  };

  return (
    <>
      <DesktopNavbar />
      <MobileNavbar />
    </>
  );
};

// Map the setProperties action to props
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setProperties: require("../../actions/propertiesActions").setProperties, // avoid circular dependencies
      setFilteredProperties: require("../../actions/propertiesActions")
        .setFilteredProperties,
      setDrawnPolygons: require("../../actions/polygonActions")
        .setDrawnPolygons,
      setFilters: require("../../actions/filterActions").setFilters,
      setInvisibleFilters: require("../../actions/filterActions")
        .setInvisibleFilters,
      setLastAppliedFilters: require("../../actions/filterActions")
        .setLastAppliedFilters,
      setSort: require("../../actions/filterActions").setSort,
      setClientMode: require("../../actions/miscActions").setClientMode,
      setCmaProperty: require("../../actions/cmaActions").setCmaProperty,
      setSelectedPlot: require("../../actions/plotsActions").setSelectedPlot,
      setCanDrawPlots: require("../../actions/plotsActions").setCanDrawPlots,
      setSaveSearchModalOpen: require("../../actions/saveSearchActions")
        .setSaveSearchModalOpen,
      setSearchObject: require("../../actions/saveSearchActions")
        .setSearchObject,
      setGlobalLoading: require("../../actions/miscActions").setGlobalLoading,
      setGlobalLoadingMessage: require("../../actions/miscActions")
        .setGlobalLoadingMessage,
      setNotificationsOpen: require("../../actions/notificationsActions")
        .setNotificationsOpen,
      setIsPDFGenerated: require("../../actions/cmaActions").setIsPDFGenerated,
      setPricingModal: require("../../actions/miscActions").setPricingModal,
      setHighlightProIcon: require("../../actions/miscActions")
        .setHighlightProIcon,
      incrementNumberOfPolygonsFetched: require("../../actions/polygonActions")
        .incrementNumberOfPolygonsFetched,
    },
    dispatch,
  );
};

export default connect(
  (state) => ({
    clientMode: state.misc.clientMode,
    agencyDetails: state.user.userData.agencyDetails,
    personalDetails: state.user.userData.personalDetails,
    lang: state.user.userData.preferences.lang,
    user: state.auth.user,
    userData: state.user.userData,
    misc: state.misc,
    drawnPolygons: state.polygon.drawnPolygons,
    searchContext: state.saveSearch.searchContext,
    notifications: state.notifications.notifications,
    notificationsOpen: state.notifications.notificationsOpen,
    cma: state.cma,
    highlightProIcon: state.misc.highlightProIcon,
    properties: state.property.properties,
    subscription: state.auth.subscription,
  }),
  mapDispatchToProps,
)(Navbar);
