import React from "react";
import carIcon from "../../assets/property/car.svg";
import bedIcon from "../../assets/property/bed.svg";
import showerIcon from "../../assets/property/shower.svg";
import poolIcon from "../../assets/property/pool.svg";
import garageIcon from "../../assets/property/garage.svg";
import terraceIcon from "../../assets/property/terrace.svg";
import liftIcon from "../../assets/property/lift.svg";
import gardenIcon from "../../assets/property/garden.svg";
import storeroomIcon from "../../assets/property/storeroom.svg";
import acIcon from "../../assets/property/ac.svg";
import plusIcon from "../../assets/core/plus.svg";
import seaViewIcon from "../../assets/property/seaview.svg";
import storageIcon from "../../assets/property/storage.svg";
import heatingIcon from "../../assets/property/heating.svg";
import wardrobeIcon from "../../assets/property/wardrobe.svg";
import StatusCheckMarkIcon from "../../assets/property/statusCheckmark.svg";
import { i18n } from "i18n/localisation";

export const PROPERTY_FEATURE_TYPE = {
  bedrooms: 0,
  bathrooms: 1,
  pool: 2,
  parking: 3,
  ac: 4,
  terrace: 5,
  garden: 6,
  garage: 7,
  storeroom: 8,
  lift: 9,
  more: 10,
  seaView: 11,
  greenery: 12,
  heating: 13,
  wardrobe: 14,
  balcony: 15,
  isBankProperty: 45,

  // land features
  land_type: 16,
  land_water: 17,
  land_sewage: 18,
  land_electricity: 19,
  land_gas: 20,
  land_streetLighting: 21,
  land_sidewalk: 22,
  land_roadAccess: 23,
  land_bankProperty: 24,
  land_buildableFloors: 25,

  // commercial features
  commercial_type: 26,
  commercial_heating: 27,
  commercial_extractorFan: 28,
  commercial_safetyGate: 29,
  commercial_cctv: 30,
  commercial_onCorner: 31,
  commercial_office: 32,
  commercial_accessibleBathrooms: 33,
  commercial_ac: 34,
  commercial_kitchen: 35,
  commercial_alarm: 36,
  commercial_store: 37,
  commercial_smokeExtractor: 38,
  commercial_auxEntrance: 39,
  commercial_businessActivities: 40,
  commercial_shopWindows: 41,
  commercial_communityCost: 42,
  commercial_grossAnnualProfitability: 43,
  commercial_facade: 44,

  // building features
  building_use: 61,
  building_hasTenants: 62,
  building_garageSpaces: 63,
  building_numLifts: 64,
  building_numUnits: 65,

  // garage features
  garage_hasSecurity: 50,
  garage_isCovered: 51,
  garage_hasAlarm: 52,
  garage_hasAutomaticDoor: 53,
  garage_hasLift: 54,
  garage_hasCCTV: 55,
  garage_spaceSize: 56,
  garage_multipleSpaces: 57,
  garage_vehicle: 58,

  // storage features
  storage_24HourAccess: 59,
  storage_hasLoadingBay: 60,

  // rental features
  rental_petsAllowed: 46,
  rental_childrenAllowed: 47,
  rental_accessibility: 48,
  rental_maxTenants: 49,

  // office features
  office_exclusiveUse: 66,
  office_isWareHouse: 67,
  office_hasKitchen: 72,
  office_hasToilets: 73,
  office_hasEmergencyExit: 74,
  office_location: 75,
  office_distribution: 76,

  // private bank features
  privateBank_propertyStatus: 77,
  privateBank_modificationDate: 78,
};

const camelCaseToTitleCase = (value) => {
  if (!value) return "";
  // Insert a space before all uppercase letters
  const result = value.replace(/([A-Z])/g, " $1");
  // Capitalize the first letter of the result string and return
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const formatDate = (date) => {
  if (!date) return "";

  const d = new Date(date);

  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = String(d.getFullYear()).slice(-2);

  return `${day}/${month}/${year}`;
};

const PropertyFeatureChip = (props) => {
  const { feature } = props;
  let icon = null;

  switch (feature.type) {
    case PROPERTY_FEATURE_TYPE.bedrooms:
      icon = bedIcon;
      break;
    case PROPERTY_FEATURE_TYPE.bathrooms:
      icon = showerIcon;
      break;
    case PROPERTY_FEATURE_TYPE.pool:
      icon = poolIcon;
      break;
    case PROPERTY_FEATURE_TYPE.parking:
      icon = carIcon;
      break;
    case PROPERTY_FEATURE_TYPE.ac:
      icon = acIcon;
      break;
    case PROPERTY_FEATURE_TYPE.terrace:
      icon = terraceIcon;
      break;
    case PROPERTY_FEATURE_TYPE.garage:
      icon = garageIcon;
      break;
    case PROPERTY_FEATURE_TYPE.garden:
      icon = gardenIcon;
      break;
    case PROPERTY_FEATURE_TYPE.lift:
      icon = liftIcon;
      break;
    case PROPERTY_FEATURE_TYPE.storeroom:
      icon = storageIcon;
      break;
    case PROPERTY_FEATURE_TYPE.more:
      icon = plusIcon;
      break;
    case PROPERTY_FEATURE_TYPE.seaView:
      icon = seaViewIcon;
      break;
    case PROPERTY_FEATURE_TYPE.greenery:
      icon = gardenIcon;
      break;
    case PROPERTY_FEATURE_TYPE.wardrobe:
      icon = wardrobeIcon;
      break;
    case PROPERTY_FEATURE_TYPE.heating:
      icon = heatingIcon;
      break;
    case PROPERTY_FEATURE_TYPE.balcony:
      icon = terraceIcon;
      break;
    default:
      break;
  }

  // private bank propert status: Available, Offer Received, Reserved, Sold

  if (feature.type === PROPERTY_FEATURE_TYPE.privateBank_propertyStatus) {
    return (
      <div
        className={`property-card-chips_chip ${feature.value === "sold" ? "sold-chip" : feature.value === "offerReceived" ? "offerReceived-chip" : feature.value === "reserved" ? "reserved-chip" : feature.value === "available" ? "available-chip" : ""}`}
      >
        <span>
          {icon && <img src={icon} />}
          {i18n(camelCaseToTitleCase(feature.value))}
        </span>
      </div>
    );
  }

  if (feature.type === PROPERTY_FEATURE_TYPE.privateBank_modificationDate) {
    return (
      <div {...props} className="property-card-chips_chip">
        <span>{formatDate(feature.value)}</span>
      </div>
    );
  }

  let value = feature.value;

  if (feature.type === PROPERTY_FEATURE_TYPE.bedrooms && value === 0) {
    value = "Studio";
  }

  return (
    <div {...props} className="property-card-chips_chip">
      {icon && <img src={icon} />}
      <span>{value}</span>
    </div>
  );
};

export default PropertyFeatureChip;
