import { cancelSubscriptionRequest } from "api/subscriptions";
import Button from "../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { connect } from "react-redux";
import { fetchSubscription } from "actions/userActions";
import { toast } from "sonner";
import { i18n } from "i18n/localisation";

const CancelSubscriptionModal = ({ closeModal, open, dispatch }) => {
  const handleCancelSubscription = async () => {
    let promise = async () => {
      await cancelSubscriptionRequest();
      dispatch(fetchSubscription());
    };

    toast.promise(promise, {
      loading: i18n("Cancelling subscription..."),
      success: i18n("Subscription cancelled"),
      error: i18n("Failed to cancel subscription"),
      duration: 1000,
    });

    closeModal();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        closeModal();
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Cancel subscription?")}</DialogTitle>
        </DialogHeader>
        <div className="p-4 text-sm">
          <p>{i18n("Are you sure you want to cancel your subscription?")}</p>
          <p className="mt-4 text-sm">
            {i18n(
              "You will lose all access to the platform after the current billing period ends.",
            )}
          </p>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="primary" onClick={() => closeModal()}>
              {i18n("No, keep my benefits")}
            </Button>
            <Button
              variant="text"
              onClick={() => handleCancelSubscription()}
              className="ml-auto"
            >
              {i18n("Yes, cancel")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default connect()(CancelSubscriptionModal);
