import { createAction } from "@reduxjs/toolkit";

const setMarkDupeMode = createAction("misc/setMarkDupeMode");
const setDupeProperty = createAction("misc/setDupeProperty");
const setMarkLocationMode = createAction("misc/setMarkLocationMode");
const setMarkLocationProperty = createAction("misc/setMarkLocationProperty");

export {
  setMarkDupeMode,
  setDupeProperty,
  setMarkLocationMode,
  setMarkLocationProperty,
};
