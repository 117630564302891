import fireApiRequest from "./core";

var FETCH_PLOTS_BY_BOUNDS_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_plots_by_bounds";
var GET_PLOT_BY_REF_CAT_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_plot_by_refcat";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_PLOTS_BY_BOUNDS_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_plots_by_bounds";
  GET_PLOT_BY_REF_CAT_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_plot_by_refcat";
}

let abortController = new AbortController();

/**
 * fetches plots within a certain bounds from the server
 */
export async function fetchPlotsByBounds(bounds) {
  // If there's an ongoing fetch, abort it
  if (abortController) abortController.abort();

  // Create a new AbortController for the new fetch
  abortController = new AbortController();

  let plots = [];

  try {
    let data = await fireApiRequest(FETCH_PLOTS_BY_BOUNDS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({ bounds }),
      signal: abortController.signal, // Pass the abort signal to the fetch request
    });

    plots = data.plots;
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Fetch aborted");
    } else {
      // Handle other errors
    }
  }

  return plots;
}
/**
 * fetches a plot by its refcat
 */
export async function getPlotByRefCat(refCat) {
  try {
    let data = await fireApiRequest(GET_PLOT_BY_REF_CAT_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({ refcat: refCat }),
    });

    return data.plot;
  } catch (e) {
    console.log(e);
  }

  return null;
}
