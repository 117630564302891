import { setPricingModal } from "actions/miscActions";
import { fetchUsersForTeam } from "actions/teamActions";
import {
  fetchUserData,
  saveUserData,
  setSubscription,
} from "actions/userActions";
import { saveUserDataRequest } from "api/user";
import { i18n } from "i18n/localisation";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import logo from "../assets/logo_with_text.svg";
import GlobalLoader from "./core/GlobalLoader";
import FreeTrialModal from "./core/modal/FreeTrialModal";
import PricingModal from "./core/modal/PricingModal";
import Navbar from "./navbar/Navbar";
import NotificationsPanel from "./notifications/NotificationsPanel";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFreeTrialModal: false,
      showSubscriptionEndModal: false,
      subscriptionDataLoaded: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      setSubscription({
        subscriptionDataFetched: false,
        data: null,
      }),
    );

    // if upgrade is present in the url then show the pricing modal
    if (window.location.search.includes("upgrade")) {
      dispatch(setPricingModal(true));
    }

    // if the user sata isn't fetched yet then fetch it
    if (
      !this.props.userData.userDataFetching &&
      !this.props.userData.userDataFetched
    ) {
      dispatch(fetchUserData());
    }
  }

  componentDidUpdate(prevProps) {
    const { subscription, userData, dispatch, team } = this.props;
    const { subscriptionDataLoaded } = this.props;

    // if its the initial launch of the app for the user
    // (usually from account creation) then show the free trial modal
    if (
      prevProps.userData.userData.initialLaunch !=
      userData.userData.initialLaunch
    ) {
      if (userData.userData.initialLaunch) {
        dispatch(saveUserData({ initialLaunch: false }));
        this.setState({
          showFreeTrialModal: true,
        });

        return;
      }
    }

    // if the user has a pending invitation
    // then flip the status to accepted
    if (
      userData.userData.invitationStatus == "pending" &&
      userData.userData.invitationStatus !=
        prevProps.userData.userData.invitationStatus
    ) {
      saveUserDataRequest({ invitationStatus: "accepted" });
    }

    // fetch team for user once user data consumed
    if (userData.userDataFetched && !team.teamFetched && !team.fetchingTeam) {
      if (userData.userData.teamId) {
        dispatch(fetchUsersForTeam(userData.userData.teamId));
      }
    }

    if (
      ((!prevProps.subscription.subscriptionDataFetched &&
        subscription.subscriptionDataFetched &&
        userData.userDataFetched) ||
        (!prevProps.userData.userDataFetched &&
          userData.userDataFetched &&
          subscription.subscriptionDataFetched)) &&
      !subscriptionDataLoaded
    ) {
      // if subscription data not present
      // or subscription status is not equal to
      // active or trialing then show the subscription end modal
      if (
        !subscription.data ||
        (subscription.data.status != "active" &&
          subscription.data.status != "trialing" &&
          subscription.data.status != "past_due")
      ) {
        // if we are managing the free trial from our end
        // then check for that too
        if (userData.userData.trialEndDate) {
          if (moment().unix() > parseFloat(userData.userData.trialEndDate)) {
            this.setState({
              showSubscriptionEndModal: true,
            });
          }
        } else {
          this.setState({
            showSubscriptionEndModal: true,
          });
        }
      }

      this.setState({
        subscriptionDataLoaded: true,
      });
    }
  }

  renderLoader() {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <img className="loader-logo" src={logo} />
      </div>
    );
  }

  // closes free trial modal
  closeFreeTrialModal() {
    this.setState({
      showFreeTrialModal: false,
    });
  }

  render() {
    const { initialDataLoaded, dispatch, pricingModal, changePlanModal } =
      this.props;
    const { showFreeTrialModal, showSubscriptionEndModal } = this.state;

    if (!initialDataLoaded) {
      return this.renderLoader();
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n("Dashboard")}</title>
        </Helmet>
        <Navbar />
        {this.props.children}
        <NotificationsPanel />
        <GlobalLoader />
        <FreeTrialModal
          isOpen={showFreeTrialModal}
          closeModal={() => this.closeFreeTrialModal()}
        />
        <PricingModal
          isOpen={pricingModal || showSubscriptionEndModal}
          closeModal={() => dispatch(setPricingModal(false))}
          trialEnded={showSubscriptionEndModal}
          changePlanModal={changePlanModal}
        />
        {/* <SubscriptionEndModal isOpen={showSubscriptionEndModal} /> */}
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  subscription: state.auth.subscription,
  user: state.auth.user,
  userData: state.user,
  globalLoading: state.misc.globalLoading,
  initialDataLoaded: state.misc.initialDataLoaded,
  team: state.team,
  pricingModal: state.misc.pricingModal,
  changePlanModal: state.misc.changePlanModal,
}))(Dashboard);
