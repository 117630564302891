import clsx from "clsx";
import { getPropertyTitle } from "../../utils/properties";
import PriceComponent from "../PriceComponent";
import { unwatermarkImg } from "utils/helpers";
import deleteIcon from "assets/core/delete.svg";
import { i18n } from "i18n/localisation";
import HamburgerMenu from "components/core/HamburgerMenu";

const PropertyTile = ({
  property,
  onClick,
  className,
  onDeleteProperty,
  ...props
}) => {
  const handleClick = () => {
    onClick();
  };

  const menuItems = [
    {
      text: i18n("Delete"),
      icon: deleteIcon,
      onClick: () => onDeleteProperty(),
    },
  ];

  return (
    <div className={clsx(className, "tile flex flex-col")} {...props}>
      <div
        className={clsx(
          "relative h-[268px] w-[268px] cursor-pointer rounded-2xl border-4 border-white bg-gray-200 transition-all duration-200 ease-in-out hover:opacity-75",
        )}
        onClick={handleClick}
      >
        <div className="flex h-full w-full items-center justify-center ">
          <img
            src={unwatermarkImg(property.multimedia.images[0].url)}
            alt={property.multimedia.images[0].tag}
            className="absolute h-full w-full rounded-xl object-cover"
          />
        </div>
      </div>
      <div className="flex w-[268px] flex-col py-3">
        <p className="text-lg font-medium">{getPropertyTitle(property)}</p>
        <p className="mb-3 text-sm text-gray-500">
          <PriceComponent property={property} />
        </p>
      </div>
      <HamburgerMenu items={menuItems} />
    </div>
  );
};

export default PropertyTile;
