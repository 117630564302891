export const SPAIN_PROVINCE_GEOBOUNDARIES = "SPAIN_PROVINCE_GEOBOUNDARIES";

// downloads province geo boundaries
export async function downloadProvinceData() {
  let provinceData = localStorage.getItem(SPAIN_PROVINCE_GEOBOUNDARIES);

  if (!provinceData) {
    let response = await fetch(
      "https://irealty-geojsons.s3.eu-west-2.amazonaws.com/spain-provinces.json",
    );
    let text = await response.text();
    localStorage.setItem(SPAIN_PROVINCE_GEOBOUNDARIES, text);
  }
}

// gets cached province data
export function getProvinceData() {
  let provinceData = localStorage.getItem(SPAIN_PROVINCE_GEOBOUNDARIES);

  if (provinceData) {
    return JSON.parse(provinceData);
  }

  return null;
}

export function translateProvinceNameToBackendReadable(province) {
  if (province === "Gipuzkoa/Guipúzcoa") return "Guipúzcoa";
  if (province === "Bizkaia/Vizcaya") return "Vizcaya";
  if (province === "València/Valencia") return "València";
  if (province === "Santa Cruz De Tenerife") return "Santa Cruz de Tenerife";
  if (province === "Illes Balears") return "Balears (Illes)";
  return province;
}
